import { FC } from 'react'
import AutocompleteFilter from 'components/common/AutocompleteFilter'
import { observer } from 'mobx-react-lite'
import { FilterCombinedProps, FilterOptions } from 'models/filters'
import { filtersService } from 'services/filters/filters.service'
// import { useLanguage } from "context/Translation";

const SanctionTypeFilter: FC<FilterCombinedProps<FilterOptions>> = ({
  onChange,
  checkedValues
}) => {
  const { sanctionType, loading } = filtersService

  const handleChange = (value) => {
    console.log(value, 'valueee')

    onChange({ ...checkedValues, type: value })
  }

  const handleOpen = () => {
    filtersService.fetchSanctionTypes()
  }

  return (
    <AutocompleteFilter
      options={sanctionType}
      loading={loading}
      label="Тип санкций"
      checkedValues={checkedValues.type}
      optionLabelField="name"
      optionSelectedFieldKey="name"
      onOpen={handleOpen}
      onChange={handleChange}
      hasAll
    />
  )
}

export default observer(SanctionTypeFilter)
