import { makeStyles, Theme } from '@material-ui/core'
import { defaultContainer } from 'styles/defaultStyle'

export const useAboutStyles = makeStyles((theme: Theme) => ({
  container: defaultContainer(theme),
  indicator: {
    height: 14,
    width: 11,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0)'
  },
  spanIndicator: {
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: `20px solid ${theme.palette.success.main}`
  },
  fixed: {
    width: 0
  },
  flexContainer: {
    justifyContent: 'center'
  },
  tab: {
    borderBottom: `1px solid ${theme.palette.success.main}`,
    color: theme.palette.success.main
  },
  direction: {
    fontSize: 14,
    lineHeight: '26px',
    textAlign: 'justify'
  },
  analuticsBlocksContainer: {
    margin: '40px 0px'
  },
  analyticsBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 8,
    width: 270,
    height: 270,
    padding: '10px 30px 30px 30px'
  },
  analyticsBlockName: {
    fontWeight: 700,
    fontSize: 18,
    textAlign: 'center',
    color: theme.palette.text.primary
  },
  infoBlock: {
    background: theme.palette.grey[50],
    borderRadius: 5,
    padding: '25px 10px'
  },
  aboutTabsPanel: {
    padding: '0px 5%'
  },
  authorsContainer: {
    padding: '30px 0px 30px 0px'
  },
  panelContainer: {
    padding: '30px 0px 30px 0px',
    '& .MuiGrid-root ': {
      '& a': {
        textDecoration: 'unset',
        '& :hover': {
          textDecoration: 'unset'
        }
      }
    },
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}))
