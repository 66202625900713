import { makeStyles } from "@material-ui/core";
import { common } from "@material-ui/core/colors";

export const useButtonLinkStyles = makeStyles((theme) => ({
  root: {
    color: common.black,
    border: `1px solid ${theme.palette.success.main}`,
    borderRadius: 12,
    textDecoration: "none",
    fontSize: 20,
    padding: "7px 18px",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

export const useHomeLinkStyles = makeStyles((theme) => ({
  root: {
    fontSize: 24,
    color: theme.palette.primary.dark,
    textDecoration: "none",
  },
}));

export const useFooterLinkStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.common.white,
    textDecoration: "none",
  },
  container: {
    padding: "4px 16px",
  },
}));
