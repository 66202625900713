import { FC, Fragment } from "react";
import Default from "./Default";
import Mobile from "./Mobile";
import { FooterHelper } from "./Footer.helper";
import OkModal from "components/common/Modal/OkModal";
import ErrorModal from "components/common/Modal/ErrorModal";
import InvalidFormatModal from "components/common/Modal/InvalidFormat";

const Footer: FC = () => {
  const {
    email,
    isMobile,
    handleChangeEmail,
    handleSubmitClick,
    openOkMessage,
    openErrorMessage,
    openInvalidFormat,
    handleCloseOkMessage,
    handleCloseErrorMessage,
    handleCloseInvalidFormat,
  } = FooterHelper();

  return (
    <Fragment>
      {isMobile ? (
        <Mobile
          email={email}
          onChangeEmail={handleChangeEmail}
          onSubmit={handleSubmitClick}
        />
      ) : (
        <Default
          email={email}
          onChangeEmail={handleChangeEmail}
          onSubmit={handleSubmitClick}
        />
      )}
      <OkModal open={openOkMessage} onClose={handleCloseOkMessage} />
      <ErrorModal
        open={openErrorMessage}
        onClose={handleCloseErrorMessage}
        email={email}
      />
      <InvalidFormatModal
        open={openInvalidFormat}
        onClose={handleCloseInvalidFormat}
      />
    </Fragment>
  );
};

export default Footer;
