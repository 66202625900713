import { FC, Fragment, useEffect, useReducer } from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "components/common/Footer";
import LegalBasePage from "components/LegalBase";
import DetailPage from "components/LegalBase/Details";
import { useLanguage } from "context/Translation";
import { legalBaseService } from "services/legalBase/legalBase.service";
import { defaultFilterOptions } from "enums";
import { FilterOptions } from "models/filters";
import { routers } from "routers";

const reducer = (
  options: FilterOptions,
  newOptions: Partial<FilterOptions>
) => ({
  ...options,
  ...newOptions,
});

const LegalBase: FC = () => {
  const [filterOptions, setFilterOptions] = useReducer(
    reducer,
    defaultFilterOptions
  );
  const { language } = useLanguage();
  const { lang, documents } = legalBaseService;

  useEffect(() => {
    return function cleanupPage() {
      legalBaseService.cleanPage();
    };
  }, []);

  useEffect(() => {
    if (lang !== language || !documents.length) {
      legalBaseService.fetchDocuments(language);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <Fragment>
      <Switch>
        <Route exact path={routers.legalBase}>
          <LegalBasePage
            checkedValues={filterOptions}
            onChange={setFilterOptions}
          />
        </Route>
        <Route exact path={`${routers.legalBase}/:id`} component={DetailPage} />
      </Switch>
      <Footer />
    </Fragment>
  );
};

export default LegalBase;
