import { makeAutoObservable, runInAction } from 'mobx'
import axios from 'axios'
import {
  AutorItem,
  BlockItem,
  CountryItem,
  DomainItem,
  NewListItem,
  SanctionItem
} from 'models'

const urlBlocks = '/api/blocks'
const urlCountries = '/api/terms/countries'
const urlDocumentType = '/.git/api/document_types'
const urlResearch = '/api/research_types'
const urlSanctionTypes = '/api/terms/sanction_type'
const urlDomains = '/api/terms/sanction_domain'
const urlAuthors = '/api/authors'

class FiltersService {
  public blocks: BlockItem[] = []
  public countries: CountryItem[] = []
  public documentType: NewListItem[] = []
  public sanctionType: SanctionItem[] = []
  public domainType: DomainItem[] = []
  public research: NewListItem[] = []
  public authors: AutorItem[] = []
  public lang = ''
  public loading = false

  constructor() {
    makeAutoObservable(this)
  }

  async fetchBlocks(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(urlBlocks, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.blocks = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchRegions() {
    try {
      runInAction(() => (this.loading = true))

      const result = await axios.get(urlCountries)
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.countries = data
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchResearchType(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(urlResearch, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.research = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchDocumentTypes(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(urlDocumentType, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.documentType = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchDomainTypes() {
    try {
      runInAction(() => (this.loading = true))

      const result = await axios.get(urlDomains)
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.domainType = data
        console.log({ data })
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
  async fetchSanctionTypes() {
    try {
      runInAction(() => (this.loading = true))

      const result = await axios.get(urlSanctionTypes)
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.sanctionType = data
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchAuthors(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(urlAuthors, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data.data
        this.authors = data
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
}

export const filtersService = new FiltersService()
