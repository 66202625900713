import { makeAutoObservable, runInAction } from 'mobx'
import axios from 'axios'
import getOrigin from 'utils/getOrigin'
import {
  AutorDetails,
  AutorItem,
  BlockItem,
  CaruselNewsItem,
  EventListItem,
  FeedbackItem,
  NewListItem,
  PartnerItem,
  PhotoGalleryList,
  SanctionListItem,
  TeamItem,
  VideoGalleryList
} from 'models'
import { defaultPaging } from 'enums'
import { FilterOptions } from 'models/filters'
import { defaultAuthorDetail } from './general.data'
// import { routers } from 'routers'

const urlBlocks = getOrigin() + 'api/blocks'
const urlPartners = getOrigin() + 'api/partners'
const urlNews = getOrigin() + 'api/news'
// const urlEvents = getOrigin() + 'api/event/list'
// const urlPhotos = getOrigin() + 'api/igallery/list'
// const urlVideos = getOrigin() + 'api/vgallery/list'
const urlAuthors = getOrigin() + 'api/authors'
const urlTeam = getOrigin() + 'api/team'

const urlSubmit = getOrigin() + 'api/subscribe'
const urlFeedback = 'api/feedback'
console.log(urlNews, 'url')

class GeneralService {
  public blocks: BlockItem[] = []
  public caruselNews: CaruselNewsItem[] = []
  public homeEvent: EventListItem[] = []
  public homeNews: NewListItem[] = []
  public homeSanctions: SanctionListItem[] = []
  public partners: PartnerItem[] = []
  public clients: PartnerItem[] = []
  public authors: AutorItem[] = []
  public rusAuthors: AutorItem[] = []
  public engAuthors: AutorItem[] = []
  public authorDetails: AutorDetails = defaultAuthorDetail
  public team: TeamItem[] = []
  public photoGallerys: PhotoGalleryList[] = []
  public videoGallerys: VideoGalleryList[] = []
  public lang = ''
  public loading = false
  public paging = defaultPaging
  public capchaToken = ''

  constructor() {
    makeAutoObservable(this)
  }

  public setCapchaToken = (token: string) => {
    console.log(token, 'tokennnnnnn')

    this.capchaToken = token
  }

  async fetchBlocks(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(urlBlocks, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.blocks = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
  // async fetchNews(language: string) {
  //   try {
  //     runInAction(() => (this.loading = true))

  //     const resultNewss = await axios.get(urlNews)
  //     console.log(resultNewss, 'news')

  //     if (resultNewss.status !== 200) {
  //       return console.log('result', resultNewss)
  //     }
  //     runInAction(() => {
  //       const { data } = resultNewss.data.data
  //       this.homeNews = data
  //       this.lang = language
  //     })
  //   } catch (error) {
  //     console.log(error)
  //     console.log(11111111111111)
  //   } finally {
  //     runInAction(() => (this.loading = false))
  //   }
  // }

  async fetchHomePage() {
    try {
      this.caruselNews = []
      runInAction(() => (this.loading = true))
      console.log(1212121212)

      const resultNews = await axios.get('/api/news')
      console.log(resultNews, 'resultNews')

      runInAction(() => {
        const data = resultNews.data.data
        this.homeNews = data
      })

      const isError = resultNews.status !== 200
      if (isError) {
        console.log('result', resultNews)
        return
      }
    } catch (error) {
      console.log(error)
      console.log(222222222222)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchPartners(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        client: 0
      }
      const result = await axios.get(urlPartners, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.partners = data
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchClients(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        client: 1
      }
      const result = await axios.get(urlPartners, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.clients = data
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchAuthors(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        page: 0,
        psize: 6
      }
      const result = await axios.get(urlAuthors, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data.data
        this.authors = data
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchTeam(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(urlTeam, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.team = data
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchAllAuthors(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const rusParams = {
        lang: language,
        foreign: 0,
        ...this.paging
      }
      const engParams = {
        lang: language,
        foreign: 1,
        ...this.paging
      }
      const rusResult = await axios.get(urlAuthors, { params: rusParams })
      const engResult = await axios.get(urlAuthors, { params: engParams })
      if (rusResult.status !== 200 || engResult.status !== 200) {
        console.log('rusResult', rusResult)
        console.log('engResult', engResult)
        return
      }
      runInAction(() => {
        this.rusAuthors = rusResult.data.data.data
        this.engAuthors = engResult.data.data.data
        const total =
          rusResult.data.data.total > engResult.data.data.total
            ? rusResult.data.data.total
            : engResult.data.data.total
        this.paging = {
          ...this.paging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchAuthorsPaging(language: string, newPage: number) {
    try {
      runInAction(() => (this.loading = true))
      const rusParams = {
        lang: language,
        foreign: 0,
        ...this.paging,
        page: newPage
      }
      const engParams = {
        lang: language,
        foreign: 1,
        ...this.paging,
        page: newPage
      }
      const rusResult = await axios.get(urlAuthors, { params: rusParams })
      const engResult = await axios.get(urlAuthors, { params: engParams })
      if (rusResult.status !== 200 || engResult.status !== 200) {
        console.log('rusResult', rusResult)
        console.log('engResult', engResult)
        return
      }
      runInAction(() => {
        this.rusAuthors = rusResult.data.data.data
        this.engAuthors = engResult.data.data.data
        const total =
          rusResult.data.data.total > engResult.data.data.total
            ? rusResult.data.data.total
            : engResult.data.data.total
        this.paging = {
          ...this.paging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchAuthorsFilter(language: string, filters: FilterOptions) {
    try {
      runInAction(() => (this.loading = true))
      const rusParams = {
        lang: language,
        foreign: 0,
        name: filters.text,
        ...defaultPaging
      }
      const engParams = {
        lang: language,
        foreign: 1,
        name: filters.text,
        ...defaultPaging
      }
      const rusResult = await axios.get(urlAuthors, { params: rusParams })
      const engResult = await axios.get(urlAuthors, { params: engParams })
      if (rusResult.status !== 200 || engResult.status !== 200) {
        console.log('rusResult', rusResult)
        console.log('engResult', engResult)
        return
      }
      runInAction(() => {
        this.rusAuthors = rusResult.data.data.data
        this.engAuthors = engResult.data.data.data
        const total =
          rusResult.data.data.total > engResult.data.data.total
            ? rusResult.data.data.total
            : engResult.data.data.total
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchAuthorDetail(language: string, id: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(`${urlAuthors}/${id}`, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        console.log('detail', data)
        this.authorDetails = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  public clearDetail() {
    this.authorDetails = defaultAuthorDetail
  }

  async submit(
    email: string,
    token: string,
    openOkModal,
    openErrorModal,
    openInvalidFormat
  ) {
    try {
      runInAction(() => (this.loading = true))
      const params = new FormData()
      params.append('email', email)
      params.append('token', token)
      const result = await axios.post(urlSubmit, params)

      if (result.status !== 200) {
        console.log('result', result)
        return
      }
      const { status, data } = result.data
      if (status === 0) {
        openOkModal()
        return
      }
      if (data === 'Invalid email format') {
        openInvalidFormat()
        return
      }
      openErrorModal()
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async feedback(personData: FeedbackItem, token: string) {
    try {
      runInAction(() => (this.loading = true))
      // const params = new FormData()
      const body = {
        name: personData.name,
        subject: personData.subject,
        message: personData.message,
        email: personData.email
      }
      // params.append('name', personData.name)
      // params.append('subject', personData.subject)
      // params.append('message', personData.message)
      // params.append('email', personData.email)
      const headers = {
        HOST: 'sanctions.event33.ru',
        ctoken: `${token}`
      }
      const result = await axios.post(urlFeedback, body, {
        headers: headers
      })

      if (result.status !== 200) {
        console.log('result', result)
        return
      }
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  public reset
}

export const generalService = new GeneralService()
