import { makeStyles, Theme } from "@material-ui/core";
import { defaultContainer } from "styles/defaultStyle";

export const useOpenDataStyles = makeStyles((theme: Theme) => ({
  container: defaultContainer(theme),
  description: {
    color: theme.palette.text.primary,
    width: "100%",
  },
  linkButton: {
    textDecoration: "none",
    border: `1px solid ${theme.palette.success.main}`,
    padding: "7px 16px",
    borderRadius: 4,
    color: theme.palette.text.primary,
    fontSize: 20,
  },
  titleContainer: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    background: theme.palette.common.white,
  },
  title: {
    fontWeight: 700,
    borderRadius: "4px 4px 0px 0px",
    fontSize: 16,
    lineHeight: "25px",
    color: theme.palette.text.primary,
    background: theme.palette.grey[50],
    padding: "10px 15px",
  },
}));

export const useOpenDataDetailStyles = makeStyles((theme: Theme) => ({
  container: defaultContainer(theme),
  title: {
    background: theme.palette.grey[50],
    borderRadius: "4px 4px 0px 0px",
    display: "flex",
    justifyContent: "center",
    fontSize: 18,
    lineHeight: "25px",
    fontWeight: 700,
    padding: "10px 15px",
    color: theme.palette.text.primary,
  },
  text: {
    color: theme.palette.text.primary,
    width: "100%",
    margin: "25px 0px 50px 0px",
  },
  iframeFile: (isVideo) => ({
    width: "100%",
    height: "auto",
    borderWidth: 0,
    minHeight: isVideo ? 500 : 1000,
    "& .img": {
      width: "100%",
    },
  }),
}));
