import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Card from './Card'
import { Box } from '@material-ui/core'
import { useSanctionStyle } from './SanctionHome.styles'
import { sanctionsService } from 'services/sanctions/sanctions.service'
import { useLanguage } from 'context/Translation'

const SanctionHome: FC = () => {
  const classes = useSanctionStyle()
  const { homeSanctions } = sanctionsService
  const { language } = useLanguage()

  useEffect(() => {
    sanctionsService.fetchHomeSanctions(language)
  }, [language])
  return (
    <Box className={classes.sanctionContainer}>
      <Card info={homeSanctions} />
    </Box>
  )
}

export default observer(SanctionHome)
