import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { scrollTopPage } from "utils";
import { useButtonLinkStyles } from "./Link.styles";
import { LinkProps } from "./Link.types";

const ButtonLink: FC<LinkProps> = ({ link, title, isTranslation = true }) => {
  const classes = useButtonLinkStyles();
  const { t } = useTranslation();
  const titles = isTranslation ? t(title) : title;
  return (
    <Link className={classes.root} to={link} onClick={scrollTopPage}>
      {titles}
    </Link>
  );
};

export default ButtonLink;
