import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {defaultContainer, defaultFilters, tagGridItem} from "styles/defaultStyle";

export const useLegalBaseStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: defaultContainer(theme),
    filters: defaultFilters(theme),
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.grey[50],
      width: "100%",
      color: theme.palette.text.primary,
      padding: "10px 20px",
    },
    detailsBox: {
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 5,
    },
    detailsBoxHeader: {
      backgroundColor: theme.palette.grey[50],
      padding: "10px 20px",
      borderRadius: "4px 4px 0px 0px",
      margin: "0px",
      justifyContent: "space-between",
    },
    headerItem: {
      display: "flex",
      alignItems: "center",
      "& div": {
        padding: 15,
      },
    },
    detailsBoxContain: {
      padding: "10px 17px 30px",
    },
    detailsBoxTitle: {
      paddingTop: 25,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "19px",
      color: theme.palette.secondary.main,
      textTransform: "uppercase",
    },
  })
);

export const useDetailLegalBaseStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: defaultContainer(theme),
    detailContainer: {
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 5,
      backgroundColor: theme.palette.common.white,
    },
    tagItem: tagGridItem,
    header: {
      backgroundColor: theme.palette.grey[50],
      padding: "10px 20px",
      borderRadius: "4px 4px 0px 0px",
      margin: "0px",
    },
    headerItem: {
      display: "flex",
    },
    headerTitle: {
      paddingLeft: 15,
      color: theme.palette.text.primary,
      fontWeight: 700,
      textTransform: "uppercase",
    },
    Green: {
      color: theme.palette.success.main,
    },
    Blue: {
      color: theme.palette.primary.main,
    },
    Red: {
      color: theme.palette.secondary.main,
    },
    title: {
      paddingTop: 25,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "19px",
      color: theme.palette.secondary.main,
      textTransform: "uppercase",
    },
    text: {
      padding: "25px 0px",
    },
    linkedTitle: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "27px",
      textTransform: "uppercase",
      color: theme.palette.secondary.main,
    },
  })
);
