import { FC, Fragment } from "react";
import Header from "components/common/Header";
import Footer from "components/common/Footer";
import SearchPage from "components/Search";

const SearchView: FC = () => (
  <Fragment>
    <Header />
    <SearchPage />
    <Footer />
  </Fragment>
);

export default SearchView;
