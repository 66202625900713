import { makeStyles, Theme } from '@material-ui/core'
import header from 'assets/image/header.png'

export const useHeaderStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '10px 0',
    backgroundImage: `url(${header})`,
    backgroundSize: '70% 100%',
    backgroundRepeat: 'no-repeat',
    height: 130,
    width: '100%',
    position: 'relative',
    [theme.breakpoints.between(300, 600)]: {
      padding: '10px 5% 0px 5%'
    }
  },
  icon: {
    color: theme.palette.common.black,
    '& span': {
      '& svg': {
        color: theme.palette.common.black
      }
    }
  },
  headerContainer: {
    margin: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  hoverMenu: {
    minWidth: '100px',
    marginTop: 10,
    zIndex: 2
  },
  headerImage: {
    width: '70%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    '& img': {
      height: '100%',
      maxWidth: '100%'
    }
  },
  headerTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    top: '50%',
    position: 'absolute'
  },
  mobileHeaderTitle: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0,
    [theme.breakpoints.down(900)]: {
      width: '50%',
      display: 'flex',
      left: '25%',
      flexWrap: 'wrap',
      textAlign: 'center'
    }
  },
  topHeader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    position: 'relative'
  },
  text: {
    fontSize: 30,
    fontWeight: 700,
    color: theme.palette.info.main,
    [theme.breakpoints.down(1500)]: {
      fontSize: 20
    },
    [theme.breakpoints.down(900)]: {
      fontSize: 14
    }
  },
  menu: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    justifyContent: 'space-between'
  },
  loginButton: {
    height: '100%',
    width: 160,
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    transition: 'color 500ms',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main
    }
  },
  userBox: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      cursor: 'unset'
    }
  },
  buttonBox: {
    height: '50px',
    padding: 0
  },
  menuGrid: {
    background: theme.palette.common.white,
    padding: '10px 50px',
    borderRadius: 5,
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto auto auto auto',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  linkStyle: {
    '& a': {
      color: theme.palette.text.primary,
      display: 'block',
      /* line-height: 1; */
      fontFamily: 'NotoSans',
      fontSize: 18,
      textDecoration: 'none',
      cursor: 'pointer',
      position: 'relative'
    },
    '&:after': {
      display: 'block',
      content: '"',
      backgroundColor: '#9f2914',
      height: 3,
      width: '100%',
      position: 'absolute'
    }
  },
  activeLinkStyles: {
    display: 'inline-block',
    color: '#fff',
    /* line-height: 1; */
    fontFamily: 'NotoSans',
    fontSize: 18,
    textDecoration: 'none',
    cursor: 'pointer',
    position: 'relative',
    '&:after': {
      display: 'block',
      content: '"',
      backgroundColor: '#9f2914',
      height: 3,
      width: '0%',
      left: '50%',
      position: 'absolute',
      WebkitTransition: 'width 0.3s ease-in-out',
      MozTransition: 'width 0.3s ease-in-out',
      transition: 'width 0.3s ease-in-out',
      WebkitTransform: 'translateX(-50%)',
      MozTransform: 'translateX(-50%)',
      transform: 'translateX(-50%)'
    },
    '& a': {}
  },
  commonMenu: {
    background: theme.palette.common.white,
    padding: 10,
    borderRadius: 5,
    width: '100%',
    height: 150
  },
  mobileMenuTootip: {
    width: '90%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    zIndex: 11,
    position: 'absolute',
    margin: '0 auto',
    left: '5%',
    right: '5%',
    top: '50%'
  },
  search: {
    width: 300,
    marginRight: 10
  },
  searchContainer: {
    display: 'flex',
    margin: '0 auto',
    justifyContent: 'end',
    alignItems: 'center',
    width: '100%',
    height: '90px',
    position: 'absolute',
    top: 0,
    padding: '0 10px',
    left: 0
  },
  mobileInput: {
    width: '500px',
    marginRight: 10,
    borderRadius: 5,
    height: 30
  }
}))
