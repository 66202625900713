import { FC, Fragment } from "react";
import Header from "components/common/Header";
import Footer from "components/common/Footer";
import TagsPage from "components/Tags";

const Tags: FC = () => (
  <Fragment>
    <Header />
    <TagsPage />
    <Footer />
  </Fragment>
);

export default Tags;
