import { Navigation } from 'models'
import { routers } from 'routers'

export const navigation: Navigation[] = [
  {
    title: 'Главная',
    link: '/home'
  },
  {
    title: 'О проекте',
    link: '/about'
  },
  {
    title: 'Новости',
    link: '/news'
  },
  {
    title: 'Санкции',
    link: '/sanctions'
  },
  {
    title: 'Аналитика',
    link: '/analytics'
  },

  {
    title: 'Обратная связь',
    link: '/feedback'
  }
  // {
  //   title: "about",
  //   link: routers.about.home,
  //   childrenLinks: [
  //     {
  //       title: "team",
  //       link: routers.about.team,
  //     },
  //     // {
  //     //   title: "partners",
  //     //   link: routers.about.partners,
  //     // },
  //     {
  //       title: "authors",
  //       link: routers.about.authors,
  //     },
  //     // {
  //     //   title: "clients",
  //     //   link: routers.about.clients,
  //     // },
  //   ],
  // },
  // {
  //   title: "analytics",
  //   link: routers.analytics.home,
  //   childrenLinks: [],
  // },
  // {
  //   title: "events",
  //   link: routers.events,
  // },

  // {
  //   title: "mediacenter",
  //   link: routers.mediacenter.news,
  //   isLink: false,
  //   childrenLinks: [
  //     {
  //       title: "news",
  //       link: routers.mediacenter.news,
  //     },
  //     {
  //       title: "photos",
  //       link: routers.mediacenter.photos,
  //     },
  //     {
  //       title: "videos",
  //       link: routers.mediacenter.videos,
  //     },
  //   ],
  // },
  // {
  //   title: "legal-base",
  //   link: routers.legalBase,
  // },
  // {
  //   title: "open-data",
  //   link: routers.openData,
  // },
]

export const footers: Navigation[] = [
  {
    title: 'site-map',
    link: routers.siteMap
  },
  {
    title: 'terms-materials',
    link: routers.termsMaterials
  },
  {
    title: 'privacy-policy',
    link: routers.privacyPolicy
  },
  {
    title: 'faq',
    link: routers.faq
  }
  // {
  //   title: "open-data",
  //   link: routers.openData,
  // },
]
