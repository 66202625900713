import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { routers } from 'routers'
import Footer from 'components/common/Footer'
import Feedback from 'components/FeedBack/Feedback'
interface Props {
  username: string
  password: string
}

const FeedBack: React.FC<Props> = () => {
  return (
    <>
      <Fragment>
        <Switch>
          <Route exact path={routers.feedback}>
            <Feedback />
          </Route>
        </Switch>
        <Footer />
      </Fragment>
    </>
  )
}

export default FeedBack
