import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import getOrigin from "utils/getOrigin";
import { defaultPaging } from "enums";
import {
  DocumentListItem,
  EventListItem,
  NewListItem,
  ResearchListItem,
} from "models";

const urlTag = getOrigin() + "api/tag/";

class TagsService {
  public idTag = 0;
  public tagName = "";
  public paging = defaultPaging;
  public newList: NewListItem[] = [];
  public analyticList: ResearchListItem[] = [];
  public eventList: EventListItem[] = [];
  public documentList: DocumentListItem[] = [];
  public lang = "";
  private totals = {
    news: 0,
    analytics: 0,
    events: 0,
    "legal-base": 0,
  };
  public loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async fetchTagInfo(language: string, tagId: number, tab: string) {
    try {
      console.log("tab", tab);
      runInAction(() => (this.loading = true));
      const params = {
        lang: language,
        ...this.paging,
      };
      const result = await axios.get(`${urlTag}${tagId}`, { params });
      if (result.status !== 200) {
        return console.log("result tag", result);
      }

      runInAction(() => {
        console.log("total", this.retutnTotal(result.data.data, tab));
        const { news, researches, events, documents, name } = result.data.data;
        this.tagName = name;
        this.newList = news.data;
        this.analyticList = researches.data;
        this.eventList = events.data;
        this.documentList = documents.data;
        this.lang = language;
        this.totals = {
          news: news.total,
          analytics: researches.total,
          events: events.total,
          "legal-base": documents.total,
        };
        this.idTag = tagId;
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(
            this.retutnTotal(result.data.data, tab) / defaultPaging.psize
          ),
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  public setElementTotal(tab: string) {
    this.paging = {
      ...defaultPaging,
      count: Math.ceil(this.totals[tab] / defaultPaging.psize),
    };
  }

  private retutnTotal = (data, tab) => {
    switch (tab) {
      case "news":
        return data.news.total;
      case "analytics":
        return data.researches.total;
      case "events":
        return data.events.total;
      case "legal-base":
        return data.documents.total;
      case "author":
        return;
      default:
        return 0;
    }
  };
}

export const tagsService = new TagsService();
