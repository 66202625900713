import { FC, Fragment, useEffect, useReducer } from 'react'
import { Route, Switch } from 'react-router-dom'
import Footer from 'components/common/Footer'
import { FilterOptions } from 'models/filters'
import { defaultFilterOptions } from 'enums'
import { sanctionsService } from 'services/sanctions/sanctions.service'
import { routers } from 'routers'
import SanctionPage from 'components/Sanction'
import SanctionsDetail from 'components/Sanction/Details'
const reducer = (
  options: FilterOptions,
  newOptions: Partial<FilterOptions>
) => ({
  ...options,
  ...newOptions
})

const Sanctions: FC = () => {
  const [filterOptions, setFilterOptions] = useReducer(
    reducer,
    defaultFilterOptions
  )

  useEffect(() => {
    return function cleanupPage() {
      sanctionsService.cleanPage()
    }
  }, [])

  return (
    <Fragment>
      <Switch>
        <Route exact path={routers.sanctions}>
          <SanctionPage
            checkedValues={filterOptions}
            onChange={setFilterOptions}
          />
        </Route>
        <Route
          exact
          path={`${routers.sanctions}/:id`}
          component={SanctionsDetail}
        />
      </Switch>
      <Footer />
    </Fragment>
  )
}

export default Sanctions
