import { Button } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { FC } from 'react'
import { useButtonsStyles } from './Buttons.styles'
import { LoginButtonProps } from './Buttons.types'
import { Link, useHistory } from 'react-router-dom'

const LanguageButton: FC<LoginButtonProps> = ({
  isDark = false,
  login = false
}) => {
  const classes = useButtonsStyles(isDark)
  const history = useHistory()
  const handleClick = () => {
    localStorage.clear()
    history.push('/login')
  }
  return (
    <Link to="/login">
      {!login ? (
        <Button
          className={classes.login}
          onClick={handleClick}
          startIcon={<ExitToAppIcon />}
        >
          logout
        </Button>
      ) : (
        <Button className={classes.login} startIcon={<ExitToAppIcon />}>
          Login
        </Button>
      )}
    </Link>
  )
}

export default LanguageButton
