import { FC, Fragment, useEffect } from 'react'
import SectionTitle from 'components/common/SectionTitle'
import Breadcrumb from 'components/common/Breadcrumb'
import { useSanctionStyles } from './Sanctions.styles'
import { Box, Grid } from '@material-ui/core'
import { FilterCombinedProps, FilterOptions } from 'models/filters'
import SanctionsCard from 'components/common/Cards/SanctionsCard/SanctionsCard'
import { observer } from 'mobx-react-lite'
import Paging from 'components/common/Paging'
import { scrollTopPage } from 'utils'
import EmptyFilterResult from 'components/common/StubsAndBugs/EmptyFilterResult'
import SanctionFilter from 'components/common/Filters/SanctionFilter'
import { sanctionsService } from 'services/sanctions/sanctions.service'
import { useLanguage } from 'context/Translation'
import Spiner from 'components/common/Spiner'
import { useHistory } from 'react-router-dom'

const SanctionPage: FC<FilterCombinedProps<FilterOptions>> = ({
  checkedValues,
  onChange
}) => {
  const { sanctions, paging, loading } = sanctionsService
  const authUser = localStorage.getItem('authorizeStatus')?.toString()

  const history = useHistory()

  const userPermission = localStorage.getItem('have_permission')
  useEffect(() => {
    switch (authUser) {
      case '3':
        history.push('/login')
        break
      case undefined:
        history.push('/login')
        break
      case '2':
        history.push('/login')

        return
        break

      case '0':
        return
        break

      default:
        history.push('/login')
        break
    }
  }, [authUser])
  const classes = useSanctionStyles()
  const { language } = useLanguage()
  const expired_date = localStorage.getItem('expired') || ''

  const decodedTimestamp = expired_date ? parseInt(expired_date, 10) : 0
  const decodedDate = new Date(decodedTimestamp * 1000)

  useEffect(() => {
    if (userPermission === 'true') {
      if (decodedDate >= new Date()) {
        sanctionsService.fetchSanctions(language)
      } else {
        localStorage.clear()
        history.push('/login')
      }
    } else {
      localStorage.clear()
      history.push('/login')
    }
  }, [userPermission])

  const handlePageChange = (page: number) => {
    sanctionsService.fetchPagingSanctions(page)
    scrollTopPage()
  }

  const handleFindClick = () => {
    sanctionsService.fetchSanctionsFilter(language, checkedValues)
  }

  return (
    <Fragment>
      <SectionTitle background="sanction">
        <SanctionFilter
          checkedValues={checkedValues}
          onChange={onChange}
          onFind={handleFindClick}
        />
      </SectionTitle>
      <Breadcrumb />

      {loading ? (
        <Spiner />
      ) : (
        <Box className={classes.container}>
          <Box>
            <Grid container spacing={2} className={classes.contentContainer}>
              {sanctions && sanctions.length ? (
                sanctions.map((item, idx) => (
                  <Grid item xs={12} key={idx}>
                    <SanctionsCard item={item} />
                  </Grid>
                ))
              ) : (
                <EmptyFilterResult />
              )}
            </Grid>
            <Paging paging={paging} onChange={handlePageChange} />
          </Box>
        </Box>
      )}
    </Fragment>
  )
}

export default observer(SanctionPage)
