import { makeStyles, Theme } from '@material-ui/core'

const useContainerStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    margin: 0,
    color: theme.palette.common.black,
    [theme.breakpoints.up('mobileVertical')]: {
      width: '100%'
    },
    [theme.breakpoints.up('mobileHorisontal')]: {
      width: '100%'
    }
    // [theme.breakpoints.up('tablet')]: {
    //   width: 600
    // },
    // [theme.breakpoints.up('tabletVertical')]: {
    //   width: 768
    // },
    // [theme.breakpoints.up('tabletHorisontal')]: {
    //   width: 1024
    // },
    // [theme.breakpoints.up('laptop')]: {
    //   width: 1280
    // },
    // [theme.breakpoints.up('desktop')]: {
    //   width: '100%'
    // }
  }
}))

export default useContainerStyle
