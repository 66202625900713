import { AutorDetails } from "models";

export const defaultAuthorDetail: AutorDetails = {
  id: 0,
  name: "",
  short_name: "",
  email: "",
  photo: "",
  work: "",
  position: "",
  foreign: false,
  news: {
    data: [],
    total: 0,
  },
  researches: {
    data: [],
    total: 0,
  },
  description: "",
};
