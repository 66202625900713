export const routers = {
  home: '/',
  termsMaterials: '/terms-materials',
  privacyPolicy: '/privacy-policy',
  siteMap: '/site-map',
  feedback: '/feedback',
  faq: '/faq',
  tags: '/tags',
  sanctions: '/sanctions',
  searchByKey: '/search-by-key',
  openData: '/open-data',
  login: '/login',
  register: '/register',
  // analytics: {
  //   home: '/analytics',
  //   block: '/analytics?block='
  // },
  analytics: '/analytics',
  events: '/events',
  news: '/news',
  mediacenter: {
    home: '/mediacenter',
    news: '/mediacenter/news',
    photos: '/mediacenter/photos',
    videos: '/mediacenter/videos'
  },
  about: {
    home: '/about',
    team: '/about/team',
    partners: '/about/partners',
    authors: '/about/authors',
    clients: '/about/clients'
  },
  legalBase: '/legal-base'
}
