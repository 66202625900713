import { makeAutoObservable, runInAction } from 'mobx'
import axios from 'axios'
import { AboutItem } from 'models'
import { defaultaboutProject } from './abou.data'

const urlAbout = '/api/block/about'
const urlAboutImage = '/api/block/about_image'

class AboutService {
  public about: AboutItem = defaultaboutProject
  public aboutImage: AboutItem = defaultaboutProject
  public lang = ''
  public loading = false

  constructor() {
    makeAutoObservable(this)
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async fetchAboutInfo() {
    try {
      runInAction(() => (this.loading = true))

      const result = await axios.get(urlAbout)
      if (result.status !== 200) {
        return console.log('result tag', result)
      }

      runInAction(() => {
        const { data } = result.data
        this.about = data
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
  async fetchAboutImage() {
    try {
      runInAction(() => (this.loading = true))

      const result = await axios.get(urlAboutImage)
      if (result.status !== 200) {
        return console.log('result tag', result)
      }

      runInAction(() => {
        const { data } = result.data
        this.aboutImage = data
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
}

export const aboutService = new AboutService()
