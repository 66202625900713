import { FC, Fragment, useEffect } from 'react'
import SectionTitle from 'components/common/SectionTitle'
import Breadcrumb from 'components/common/Breadcrumb'
import { useDetailNewsStyles } from './News.styles'
import { Box, Grid } from '@material-ui/core'
import { Event } from '@material-ui/icons'
import { newsService } from 'services/news/news.service'
import { getUrlAdress } from 'utils'
import { useLanguage } from 'context/Translation'
import { observer } from 'mobx-react-lite'
import { format } from 'date-fns'
import HtmlElement from 'components/common/HtmlElement'
import Line from 'components/common/Line'
import Tag from 'components/common/Tag'
import Spiner from 'components/common/Spiner'

const NewsDetail: FC = () => {
  const classes = useDetailNewsStyles()
  const url = getUrlAdress(window.location.pathname)
  const id = url[url.length - 1].name
  const { language } = useLanguage()
  const { detail, lang, loading } = newsService

  useEffect(() => {
    if (id === null) return
    if (detail.id !== Number(id)) {
      newsService.fetchDetail(language, Number(id))
      return
    }
    if (lang !== language) {
      newsService.fetchDetail(language, Number(id))
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, language, id])

  useEffect(() => {
    return function cleanupPage() {
      newsService.cleanDetail()
    }
  }, [])

  if (loading) {
    return (
      <Fragment>
        <SectionTitle isShown background={'sanction'} title={detail.title} />
        <Box className={classes.container}>
          <Spiner />
        </Box>
      </Fragment>
    )
  }
  const image = detail.image.map((img) => {
    return img['full']
  })

  return (
    <Fragment>
      <SectionTitle background="sanction" title={detail.title} />
      <Breadcrumb title={detail.title} />
      <Box className={classes.container}>
        <Box className={classes.title}>{detail.title}</Box>
        <Grid container spacing={4} direction="column">
          <Grid item xs container spacing={2}>
            <Grid item xs>
              <Box className={`${classes.text} ${classes.textMargin}`}>
                <div className={classes.iconTextdiv}>
                  <Event />
                  <p>
                    {detail.date
                      ? format(new Date(detail.date), 'dd.MM.yyyy')
                      : null}{' '}
                  </p>
                </div>
              </Box>

              {Object.keys(detail.text).length === 0 ? null : (
                <HtmlElement
                  component="div"
                  classes={`${classes.text} ${classes.textMargin}`}
                  html={detail.text}
                />
              )}
            </Grid>
            <Grid item>
              <Box
                width={350}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                className={classes.imgBox}
              >
                <img className={classes.img} src={image[0]} alt="" />
              </Box>
            </Grid>
          </Grid>

          {detail.tags ? (
            <Grid item xs>
              <Line />
              <Box pt={'30px'} style={{ display: 'flex' }}>
                {detail.tags.map((tag) => (
                  <Tag key={tag.id} id={tag.id} title={tag.name} marginRight />
                ))}
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Fragment>
  )
}

export default observer(NewsDetail)
