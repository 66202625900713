import { FC } from "react";
import { usePhotoStyle } from "./Photos.styles";
import { PhotoCardProps } from "./Photos.types";
import { Link } from "react-router-dom";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import { scrollTopPage } from "utils";
import { routers } from "routers";

const PhotoCard: FC<PhotoCardProps> = ({ id, title, date, urlPicture }) => {
  const classes = usePhotoStyle();

  return (
    <Card className={classes.cardItem}>
      <CardActionArea
        component={Link}
        to={`${routers.mediacenter.photos}/${id}`}
        onClick={scrollTopPage}
      >
        <CardMedia className={classes.media} image={urlPicture}>
          <Box display="flex" alignItems="flex-end">
            <Box className={classes.dateOnImage}>
              {format(new Date(date), "dd.MM.yyyy")}
            </Box>
          </Box>
        </CardMedia>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title} gutterBottom component="div">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default PhotoCard;
