import { FC } from "react";
import { Box } from "@material-ui/core";
import { TabPanelProps } from "./TabsPanel.types";
import useTabPanelStyles from "./TabsPanel.styles";

const TabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  isBigMargin,
  ...other
}) => {
  const classes = useTabPanelStyles();
  return (
    <div
      className={classes.panelMargin}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingTop={isBigMargin ? "20px" : 0}>{children}</Box>
      )}
    </div>
  );
};

export default TabPanel;
