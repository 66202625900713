import { FC } from 'react'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography
} from '@material-ui/core'
import { navigation, footers } from 'enums/navigation'
import { FooterLink } from '../Link'
import { showTitle } from 'utils'
import { Call, Email, LocationOn } from '@material-ui/icons'
import { useFooterStyles } from './Footer.styles'
import { useTranslation } from 'react-i18next'
import { FooterProps } from './Footer.types'
import { allRights, phoneNumber } from './Footer.util'
import useContainerStyle from '../Container/Container.styles'

const Default: FC<FooterProps> = () => {
  const classes = useFooterStyles()
  const containerStyle = useContainerStyle()
  const { t } = useTranslation()
  return (
    <Box className={classes.footerBackground}>
      <footer className={classes.footer}>
        <Grid container className={`${classes.root} ${containerStyle.root}`}>
          <Grid container item xs={12} className="footerDetails">
            <Grid xs={2}>
              <List
                subheader={
                  <ListSubheader component="h1">
                    {showTitle(t('portal-name'), '', false)}
                  </ListSubheader>
                }
              >
                <ListItem />
              </List>
            </Grid>
            <Grid className="quickLinks" xs={3}>
              <List>
                {navigation.map(({ link, title }) => (
                  <FooterLink key={link} link={link} title={title} />
                ))}
              </List>
            </Grid>
            <Grid className="quickLinks" xs={3}>
              <List>
                {footers.map(({ link, title }) => (
                  <FooterLink key={link} link={link} title={title} />
                ))}
              </List>
            </Grid>
            <Grid xs={4}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <LocationOn />
                  </ListItemIcon>
                  <ListItemText primary={t('location')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Email />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${t('manager')} ${t('manager-information')}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Call />
                  </ListItemIcon>
                  <ListItemText primary={phoneNumber} />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} className="social-rights">
              <Box className={classes.rights}>
                <Typography>{allRights}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </Box>
  )
}

export default Default
