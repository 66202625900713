import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { tagGridItem } from "styles/defaultStyle";

export const useResearchCardStyles = makeStyles<Theme>((theme) => ({
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    boxShadow: "none",
    height: "100%",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    color: theme.palette.common.white,
    padding: "10px 20px",
    borderRadius: "5px 5px 0px 0px",
  },
  titleContainer: {
    padding: "16px 20px",
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "25px",
    maxHeight: 120,
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  title: {
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  autors: {
    display: "flex",
    padding: "5px 20px",
    width: "100%",
    backgroundColor: theme.palette.grey[50],
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "25px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  imgDescriptionContainer: {
    padding: "20px 20px 20px 0px",
    display: "flex",
    justifyContent: "space-between",
  },
  imgBox: {
    width: 300,
    marginRight: 20,
    "& img": {
      width: "100%",
      height: 300,
      borderRadius: "0px 5px 5px 0px",
      objectFit: "cover",
    },
    [theme.breakpoints.down(480)]: {
      display: "none",
    },
  },
  description: {
    width: "100%",
    height: 300,
    "& div": {
      color: theme.palette.text.primary,
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 15,
      "-webkit-box-orient": "vertical",
      height: 300,
      textOverflow: "ellipsis",
    },
    [theme.breakpoints.down(480)]: {
      paddingLeft: 20,
    },
  },
  tagItem: tagGridItem,
}));

export const useResearchBlockCardStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "30px 20px",
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    boxShadow: "none",
    minHeight: 600,
    height: "100%",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    color: theme.palette.common.white,
    padding: "10px 20px",
  },
  title: {
    display: "flex",
    padding: "16px 20px",
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "25px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  autors: {
    display: "flex",
    padding: "5px 20px",
    width: "100%",
    backgroundColor: theme.palette.grey[50],
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "25px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  imgNameContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 200,
    alignItems: "center",
  },
  eventName: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
  },
  description: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& ul": {
      paddingInlineStart: 10,
    },
  },
}));

export const useBlockCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      background: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 8,
      width: 270,
      height: 270,
      padding: "10px 30px 30px 30px",
    },
    img: {
      width: 100,
      height: 120,
    },
    blockName: {
      fontWeight: 700,
      fontSize: 18,
      textAlign: "center",
      color: theme.palette.text.primary,
    },
  })
);
