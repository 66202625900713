import { Box, CircularProgress } from "@material-ui/core";
import { FC } from "react";
import { SpinerProps } from "./Spiner.types";

const Spiner: FC<SpinerProps> = ({ isFone = false }) => (
  <Box
    display={"flex"}
    alignItems={"center"}
    justifyContent={"center"}
    height={"100vh"}
    style={{ background: isFone ? "white" : "none" }}
  >
    <Box>
      <CircularProgress color={"secondary"} size={140} />
    </Box>
  </Box>
);

export default Spiner;
