import { makeStyles, Theme } from '@material-ui/core'
export const useSanctionStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  researchBlockCard: {
    border: '1px solid blue'
  },
  headerBackground: {
    width: '100%',
    background: theme.palette.grey[50],
    padding: 30
  },
  flagIcon: {
    width: 30,
    height: 30,
    marginRight: 5,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  },
  contentContainer: {
    margin: 0,
    width: '100%'
  },
  iconTextdiv: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main
    },
    '& p': {
      margin: '5px'
    }
  },
  title: {
    fontSize: 24,
    lineHeight: '33px',
    textTransform: 'uppercase',
    fontWeight: 700,
    color: theme.palette.text.primary
  },
  tagBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  icon: {
    marginRight: 10,
    color: theme.palette.secondary.main
  },
  dateBox: {
    width: 230,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main
  },
  linkButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    borderRadius: 5,
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white
    }
  },
  name: {
    color: theme.palette.secondary.main,
    minWidth: 'fit-content',
    [theme.breakpoints.down(690)]: {
      fontSize: 14
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 12
    }
  },
  countryName: {
    color: theme.palette.secondary.main
  },
  gridBox: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 3%'
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    lineHeight: '19px',
    textAlign: 'justify',
    display: 'flex',
    alignItems: 'baseline'
  },
  textMargin: {
    margin: '10px 30px'
  },
  img: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    marginTop: 30,
    width: 350,
    height: 250
  }
}))
