import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useTextStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontFamily: "NotoSans",
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
    sectionHeading: {
      fontFamily: "NotoSans",
      fontSize: 14,
      lineHeight: "19px",
      fontWeight: 700,
      textTransform: "uppercase",
      color: theme.palette.secondary.main,
    },
    main: {
      fontFamily: "NotoSans",
      fontSize: 14,
      lineHeight: "19px",
      fontWeight: 500,
      textAlign: "justify",
      color: theme.palette.text.secondary,
    },
  })
);
