import { FC, useRef, useState } from 'react'
import Chapter from 'components/common/Сhapter'
import { Button, Grid, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useFeedbackChapterStyle } from './AboutProjectHome.styles'
import { useLanguage } from 'context/Translation'
import { generalService } from 'services/general/general.service'
import { FeedbackItem } from 'models'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ReCAPTCHA from 'react-google-recaptcha'
const FeedbackChapter: FC = () => {
  const { t } = useTranslation()
  const { language } = useLanguage()
  const [theme, setTheme] = useState('')
  const [fio, setFIO] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const { executeRecaptcha } = useGoogleReCaptcha()
  const classes = useFeedbackChapterStyle()
  const recaptchaRef = useRef(null)
  const handleFioChange = ({ target: { value } }) => {
    setFIO(value)
  }

  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value)
  }

  const handleThemeChange = ({ target: { value } }) => {
    setTheme(value)
  }

  const handleMessageChange = ({ target: { value } }) => {
    setMessage(value)
  }

  const handleFeedbackClick = async (e) => {
    const person: FeedbackItem = {
      name: fio,
      subject: theme,
      message,
      email
    }
    e.preventDefault()

    try {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available')
        return
      }
      executeRecaptcha('submit').then(async (token) => {
        console.log(token, '******result')

        await generalService.feedback(person, token)
      })
    } catch (err) {
      throw new Error('Token error')
    }
  }

  const isDisabled =
    !fio.length || !theme.length || !message.length || !email.length

  return (
    <Chapter title="feedback" isButton={false} padding="30px 0px">
      <Grid container spacing={4}>
        <Grid
          className={classes.feedbackAndMap}
          item
          container
          spacing={3}
          direction="column"
        >
          <Grid item>
            <TextField
              className={classes.textField}
              label={t('name-user')}
              size="small"
              variant="outlined"
              value={fio}
              onChange={handleFioChange}
            />
          </Grid>
          <Grid item>
            <TextField
              className={classes.textField}
              label="E-mail"
              size="small"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item>
            <TextField
              className={classes.textField}
              label={t('theme')}
              size="small"
              variant="outlined"
              value={theme}
              onChange={handleThemeChange}
            />
          </Grid>
          <Grid item>
            <TextField
              className={classes.textField}
              placeholder={t('message')}
              multiline
              rows={4}
              value={message}
              onChange={handleMessageChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              color={isDisabled ? 'default' : 'primary'}
              variant={'contained'}
              className={classes.sendButton}
              onClick={handleFeedbackClick}
              disabled={isDisabled}
            >
              {t('send')}
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.feedbackAndMap} item>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="your_site_key"
            size="invisible" // Set size to invisible for programmatic execution
          />
        </Grid>
        <Grid className={classes.feedbackAndMap} item>
          <iframe
            src={`https://yandex.ru/map-widget/v1/?um=constructor%3A755b02345a31f8b8329232835f7d29f977e14413a7cecf6926ec262e84cfde01&amp;source=constructor" &lang=${language.toLocaleLowerCase}_${language.toLocaleLowerCase} width="840" height="507" frameborder="0"`}
            width={'100%'}
            height="400"
            frameBorder="0"
          />
        </Grid>
      </Grid>
      <></>
    </Chapter>
  )
}

export default FeedbackChapter
