import { SanctionItem, internalSanctionsItem } from 'models'

export const defaultSanctionsDetail: internalSanctionsItem = {
  info: [],
  values: [],
  data: [],
  id: 0,
  image: [],
  field_sanction_date: '',
  field_sanction_exceptions: {
    value: []
  },
  field_sanction_justification_en: {
    value: []
  },
  field_sanction_comments: {
    info: {
      label: ''
    },
    value: [
      {
        value: ''
      }
    ]
  },
  field_sanctioner: {
    value: [
      {
        name: ''
      }
    ]
  },
  field_sanction_required: {
    value: []
  },
  field_sanction_revision: {
    value: []
  },
  field_sanction_country: {
    value: [
      {
        name: '',
        icon: '',
        id: 0
      }
    ]
  },
  field_sanction_is_economic: '',
  field_sanction_type: {
    value: []
  },
  field_sanction_domain: '',
  field_sanction_restrictions: '',
  field_sanction_doc_link: {
    value: [
      {
        uri: ''
      }
    ]
  },
  field_sanction_justification: '',
  field_tags: {
    value: []
  }
}

export const defaultHomeSanctions: SanctionItem = {
  title: '',
  text: ''
}
