import { FC, Fragment, useEffect } from 'react'
import SectionTitle from 'components/common/SectionTitle'
import Breadcrumb from 'components/common/Breadcrumb'
import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { useNewsDetailStyles } from './News.style'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from 'pure-react-carousel'
import Line from 'components/common/Line'
import Tag from 'components/common/Tag'
import { mediacenterService } from 'services/mediacenter/mediacenter.service'
import { useLanguage } from 'context/Translation'
import { getUrlAdress } from 'utils'
import { observer } from 'mobx-react-lite'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import HtmlElement from 'components/common/HtmlElement'
import Spiner from 'components/common/Spiner'
import { newsService } from 'services/news/news.service'

const NewDetail: FC = () => {
  const classes = useNewsDetailStyles()
  const url = getUrlAdress(window.location.pathname)
  const id = url[url.length - 1].name
  const { language } = useLanguage()
  const { detail, loading, lang } = newsService
  const theme = useTheme()
  const isLong = useMediaQuery(theme.breakpoints.up(800))

  useEffect(() => {
    if (id === null) return
    if (detail.id !== Number(id)) {
      mediacenterService.fetchNewDetail(language, Number(id))
      return
    }
    if (lang !== language) {
      mediacenterService.fetchNewDetail(language, Number(id))
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, language, id])

  useEffect(() => {
    return function cleanupPage() {
      mediacenterService.cleanDetail()
    }
  }, [])

  const TagContent = (
    <Grid container spacing={2}>
      {detail.tags.map((item) => (
        <Grid item key={item.id} className={classes.tagItem}>
          <Tag id={item.id} title={item.name} />
        </Grid>
      ))}
    </Grid>
  )

  if (loading) {
    return (
      <Fragment>
        <SectionTitle isEnd background="earth" />
        <Breadcrumb isEnd title={detail?.title} />
        <Box className={classes.detailContainer}>
          <Spiner />
        </Box>
      </Fragment>
    )
  }

  const ImageCarusel =
    detail?.image && detail?.image.length > 3 ? (
      <CarouselProvider
        naturalSlideWidth={950}
        naturalSlideHeight={195}
        totalSlides={detail.image ? Math.ceil(detail.image.length / 4) : 1}
        isPlaying
        className={classes.carusel}
        infinite={true}
      >
        {detail?.image.length / 4 > 1 ? (
          <ButtonBack className={classes.buttons}>
            <ArrowBackIos fontSize={'large'} />
          </ButtonBack>
        ) : null}
        <Slider className={classes.slider}>
          <Slide index={0}>
            <Box display="flex" justifyContent="space-beetwen">
              <img
                className={classes.image}
                src={detail.image[0].full}
                alt=""
              />
            </Box>
          </Slide>
        </Slider>
        {detail?.image.length / 4 > 1 ? (
          <ButtonNext className={classes.buttons}>
            <ArrowForwardIos fontSize="large" />
          </ButtonNext>
        ) : null}
      </CarouselProvider>
    ) : (
      <Box display="flex" justifyContent="center">
        <img className={classes.image} src={detail.image[0].full} alt="" />
      </Box>
    )

  return (
    <Fragment>
      <SectionTitle isEnd background="earth" />
      <Breadcrumb
        isEnd
        title={isLong ? detail?.title : detail?.title.slice(0, 8)}
      />
      <Box className={classes.detailContainer}>
        <Box className={classes.title}>{detail?.title}</Box>
        <Grid container spacing={4} direction="column">
          <Grid item xs container spacing={4}>
            <Grid item xs={detail.image.length === 1}>
              <HtmlElement
                component="div"
                classes={classes.text}
                html={detail?.summary ?? ''}
              />
            </Grid>
            {detail.image.length === 1 ? (
              <Grid item>
                <Box className={classes.imgContainerNotAutor}>
                  <img
                    className={classes.imgNotAutor}
                    src={detail.image[0].full}
                    alt=""
                  />
                </Box>
              </Grid>
            ) : (
              <Grid item xs>
                {ImageCarusel}
              </Grid>
            )}
          </Grid>
          {detail.tags ? (
            <Grid item xs>
              <Line />
              <Box padding={'30px 0px'}>{detail.tags ? TagContent : null}</Box>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Fragment>
  )

  return (
    <Fragment>
      <SectionTitle isEnd background="earth" />
      <Breadcrumb title={detail?.title} />
      <Box className={classes.detailContainer}>
        {!detail.id ? (
          <Spiner />
        ) : (
          <Grid container spacing={4}>
            <Grid item xs>
              <Box className={classes.title}>{detail.title}</Box>
              <HtmlElement
                component="div"
                classes={classes.text}
                html={detail?.summary ?? ''}
              />

              <Box padding={'30px 0px'}>
                {detail?.image && detail?.image.length > 3 ? (
                  <CarouselProvider
                    naturalSlideWidth={950}
                    naturalSlideHeight={195}
                    totalSlides={
                      detail.image ? Math.ceil(detail.image.length / 4) : 1
                    }
                    isPlaying
                    className={classes.carusel}
                    infinite={true}
                  >
                    {detail?.image.length / 4 > 1 ? (
                      <ButtonBack className={classes.buttons}>
                        <ArrowBackIos fontSize={'large'} />
                      </ButtonBack>
                    ) : null}
                    <Slider className={classes.slider}>
                      <Slide index={0}>
                        <Box display="flex" justifyContent="space-beetwen">
                          <img
                            className={classes.image}
                            src={detail.image[0].full}
                            alt=""
                          />
                        </Box>
                      </Slide>
                    </Slider>
                    {detail?.image.length / 4 > 1 ? (
                      <ButtonNext className={classes.buttons}>
                        <ArrowForwardIos fontSize="large" />
                      </ButtonNext>
                    ) : null}
                  </CarouselProvider>
                ) : (
                  <Box display="flex" justifyContent="center">
                    <img
                      className={classes.image}
                      src={detail.image[0].full}
                      alt=""
                    />
                  </Box>
                )}
              </Box>
              <Line />
              <Box padding={'30px 0px'}>{detail.tags ? TagContent : null}</Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Fragment>
  )
}

export default observer(NewDetail)
