import { FC } from 'react'
import { useCaruseleStyle } from './Home.styles'
import { observer } from 'mobx-react-lite'
import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import SanctionHome from './Sanction'
import ArticlesCarusel from './ArticlesCarusel'

const HomeCarousel: FC = () => {
  const classes = useCaruseleStyle()
  const theme = useTheme()
  const maxWidth = useMediaQuery(theme.breakpoints.down(768))
  const minWidth = useMediaQuery(theme.breakpoints.up(720))
  const isTablet = maxWidth && minWidth
  return (
    <Box
      alignContent={'center'}
      padding={'30px 0px'}
      margin={isTablet ? '0px auto' : '0'}
    >
      <Box className={classes.newsCarusel}>
        <SanctionHome />
      </Box>
      <Box className={classes.newsCarusel}>
        <ArticlesCarusel />
      </Box>
    </Box>
  )
}

export default observer(HomeCarousel)
