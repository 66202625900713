import { makeStyles, Theme } from '@material-ui/core'

export const useLoginStyles = makeStyles<Theme>((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '55rem'
  },
  sectionHead: {
    width: '100%',
    height: 90,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleHead: {
    fontSize: 20,
    color: theme.palette.secondary.main
  },
  forgotPass: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(400)]: {
      flexDirection: 'column'
    }
  },
  forgotText: {
    fontSize: 20,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    [theme.breakpoints.down(550)]: {
      fontSize: 14
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 18,
      marginTop: 10
    }
  },
  alert: {
    color: 'red'
  },
  form: {
    border: `1px solid ${theme.palette.secondary.main}`,
    width: '100%',
    maxWidth: 800,
    borderRadius: `20px 20px 5px 5px `,
    padding: '20px',
    position: 'relative',
    maxHeight: 680,
    height: '100%'
  },

  formik: {
    height: '85%'
  },
  feedback: {
    margin: '15px 0'
  },
  container: {
    // padding: "25px",
    // height: "100%",
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: 20,
    marginBottom: 15
  },
  field: {
    width: '100%',
    height: 70,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& input': {
      width: '100%',
      border: 'none',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      fontSize: 20
    },
    '& .MuiInputBase-root': {
      '& fieldset': {
        border: 'none'
      }
    }
  },
  messageField: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 150,

    '& input': {
      width: '100%',
      height: 150,
      borderRadius: 5,
      border: `1px solid ${theme.palette.grey[300]}`,
      fontSize: 20
    }
  },
  signUpField: {
    width: '100%',
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& input': {
      width: '100%',
      height: 50,
      borderRadius: 5,
      border: `1px solid ${theme.palette.grey[300]}`
    }
  },
  registerTitle: {
    width: '100%',
    textAlign: 'center',
    margin: '10px 0',
    fontSize: 32,
    color: '#000'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    background: theme.palette.primary.main,
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 70
  },
  button: {
    color: theme.palette.secondary.main,
    fontSize: 32,
    fontWeight: 600,
    [theme.breakpoints.down(400)]: {
      fontSize: 24
    }
  }
}))
