import { makeStyles, Theme } from "@material-ui/core";

export const useAutocompleteFilterStyles = makeStyles((theme: Theme) => ({
  popperContent: {
    "& .MuiAutocomplete-groupLabel": {
      backgroundColor: theme.palette.grey[100],
      lineHeight: "38px",
      fontSize: "1rem",
      fontWeight: "normal",
    },
    "& .MuiAutocomplete-option": {
      padding: "0 5px",
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiAutocomplete-option:hover": {
      padding: "0 5px",
      backgroundColor: theme.palette.grey[50],
    },
    '& .MuiAutocomplete-option[data-option-index="0"]': {
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.grey[50],
      },
    },
    "& .MuiAutocomplete-groupUl ": {
      backgroundColor: theme.palette.common.white,
    },
  },
  textFiled: {
    "& .MuiInputBase-root ": {
      background: "none",
      border: "none",
      "& input": {
        bacground: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
      {
        paddingLeft: 12,
      },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      backgroundColor: "none",
      minWidth: 0,
    },
  },
  closeFilter: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,

    "& .Mui-disabled": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  icon: {
    color: theme.palette.success.main,
  },
}));
