import { FC } from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core'
import { navigation, footers } from 'enums/navigation'
import { FooterLink } from '../Link'
import { Call, Email, LocationOn } from '@material-ui/icons'
import { useFooterStyles } from './Footer.styles'
import { useTranslation } from 'react-i18next'
import { FooterProps } from './Footer.types'
import { allRights, phoneNumber } from './Footer.util'

const Mobile: FC<FooterProps> = () => {
  const classes = useFooterStyles()
  const { t } = useTranslation()
  const mobNav = [...navigation, ...footers]
  return (
    <footer className={classes.footer}>
      <List className={classes.list}>
        <ListItem>
          <ListItemIcon className={classes.iconContainer}>
            <LocationOn className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={t('location')} />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.iconContainer}>
            <Email className={classes.icon} />
          </ListItemIcon>
          <Box>
            <ListItemText primary={t('manager')} />
            <ListItemText primary={t('manager-information')} />
          </Box>
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.iconContainer}>
            <Call className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={phoneNumber} />
        </ListItem>
      </List>
      <List className={classes.list}>
        {mobNav.map(({ link, title }) => (
          <FooterLink key={link} link={link} title={title} />
        ))}
      </List>
      <Box className={classes.rightsMobile}>
        <Typography>{allRights}</Typography>
      </Box>
    </footer>
  )
}

export default Mobile
