import { Typography } from '@material-ui/core'
import AnalyticsTab from 'components/Search/AnalyticsTab'
import AuthorsTab from 'components/Search/AuthorsTab'
import EventsTab from 'components/Search/EventsTab'
import LegalBaseTab from 'components/Search/LegalBaseTab'
import NewsTab from 'components/Search/NewsTab'
import { UrlBreadcrumd } from 'models'
import { FilterOptions } from 'models/filters'
import { ReactElement } from 'react'

export const getUrlAdress = (addres: string): UrlBreadcrumd[] => {
  const url = addres.split('/').filter((item) => item.length)
  const urlString: UrlBreadcrumd[] = []
  url.forEach((item, index) => {
    urlString.push({
      link: index > 0 ? `${urlString[index - 1].link}/${item}` : `/${item}`,
      name: item
    })
  })
  return urlString
}

export const getParameterFromUrl = (param: string): string | null => {
  const url_string = window.location.href
  const url = new URL(url_string)
  const vars = url.searchParams.get(param)
  return vars
}

export const showTitle = (
  title: string,
  classes: string,
  isUpperCase = true
): typeof showTitle => {
  const itemsEl: ReactElement[] = []
  const spilttedTitle = isUpperCase
    ? title.toLocaleUpperCase().split('<BR/>')
    : title.split('<br/>')
  spilttedTitle.forEach((title) => {
    itemsEl.push(<Typography className={classes}>{title}</Typography>)
  })
  return itemsEl
}

export const processingUrlYoutube = (url: string): string => {
  if (url.includes('watch?v=')) {
    const youtubeUrl = new URL(url)
    const video = `https://www.youtube.com/embed/${youtubeUrl.searchParams.get(
      'v'
    )}`
    return video
  }
  if (url.includes('https://youtu.be')) {
    const video = url.replace(
      'https://youtu.be',
      'https://www.youtube.com/embed'
    )
    return video
  }
  return url
}

export const isVideo = (url: string): boolean => {
  if (url.includes('watch?v=')) {
    return true
  }
  if (url.includes('https://youtu.be')) {
    return true
  }
  return false
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const scrollTopPage = () => window.scrollTo(0, 0)

export const checkedFilter = (filterOptions: FilterOptions): number => {
  let checkedFilters = 0
  for (const key in filterOptions) {
    if (typeof filterOptions[key] !== undefined) {
      if (key !== 'period') {
        if (filterOptions[key]?.length) checkedFilters++
        if (typeof filterOptions[key] === 'boolean' && filterOptions[key])
          checkedFilters++
      }
    }
  }
  return checkedFilters
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const returnIds = (array: any[]) => {
  let ids = ''
  array.forEach(
    ({ id }, index) =>
      (ids = index === array.length ? ids + `${id}` : ids + `${id} `)
  )
  return ids
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const returnPanel = (dataName: string) => {
  switch (dataName) {
    case 'news':
      return <NewsTab />
    case 'researches':
      return <AnalyticsTab />
    case 'events':
      return <EventsTab />
    case 'documents':
      return <LegalBaseTab />
    case 'authors':
      return <AuthorsTab />
    default:
      return <div />
  }
}
