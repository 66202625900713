import { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { FilterCombinedProps, FilterOptions } from 'models/filters'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { useFiltersStyle } from './Filters.styles'
const RadioFilter: FC<FilterCombinedProps<FilterOptions>> = ({
  onChange,
  checkedValues
}) => {
  const [Ec_value, setEc_Value] = useState('')
  const classes = useFiltersStyle()
  const handleChange = (event) => {
    setEc_Value(event.target.value)
    onChange({ ...checkedValues, is_economic: event.target.value })
  }

  return (
    <FormControl fullWidth component="fieldset">
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={Ec_value}
        onChange={handleChange}
        row
      >
        <FormControlLabel
          value="1"
          control={<Radio />}
          label="Экономический"
          className={classes.radio}
        />
        <FormControlLabel
          value="0"
          control={<Radio />}
          label="Неэкономический"
          className={classes.radio}
        />
      </RadioGroup>
    </FormControl>
  )
}

export default observer(RadioFilter)
