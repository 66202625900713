import { FC } from 'react'
import {
  Box,
  Typography,
  // CardMedia,
  Card
  // Typography
  // CardContent,
  // useMediaQuery,
} from '@material-ui/core'
import { useArticlesChapterStyles } from './Articles.styles'
import { NewsChapterCardProps } from '../Cards.types'
// import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
// // import { format } from "date-fns";
// import { ArrowForwardIos } from "@material-ui/icons";
// import { scrollTopPage } from "utils";
// import { routers } from "routers";
import HtmlElement from 'components/common/HtmlElement'
import DoubleArrowTwoToneIcon from '@material-ui/icons/DoubleArrowTwoTone'
import { Link } from 'react-router-dom'
import { routers } from 'routers'
const ArticlesChapterCard: FC<NewsChapterCardProps> = ({
  isSmall = true,
  news
}) => {
  const classes = useArticlesChapterStyles(isSmall)
  // const { t } = useTranslation();

  return (
    <Link to={`${routers.news}/${news.id}`}>
      <Card className={classes.root}>
        <Box className={classes.backBox} />
        <Box className={classes.titleBox}>
          <Typography>{news.title}</Typography>
        </Box>
        <Box className={classes.dateBox}>
          <Typography className={classes.date}>{news.date}</Typography>
        </Box>
        <Box className={classes.textBox}>
          <HtmlElement
            component="div"
            classes={classes.desText}
            html={news?.summary ?? ''}
          />
        </Box>
        <Box className={classes.actionBox}>
          <Link to={`${routers.news}/${news.id}`}>
            <DoubleArrowTwoToneIcon className={classes.icon} />
          </Link>
        </Box>
      </Card>
    </Link>
  )
}

export default ArticlesChapterCard
