import { FC, Fragment } from "react";
import { useTermsMaterialsStyles } from "./TermsMaterials.styles";
import Breadcrumb from "components/common/Breadcrumb";
import SectionTitle from "components/common/SectionTitle";
import TermsMaterialsText from "./TermsMaterialsText";
import { Box } from "@material-ui/core";

const TermsMaterialsPage: FC = () => {
  const classes = useTermsMaterialsStyles();

  return (
    <Fragment>
      <SectionTitle background="water" />
      <Breadcrumb />
      <Box className={classes.bgWhite}>
        <TermsMaterialsText />
      </Box>
    </Fragment>
  );
};

export default TermsMaterialsPage;
