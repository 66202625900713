import { FC, Fragment, useEffect } from "react";
import SectionTitle from "components/common/SectionTitle";
import Breadcrumb from "components/common/Breadcrumb";
import { useDetailLegalBaseStyles } from "./LegalBase.style";
import Line from "components/common/Line";
import { Box, Grid } from "@material-ui/core";
import { getUrlAdress } from "utils";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useLanguage } from "context/Translation";
import { legalBaseService } from "services/legalBase/legalBase.service";
import { observer } from "mobx-react-lite";
import { DateRange, Description, Language } from "@material-ui/icons";
import HtmlElement from "components/common/HtmlElement";
import Tag from "components/common/Tag";
import LinkedDocumentsCard from "components/common/Cards/LinkedDocuments";
import Spiner from "components/common/Spiner";

const DetailPage: FC = () => {
  const classes = useDetailLegalBaseStyles();
  const url = getUrlAdress(window.location.pathname);
  const id = url[url.length - 1].name;
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { details, loading, lang } = legalBaseService;
  const {
    title,
    date,
    type,
    region,
    block,
    text,
    source,
    tags,
    linked_documents,
  } = details;

  useEffect(() => {
    if (id === null) return;
    if (details.id !== Number(id)) {
      legalBaseService.fetchDocumentDetail(language, Number(id));
      return;
    }
    if (lang !== language) {
      legalBaseService.fetchDocumentDetail(language, Number(id));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, language, id]);

  const Block = block ? (
    <Grid item className={classes.headerItem} alignItems="center">
      <img width={30} src={block?.img} alt="" />
      <div className={classes.headerTitle}>{block?.name}</div>
    </Grid>
  ) : null;

  const Region = region ? (
    <Grid item className={classes.headerItem} alignItems="center">
      <Language className={classes.Green} />
      <div className={classes.headerTitle}>{region.name}</div>
    </Grid>
  ) : null;

  const Type = type ? (
    <Grid item className={classes.headerItem} alignItems="center">
      <Description className={classes.Blue} />
      <div className={classes.headerTitle}>{type.name}</div>
    </Grid>
  ) : null;

  const Source = source ? (
    <Fragment>
      <Box paddingTop={"25px"}>
        {`${t("source")}: `}
        <a
          target="_blank"
          href={source.url}
          className={classes.Blue}
          rel="noreferrer"
        >
          {source.text}
        </a>
      </Box>
    </Fragment>
  ) : null;

  const TagContent = (
    <Grid container spacing={2}>
      {tags.map((item) => (
        <Grid item key={item.id} className={classes.tagItem}>
          <Tag id={item.id} title={item.name} />
        </Grid>
      ))}
    </Grid>
  );

  if (loading) {
    return (
      <Fragment>
        <SectionTitle isEnd background="book" />
        <Box className={classes.container}>
          <Spiner />
        </Box>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <SectionTitle isEnd background="book" />
      <Breadcrumb isEnd title={title} />
      <Box className={classes.container}>
        <Box className={classes.detailContainer}>
          <Grid
            container
            className={classes.header}
            justifyContent="space-between"
          >
            {Block}
            {Region}
            {Type}
            <Grid item className={classes.headerItem} alignItems="center">
              <DateRange className={classes.Red} />
              <div className={classes.headerTitle}>
                {format(new Date(date ? date : 0), "dd.MM.yyyy")}
              </div>
            </Grid>
          </Grid>
          <Box padding={"10px 17px 30px"}>
            <div className={classes.title}>{title}</div>
            <HtmlElement
              component="div"
              classes={classes.text}
              html={text ?? ""}
            />
            {Source}
            {tags && tags.length > 0 ? (
              <Grid item xs style={{marginTop: 30}}>
                  <Line />
                  <Box padding={"30px 0px 0"}>
                      { TagContent }
                  </Box>
              </Grid>
            ) : null}
          </Box>
        </Box>
        {linked_documents && linked_documents?.data.length ? (
          <Box marginTop={"40px"}>
            <Box className={classes.linkedTitle}>НОВОЕ ПО ТЕМЕ</Box>
            <Box marginTop={"40px"}>
              <Grid container spacing={2}>
                {linked_documents?.data.map((item) => (
                  <Grid key={item.id} item>
                    <LinkedDocumentsCard document={item} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Fragment>
  );
};

export default observer(DetailPage);
