import { FC } from "react";
import { Box, Grid } from "@material-ui/core";
import { ResearchCard } from "components/common/Cards/Research";
import { observer } from "mobx-react-lite";
import { tagsService } from "services/tags/tags.service";
import { scrollTopPage } from "utils";
import Paging from "components/common/Paging";
import Spiner from "components/common/Spiner";
import EmptyFilterResult from "components/common/StubsAndBugs/EmptyFilterResult";

const AnalyticsTab: FC = () => {
  const { analyticList, paging, idTag, lang, loading } = tagsService;

  const handlePageChange = () => {
    tagsService.fetchTagInfo(lang, idTag, "");
    scrollTopPage();
  };

  if (loading) {
    return <Spiner />;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {analyticList.length ? (
          analyticList.map((item) => (
            <Grid item xs={12} key={item.id}>
              <ResearchCard data={item} />
            </Grid>
          ))
        ) : (
          <EmptyFilterResult />
        )}
      </Grid>
      <Paging paging={paging} onChange={handlePageChange} />
    </Box>
  );
};

export default observer(AnalyticsTab);
