import { FC, Fragment } from 'react'
import { useFAQStyles } from './FAQ.styles'
import Breadcrumb from 'components/common/Breadcrumb'
import SectionTitle from 'components/common/SectionTitle'
import { Box, Grid } from '@material-ui/core'
import { faqService } from 'services/faq/faq.service'
import SingleAQ from './SingleAQ'
import { observer } from 'mobx-react-lite'
import Spiner from 'components/common/Spiner'

const FAQPage: FC = () => {
  const classes = useFAQStyles()
  const { faqList, loading } = faqService

  if (loading) {
    return (
      <Fragment>
        <SectionTitle background="water" />
        <Breadcrumb />
        <Box className={classes.bgWhite}>
          <Spiner />
        </Box>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <SectionTitle isShown={false} background="water" />
      <Breadcrumb />
      <Box className={classes.bgWhite}>
        <Grid container spacing={2} className={classes.root}>
          {faqList.map(({ id, name, desc }) => (
            <SingleAQ
              key={id}
              ariaControls="panel1a-content"
              id="panel1a-header"
              question={name}
              answer={desc}
            />
          ))}
        </Grid>
      </Box>
    </Fragment>
  )
}

export default observer(FAQPage)
