import { FC } from 'react'
import { Box, Grid } from '@material-ui/core'
import { AuthorsColumnProp } from '../Authors.types'
import { useColumnStyles } from './Column.style'
import AutorItemCard from 'components/common/Cards/People/AutorItemCard'
import EmptyFilterResult from 'components/common/StubsAndBugs/EmptyFilterResult'

export const AuthorsColumn: FC<AuthorsColumnProp> = ({ authors, title }) => {
  const style = useColumnStyles()

  return (
    <Box className={style.column}>
      <Box className={style.columnTitle}>{title}</Box>
      <Grid container>
        {authors.length ? (
          authors.map((item, index) => (
            <Grid item xs={12} key={item.name} className={style.columnCell}>
              <AutorItemCard
                author={item}
                isLine={index !== authors.length - 1}
              />
            </Grid>
          ))
        ) : (
          <EmptyFilterResult />
        )}
      </Grid>
    </Box>
  )
}
