import { Box, Link } from '@material-ui/core'
import { useMembersCardStyles } from './Members.styles'
// import { format } from 'date-fns'
import HtmlElement from 'components/common/HtmlElement'
import { scrollTopPage } from 'utils'
// import { routers } from 'routers'

const MembersCard = ({ members }) => {
  const { name, desc, links, photo, role } = members
  const classes = useMembersCardStyles()

  const desktopCard = (
    <Box className={classes.container}>
      <Box className={classes.imgContainer}>
        <img src={photo[0]} alt="Live from space album cover" />
      </Box>
      <Box className={classes.bodyContainer}>
        <Box className={classes.infoContainer}>
          <Box>
            <Box className={classes.titleContainer}>
              <Box className={classes.title}>{name}</Box>
            </Box>
            <HtmlElement
              component="div"
              classes={classes.cropHeight}
              html={`<p>${role}</p>`}
            />
            <HtmlElement
              component="div"
              classes={classes.cropHeight}
              html={`<p>${desc}</p>`}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )

  return (
    <Link
      href={links[0].url}
      className={classes.link}
      target="_blank"
      onClick={scrollTopPage}
    >
      {desktopCard}
    </Link>
  )
}

export default MembersCard
