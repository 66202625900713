import { Box, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useAnalyticsCardStyles } from './Analytics.styles'
import HtmlElement from 'components/common/HtmlElement'
import { scrollTopPage } from 'utils'
import Tag from 'components/common/Tag'
import { format } from 'date-fns'
import { routers } from 'routers'

const AnalyticsCard = ({ analytics }) => {
  const { title, summary, date, image, tags, id } = analytics

  const classes = useAnalyticsCardStyles()

  const TagsContent = tags ? (
    <Grid item container spacing={2} xs={12} style={{ width: '100%' }}>
      {tags.slice(0, 2).map((tag) => (
        <Grid item key={tag.id}>
          <Tag key={tag.id} id={tag.id} title={tag.name} type="analytics" />
        </Grid>
      ))}
    </Grid>
  ) : null

  const desktopCard = (
    <Box className={classes.container}>
      <Box className={classes.imgContainer}>
        <img src={image[0].full} alt="Live from space album cover" />
      </Box>
      <Box className={classes.bodyContainer}>
        <Box className={classes.infoContainer}>
          <Box>
            <Box className={classes.titleContainer}>
              <Box className={classes.title}>{title}</Box>
            </Box>
            <p className={classes.date}>
              {' '}
              {format(new Date(date), 'dd.MM.yyyy')}
            </p>

            <HtmlElement
              component="div"
              classes={classes.cropHeight}
              html={`<p>${summary}</p>`}
            />
          </Box>
        </Box>
      </Box>
      {TagsContent}
    </Box>
  )

  return (
    <Link to={`${routers.analytics}/${id}`} onClick={scrollTopPage}>
      {desktopCard}
    </Link>
  )
}

export default AnalyticsCard
