import { FC } from 'react'
import { Box, Grid, useMediaQuery } from '@material-ui/core'
import { NewsCardProps } from '../Cards.types'
import { Link } from 'react-router-dom'
import { useMobileNewsCardStyles, useNewsCardStyles } from './News.styles'
import Tag from 'components/common/Tag'
import { format } from 'date-fns'
import HtmlElement from 'components/common/HtmlElement'
import { scrollTopPage } from 'utils'
import { routers } from 'routers'
import { useTheme } from '@material-ui/core/styles'

const NewsCard: FC<NewsCardProps> = ({ news }) => {
  const { id, image, tags, date, summary, title } = news
  const classes = useNewsCardStyles()
  const mobileClasses = useMobileNewsCardStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(600))
  const isDesktop = useMediaQuery(theme.breakpoints.up(1024))
  const isTablet = useMediaQuery(theme.breakpoints.up(786))
  const numbTags = isMobile ? 2 : isDesktop ? 5 : isTablet ? 4 : 3

  const TagsContent = tags ? (
    <Grid
      item
      container
      spacing={2}
      xs={12}
      style={{ paddingTop: 20, width: '100%' }}
    >
      {tags.map((tag) => (
        <Grid item key={tag.id}>
          <Tag key={tag.id} id={tag.id} title={tag.name} type="analytics" />
        </Grid>
      ))}
    </Grid>
  ) : null

  const DesktopTags = tags ? (
    <Grid container spacing={1}>
      {tags.map((tag, index) => {
        if (index < numbTags) {
          return (
            <Grid item key={tag.id}>
              <Tag key={tag.id} id={tag.id} title={tag.name} type="analytics" />
            </Grid>
          )
        }

        return null
      })}
    </Grid>
  ) : null

  const mobileCard = (
    <Grid container item className={mobileClasses.container}>
      <Grid item className={mobileClasses.imgContainer}>
        {image.length && (
          <img src={image[0].full} alt="Live from space album cover" />
        )}
        <Box className={mobileClasses.dateOnImage}>
          {format(new Date(date), 'dd.MM.yyyy')}
        </Box>
      </Grid>
      <Grid container item className={mobileClasses.bodyContainer}>
        <Grid item className={mobileClasses.titleDesContainer}>
          <Box className={mobileClasses.titleContainer}>
            <Box className={mobileClasses.title}>{title}</Box>
          </Box>
          <HtmlElement
            component="div"
            classes={`crop-height ${mobileClasses.cropHeight}`}
            html={`<p>${summary
              .replaceAll('<p>', '')
              .replaceAll('</p>', '')
              .slice(0, 500)}</p>`}
          />
        </Grid>
        <Grid item className={mobileClasses.about}>
          <Grid className={mobileClasses.date}>
            {format(new Date(date), 'dd.MM.yyyy')}
          </Grid>
        </Grid>
        {TagsContent}
      </Grid>
    </Grid>
  )

  const desktopCard = (
    <Box className={classes.container}>
      <Box className={classes.imgContainer}>
        {image.length && (
          <img src={image[0].full} alt="Live from space album cover" />
        )}
        <Box className={classes.dateOnImage}>
          {format(new Date(date), 'dd.MM.yyyy')}
        </Box>
      </Box>
      <Box className={classes.bodyContainer}>
        <Box className={classes.infoContainer}>
          <Box>
            <Box className={classes.titleContainer}>
              <Box className={classes.title}>{title}</Box>
            </Box>
            <HtmlElement
              component="div"
              classes={`crop-height ${classes.cropHeight} ${
                tags && !tags.length ? classes.longDescription : ''
              }`}
              html={`<p>${summary}</p>`}
            />
          </Box>

          <Box className={classes.about}>
            <Box className={classes.date}>
              {format(new Date(date), 'dd.MM.yyyy')}
            </Box>
          </Box>
        </Box>
        {DesktopTags}
      </Box>
    </Box>
  )

  return (
    <Link to={`${routers.news}/${id}`} onClick={scrollTopPage}>
      {mobileCard}
      {desktopCard}
    </Link>
  )
}

export default NewsCard
