import { makeStyles, Theme } from "@material-ui/core";
import { defaultContainer } from "styles/defaultStyle";

export const usePhotoStyle = makeStyles((theme: Theme) => ({
  container: defaultContainer(theme),
  cardItem: {
    maxWidth: "385px",
    maxHeight: "464px",
    [theme.breakpoints.down(768)]: {
      maxWidth: "initial",
    },
  },
  cardsContainer: {
    "& .MuiPaper-root": {
      borderRadius: "5px 5px 0px 0px",
      border: `1px solid ${theme.palette.grey[100]}`,
      boxShadow: "none",
    },
  },
  photoCard: {
    flexShrink: 1,
    [theme.breakpoints.down(768)]: { flexBasis: "100%" },
    [theme.breakpoints.between(769, 1279)]: { flexBasis: "50%" },
    [theme.breakpoints.up(1280)]: { flexBasis: "33.333333%" },
    [theme.breakpoints.up(2000)]: { flexBasis: "25%" },
  },
  dateOnImage: {
    borderRadius: " 4px 4px 0px 0px",
    margin: 0,
    padding: "6px 16px",
    fontSize: 18,
    color: theme.palette.secondary.main,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  media: {
    display: "flex",
    justifyContent: "start",
    height: 360,
  },
  title: {
    textAlign: "center",
    padding: "20px 10px",
    fontSize: "large",
    margin: 0,
    fontWeight: 700,
    color: theme.palette.text.primary,
    textTransform: "uppercase",
  },
  cardContent: {
    padding: 0,
  },
}));

export const usePhotoDetailStyle = makeStyles((theme: Theme) => ({
  container: defaultContainer(theme),
  root: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "10px",
    justifyItems: "center",
    [theme.breakpoints.up("mobileVertical")]: {
      gridTemplateColumns: "1fr",
      gridGap: "10px",
    },
    [theme.breakpoints.up(630)]: {
      gridTemplateColumns: "1fr 1fr",
      gridGap: "10px",
    },
    [theme.breakpoints.up("tabletVertical")]: {
      gridTemplateColumns: "1fr 1fr",
      gridGap: "25px",
    },
    [theme.breakpoints.up("tabletHorisontal")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: "25px",
    },
    [theme.breakpoints.up(1440)]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gridGap: "55px",
    },
    [theme.breakpoints.up(1900)]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
      gridGap: "55px",
    },
    [theme.breakpoints.up(2300)]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
      gridGap: "55px",
    },
  },
  imageContainer: {
    width: "100%",
    maxWidth: 277,
    height: 200,
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    "& .MuiImageListItem-item": {
      borderRadius: 5,
    },
    "& img": {
      width: "auto",
      height: 200,
      objectFit: "cover",
    },
  },
  date: {
    fontSize: 20,
    lineHeight: "27px",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
  },
  media: {
    display: "flex",
    justifyContent: "start",
    height: 360,
  },
  title: {
    fontSize: 20,
    lineHeight: "24px",
    textTransform: "uppercase",
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
}));
