import { makeStyles, Theme } from "@material-ui/core";

export const useTagStyles = makeStyles((theme: Theme) => ({
  tag: {
    padding: "8px 15px",
    borderRadius: 10,
    backgroundColor: theme.palette.grey[50],
    fontSize: 12,
    color: theme.palette.text.primary,
    width: "fit-content",
    [theme.breakpoints.up(380)]: {
      maxWidth: "100%",
    },
  },
  tagText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
    whiteSpace: "nowrap",
  },
  margin: {
    marginRight: 10,
  },
}));
