import { makeStyles, Theme } from '@material-ui/core'

export const useAboutChapterStyle = makeStyles((theme: Theme) => ({
  textContainer: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '26px',
    textAlign: 'justify'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  taskText: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25
  },
  taskTextContain: {
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'justify',
    paddingLeft: 10
  },
  tasksBlock: {
    background: theme.palette.grey[50],
    borderRadius: 5,
    padding: '11px 14px 20px 14px',
    height: 240,
    textAlign: 'center'
  },
  tasksBlockTitle: {
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'center',
    color: theme.palette.secondary.main,
    paddingBottom: 20
  },
  tasksBlockItems: {
    fontSize: 14,
    lineHeight: '26px'
  },
  specialPadding: {
    padding: '11px 40px 20px 40px'
  },
  img: {
    borderRadius: 5,
    maxHeight: 300,
    objectFit: 'contain'
  }
}))

export const useFeedbackChapterStyle = makeStyles((theme: Theme) => ({
  textField: {
    width: '100%'
  },
  sendButton: {
    borderRadius: 12,
    color: theme.palette.common.white
  },
  feedbackAndMap: {
    [theme.breakpoints.up(1315)]: {
      flexBasis: '50%'
    },
    [theme.breakpoints.down(1315)]: {
      flexBasis: '100%'
    }
  }
}))

export const useTaskChapterStyle = makeStyles((theme: Theme) => ({
  img: {
    borderRadius: 5
  },
  taskItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25
  },
  taskItem: {
    fontSize: 14,
    lineHeight: '26px',
    textAlign: 'justify',
    paddingLeft: 10
  },
  cardContainer: {
    background: theme.palette.grey[50],
    borderRadius: 5,
    padding: '11px 14px 20px 14px',
    height: '100%',
    minHeight: 240
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: 20,
    textAlign: 'center',
    color: theme.palette.secondary.main,
    paddingBottom: 20,
    textTransform: 'uppercase'
  },
  cardText: {
    fontSize: 14,
    lineHeight: '36px',
    alignItems: 'center',
    textTransform: 'uppercase'
  },
  containerItem: {
    flexShrink: 1,
    flexGrow: 1,
    [theme.breakpoints.down(760)]: {
      flexBasis: '100%'
    },
    [theme.breakpoints.up(760)]: {
      flexBasis: '50%'
    }
  }
}))
