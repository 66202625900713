import { FC, Fragment } from 'react'
import { Grid } from '@material-ui/core'
import { mediacenterService } from 'services/mediacenter/mediacenter.service'
import { observer } from 'mobx-react-lite'
import NewsCard from 'components/common/Cards/News/NewsCard'
import Paging from 'components/common/Paging'
import { scrollTopPage } from 'utils'
import Spiner from 'components/common/Spiner'
import EmptyFilterResult from 'components/common/StubsAndBugs/EmptyFilterResult'

const NewsPage: FC = () => {
  const { loading, news, pagingNews } = mediacenterService
  const handlePageChange = (page: number) => {
    mediacenterService.fetchPagingNews(page)
    scrollTopPage()
  }

  if (loading) {
    return <Spiner />
  }

  return (
    <Fragment>
      <Grid container direction="column" spacing={2}>
        {news.length ? (
          news.map((item) => (
            <Grid item key={item.id} xs>
              <NewsCard news={item} />
            </Grid>
          ))
        ) : (
          <EmptyFilterResult />
        )}
      </Grid>
      <Paging paging={pagingNews} onChange={handlePageChange} />
    </Fragment>
  )
}

export default observer(NewsPage)
