import { FC, Fragment } from "react";
import Footer from "components/common/Footer";
import OpenDataPage from "components/OpenData";
import Detail from "components/OpenData/Detail";
import { Route, Switch } from "react-router-dom";
import { routers } from "routers";

const OpenDataView: FC = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path={routers.openData}>
          <OpenDataPage />
        </Route>
        <Route exact path={`${routers.openData}/:id`} component={Detail} />
      </Switch>
      <Footer />
    </Fragment>
  );
};

export default OpenDataView;
