import { FC, Fragment } from "react";
import SectionTitle from "components/common/SectionTitle";
import Breadcrumb from "components/common/Breadcrumb";
import { Box, Grid } from "@material-ui/core";
import { useLegalBaseStyles } from "./LegalBase.style";
import LegalBaseCard from "components/common/Cards/LegalBaseCard";
import { legalBaseService } from "services/legalBase/legalBase.service";
import { observer } from "mobx-react-lite";
import Paging from "components/common/Paging";
import { FilterCombinedProps, FilterOptions } from "models/filters";
import { LegalBaseFilters } from "components/common/Filters";
import { scrollTopPage } from "utils";
import EmptyFilterResult from "components/common/StubsAndBugs/EmptyFilterResult";
import Spiner from "components/common/Spiner";

const LegalBasePage: FC<FilterCombinedProps<FilterOptions>> = ({
  checkedValues,
  onChange,
}) => {
  const classes = useLegalBaseStyles();

  const { loading, documents, paging } = legalBaseService;

  const handlePageChange = (page: number) => {
    legalBaseService.fetchPagingDocuments(page);
    scrollTopPage();
  };

  const handleFind = () => {
    legalBaseService.fetchDocumentsFilter(checkedValues);
  };

  return (
    <Fragment>
      <SectionTitle background="book" />
      <Breadcrumb />
      <LegalBaseFilters
        checkedValues={checkedValues}
        onChange={onChange}
        onFind={handleFind}
      />
      <Box className={classes.container}>
        <Grid container spacing={2} direction="column">
          {loading ? (
            <Spiner />
          ) : documents && documents.length ? (
            documents.map((item) => (
              <Grid item xs key={item.id}>
                <LegalBaseCard document={item} />
              </Grid>
            ))
          ) : (
            <EmptyFilterResult />
          )}
        </Grid>
        <Paging paging={paging} onChange={handlePageChange} />
      </Box>
    </Fragment>
  );
};

export default observer(LegalBasePage);
