import { FC } from "react";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { searchService } from "services/search/search.service";
import Spiner from "components/common/Spiner";
import Paging from "components/common/Paging";
import { scrollTopPage } from "utils";
import { useLanguage } from "context/Translation";
import { AuthorsColumn } from "components/AboutProject/Autors/Column";
import { useTranslation } from "react-i18next";

const AuthorsTab: FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { rusAuthors, engAuthors, loading, paging } = searchService;
  const { language } = useLanguage();
  const xsGrid = isMobile ? 12 : 6;
  const handlePageChange = (newPage: number) => {
    searchService.fetchAuthorsPaging(language, newPage);
    scrollTopPage();
  };

  if (loading) {
    return <Spiner />;
  }

  return (
    <Box>
      <Grid container spacing={5}>
        {rusAuthors.length ? (
          <Grid item xs={xsGrid}>
            <AuthorsColumn title={t("russian-authors")} authors={rusAuthors} />
          </Grid>
        ) : null}
        {engAuthors.length ? (
          <Grid item xs={xsGrid}>
            <AuthorsColumn title={t("foreign-authors")} authors={engAuthors} />
          </Grid>
        ) : null}
      </Grid>
      <Paging paging={paging} onChange={handlePageChange} />
    </Box>
  );
};

export default observer(AuthorsTab);
