import { makeStyles, Theme } from "@material-ui/core";
import { defaultContainer } from "styles/defaultStyle";

export const useSearchPageStyles = makeStyles((theme: Theme) => ({
  container: defaultContainer(theme),
  title: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "25px",
    marginBottom: 30,
    color: theme.palette.text.primary,
  },
}));
