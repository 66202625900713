import { FC, Fragment, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import Footer from 'components/common/Footer'
import NewsDetails from 'components/News/Details'
import NewsPage from 'components/News/index'
import { newsService } from 'services/news/news.service'
import { routers } from 'routers'

const News: FC = () => {
  useEffect(() => {
    return function cleanupPage() {
      newsService.cleanPage()
    }
  }, [])
  useEffect(() => {
    return function cleanupPage() {
      newsService.cleanPage()
    }
  }, [])

  return (
    <Fragment>
      <Switch>
        <Route exact path={`${routers.news}`}>
          <NewsPage />
        </Route>
        <Route exact path={`${routers.news}/:id`} component={NewsDetails} />
      </Switch>
      <Footer />
    </Fragment>
  )
}

export default News
