import { FC, Fragment, useEffect } from "react";
import SectionTitle from "components/common/SectionTitle";
import Breadcrumb from "components/common/Breadcrumb";
import { useArticlesStyles } from "./Articles.styles";
import { Box, Grid } from "@material-ui/core";
import { useLanguage } from "context/Translation";
import { FilterCombinedProps, FilterOptions } from "models/filters";
import EventsCard from "components/common/Cards/Events/EventsCard";
import { eventsService } from "services/events/events.service";
import { observer } from "mobx-react-lite";
import Paging from "components/common/Paging";
import { EventFilters } from "components/common/Filters";
import { scrollTopPage } from "utils";
import EmptyFilterResult from "components/common/StubsAndBugs/EmptyFilterResult";
import Spiner from "components/common/Spiner";

const SanctionsPage: FC<FilterCombinedProps<FilterOptions>> = ({
  checkedValues,
  onChange,
}) => {
  const classes = useArticlesStyles();
  const { language } = useLanguage();
  const { events, paging, loading } = eventsService;
  useEffect(() => {
    eventsService.fetchEvents(language);
  }, [language]);

  const handlePageChange = (page: number) => {
    eventsService.fetchPagingEvents(page);
    scrollTopPage();
  };

  const handleFindClick = () => {
    eventsService.fetchEventsFilter(language, checkedValues);
  };

  return (
    <Fragment>
      <SectionTitle background="planet" />
      <Breadcrumb />
      <EventFilters
        checkedValues={checkedValues}
        onChange={onChange}
        onFind={handleFindClick}
      />
      <Box className={classes.container}>
        {loading ? (
          <Spiner />
        ) : (
          <Box>
            <Grid container spacing={2}>
              {events && events.length ? (
                events.map((item) => (
                  <Grid item xs={12} key={item.id}>
                    <EventsCard event={item} />
                  </Grid>
                ))
              ) : (
                <EmptyFilterResult />
              )}
            </Grid>
            <Paging paging={paging} onChange={handlePageChange} />
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default observer(SanctionsPage);
