import { FC, Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import Footer from 'components/common/Footer'
import AboutProjectPage from 'components/AboutProject/Home'
import { routers } from 'routers'

const AboutProject: FC = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path={routers.about.home} component={AboutProjectPage} />
      </Switch>
      <Footer />
    </Fragment>
  )
}

export default AboutProject
