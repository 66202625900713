import { makeStyles, Theme } from "@material-ui/core";

export const useFooterStyles = makeStyles((theme: Theme) => ({
  footer: {
    background: theme.palette.primary.main,
  },
  footerBackground: {
    background: theme.palette.secondary.main,
    paddingTop: 20,
  },
  root: {
    position: "relative",
    mixBlendMode: "normal",
    height: "280px",
    // padding:'0px 5%',

    "& div.footerDetails": {
      maxWidth: "100%",
      marginLeft: "24px",
      marginRight: "24px",
      justifyContent: "space-between",

      "& ul": {
        "& h1": {
          fontStyle: "normal",
          fontWeight: "bold",
          lineHeight: "25px",
          color: theme.palette.common.white,
          background: theme.palette.primary.main,
        },

        "& li": {
          paddingBottom: 0,

          "& div.MuiListItemText-root": {
            "& span": {
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "19px",
              letterSpacing: "-0.5px",
              color: theme.palette.common.white,
            },
          },

          "& div.MuiListItemIcon-root": {
            color: theme.palette.common.white,
          },
        },
      },

      "& div.social-rights": {
        display: "flex",

        "& div.socialLinks": {
          alignSelf: "center",

          "& button": {
            padding: "4px",
            color: theme.palette.common.white,
          },
        },

        "& span.rights": {
          alignSelf: "center",
          marginLeft: "50px",
          fontStyle: "normal",
          fontWeight: "normal",
          lineHeight: "19px",
          textAlign: "center",
          letterSpacing: "-0.5px",
          color: theme.palette.common.white,
        },
      },
    },
  },
  submitForm: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.up("tabletVertical")]: {
      justifyContent: "space-between",
    },
  },
  submitButton: {
    fontSize: 14,
    color: theme.palette.common.white,
    marginLeft: 10,
    width: 115,
  },
  list: {
    color: theme.palette.common.white,
  },
  icon: {
    color: theme.palette.common.white,
  },
  iconContainer: {
    minWidth: 0,
    marginRight: 15,
  },
  rights: {
    color: theme.palette.common.white,
    paddingTop: 10,
    borderTop: `1px solid ${theme.palette.common.white}`,
  },
  rightsMobile: {
    color: theme.palette.common.white,
    padding: "10px 0px",
    margin: "0px 16px",
    borderTop: `1px solid ${theme.palette.common.white}`,
  },
}));
