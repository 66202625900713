import { FC, Fragment, useEffect } from "react";
import { getUrlAdress } from "utils";
import SectionTitle from "components/common/SectionTitle";
import Breadcrumb from "components/common/Breadcrumb";
import { usePhotoDetailStyle } from "./Photos.styles";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";
import { ImageListItem, Box } from "@material-ui/core";
import { mediacenterService } from "services/mediacenter/mediacenter.service";
import { useLanguage } from "context/Translation";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import Spiner from "components/common/Spiner";

const Detail: FC = () => {
  const classes = usePhotoDetailStyle();
  const url = getUrlAdress(window.location.pathname);
  const id = parseInt(url[url.length - 1].name);
  const { lang, photoGallery, loading } = mediacenterService;
  const { language } = useLanguage();

  useEffect(() => {
    if (id === null) return;
    if (photoGallery.id !== Number(id)) {
      mediacenterService.fetchPhotosDetail(language, Number(id));
      return;
    }
    if (lang !== language) {
      mediacenterService.fetchPhotosDetail(language, Number(id));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoGallery, language, id]);

  useEffect(() => {
    return function cleanupPage() {
      mediacenterService.cleanDetail();
    };
  }, []);

  if (loading) {
    return (
      <Fragment>
        <SectionTitle isEnd title={photoGallery.title} background="earth" />
        <Breadcrumb title={photoGallery.title} />
        <Box className={classes.container}>
          <Spiner />
        </Box>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <SectionTitle isEnd title={photoGallery.title} background="earth" />
      <Breadcrumb title={photoGallery.title} />
      <Box className={classes.container}>
        <Box
          marginBottom={"50px"}
          display="flex"
          justifyContent="space-between"
        >
          <Box className={classes.title}>{photoGallery.title}</Box>
          {photoGallery.date ? (
            <Box className={classes.date}>
              {format(new Date(photoGallery.date), "dd.MM.yyyy")}
            </Box>
          ) : null}
        </Box>
        <SimpleReactLightbox>
          <SRLWrapper>
            <Box className={classes.root}>
              {photoGallery.image.map((item) => (
                <ImageListItem
                  key={item}
                  // These styles should not be taken out when refactoring code
                  classes={{ root: classes.imageContainer }}
                  style={{ padding: 0, width: "100%" }}
                >
                  <img
                    src={`${item}?fit=crop&auto=format`}
                    srcSet={`${item}`}
                    loading="lazy"
                    alt=""
                  />
                </ImageListItem>
              ))}
            </Box>
          </SRLWrapper>
        </SimpleReactLightbox>
      </Box>
    </Fragment>
  );
};

export default observer(Detail);
