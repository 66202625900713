import { makeStyles, Theme } from '@material-ui/core'

export const useButtonsStyles = makeStyles((theme: Theme) => ({
  language: (isDark) => ({
    color: isDark ? theme.palette.text.primary : theme.palette.common.white
  }),
  login: {
    textTransform: 'capitalize'
  }
}))
