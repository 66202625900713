import { FC, Fragment } from "react";
import Footer from "components/common/Footer";
import PrivacyPolicyPage from "components/PrivacyPolicy";

const PrivacyPolicy: FC = () => (
  <Fragment>
    <PrivacyPolicyPage />
    <Footer />
  </Fragment>
);

export default PrivacyPolicy;
