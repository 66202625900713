import { FC, Fragment } from "react";
import { observer } from "mobx-react-lite";
import { useVideosStyle } from "./Videos.styles";
import { Grid } from "@material-ui/core";
import { mediacenterService } from "services/mediacenter/mediacenter.service";
import Paging from "components/common/Paging";
import { scrollTopPage } from "utils";
import VideoCard from "./VideoCard";
import Spiner from "components/common/Spiner";
import EmptyFilterResult from "components/common/StubsAndBugs/EmptyFilterResult";

const VideosPage: FC = () => {
  const classes = useVideosStyle();
  const { loading, videoGallerys, pagingVideo } = mediacenterService;

  const handlePageChange = (page: number) => {
    mediacenterService.fetchVideoPaging(page);
    scrollTopPage();
  };

  if (loading) {
    return <Spiner />;
  }

  return (
    <Fragment>
      <Grid container spacing={4} className={classes.cardsContainer}>
        {videoGallerys.length ? (
          videoGallerys.map((item) => (
            <Grid key={item.id} item className={classes.videoCard}>
              <VideoCard
                id={item.id}
                title={item.title}
                date={item.date}
                urlVideo={item.html}
              />
            </Grid>
          ))
        ) : (
          <EmptyFilterResult />
        )}
      </Grid>
      <Paging paging={pagingVideo} onChange={handlePageChange} />
    </Fragment>
  );
};

export default observer(VideosPage);
