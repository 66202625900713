import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import AuthorFilter from "./components/AuthorFilter";
import DateFilter from "./components/DateFilter";
import { useFiltersStyle } from "./Filters.styles";
import { MediaFilterProps } from "./Filters.types";

const MediaFilters: FC<MediaFilterProps> = ({
  checkedValues,
  onChange,
  onFind,
  isVideo,
  isNews,
}) => {
  const classes = useFiltersStyle();
  const { t } = useTranslation();

  const handleTitleChange = ({ target: { value } }) =>
    onChange({ ...checkedValues, title: value });

  const handleKeysChange = ({ target: { value } }) =>
    onChange({ ...checkedValues, text: value });

  const handleFromDateChange = (date) =>
    onChange({
      ...checkedValues,
      period: { ...checkedValues.period, start: date },
    });

  const handleToDateChange = (date) =>
    onChange({
      ...checkedValues,
      period: { ...checkedValues.period, end: date },
    });

  const handleFindClick = () => {
    onFind();
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const MobileFilter = (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMore fontSize={"small"} htmlColor="#fff" />}
      >
        <Typography className={classes.header}>{t("filters")}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Box className={classes.filters}>
          <Grid container spacing={2} direction={"row"}>
            <Grid item xs={12}>
              <TextField
                size="small"
                variant="outlined"
                value={checkedValues.title}
                fullWidth
                placeholder={t("search-by-name")}
                onChange={handleTitleChange}
              />
            </Grid>
            {isVideo ? null : (
              <Grid item xs={12}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={checkedValues.text}
                  placeholder={t("keywords")}
                  onChange={handleKeysChange}
                />
              </Grid>
            )}
            <Grid
              className={classes.container}
              item
              container
              spacing={2}
              direction={"column"}
            >
              {isNews ? (
                <Grid item xs>
                  <AuthorFilter
                    checkedValues={checkedValues}
                    onChange={onChange}
                  />
                </Grid>
              ) : null}
              <Grid item xs>
                <DateFilter
                  value={checkedValues.period.start}
                  onChange={handleFromDateChange}
                  lable={"from"}
                />
              </Grid>
              <Grid item xs>
                <DateFilter
                  value={checkedValues.period.end}
                  onChange={handleToDateChange}
                  lable={"to"}
                />
              </Grid>
              <Grid item xs>
                <Button
                  className={classes.findBtn}
                  color="default"
                  variant="contained"
                  onClick={handleFindClick}
                >
                  {t("find")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  return isMobile ? (
    MobileFilter
  ) : (
    <Box className={classes.filters}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={isVideo ? 12 : 6}>
          <TextField
            size="small"
            variant="outlined"
            value={checkedValues.title}
            fullWidth
            placeholder={t("search-by-name")}
            onChange={handleTitleChange}
          />
        </Grid>
        {isVideo ? null : (
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              value={checkedValues.text}
              placeholder={t("keywords")}
              onChange={handleKeysChange}
            />
          </Grid>
        )}
        <Grid className={classes.container} item container spacing={2}>
          {isNews ? (
            <Grid item xs>
              <AuthorFilter checkedValues={checkedValues} onChange={onChange} />
            </Grid>
          ) : null}
          <Grid item xs>
            <DateFilter
              value={checkedValues.period.start}
              onChange={handleFromDateChange}
              lable={"from"}
            />
          </Grid>
          <Grid item xs>
            <DateFilter
              value={checkedValues.period.end}
              onChange={handleToDateChange}
              lable={"to"}
            />
          </Grid>
          <Grid item xs>
            <Button
              className={classes.findBtn}
              color="default"
              variant="contained"
              onClick={handleFindClick}
            >
              {t("find")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MediaFilters;
