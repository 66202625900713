import { makeStyles, Theme } from "@material-ui/core";

export const useStubsBugsStyle = makeStyles((theme: Theme) => ({
  container: {
    position: "absolute",
    backgroundColor: theme.palette.common.white,
    width: "100%",
    height: "100%",
    padding: "14% 12%",
  },
  head: {
    width: 500,
    fontSize: 24,
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
  headSecondary: {
    width: 400,
    fontSize: 150,
    color: theme.palette.secondary.main,
    fontWeight: 700,
  },
  link: {
    fontSize: 24,
    color: theme.palette.primary.dark,
    textDecoration: "none",
  },
}));

export const useFillingStyle = makeStyles((theme: Theme) => ({
  Container: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    height: "100%",
    padding: "150px 10%",
  },
  head: {
    width: 500,
    fontSize: 50,
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
  headSecondary: {
    width: 400,
    fontSize: 150,
    color: theme.palette.secondary.main,
    fontWeight: 700,
  },
  link: {
    fontSize: 24,
    color: theme.palette.primary.dark,
    textDecoration: "none",
  },
}));

export const useInMadeingStyle = makeStyles((theme: Theme) => ({
  container: {
    padding: "20px 40px",
    border: "1px solid grey",
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
  },
  title: {
    padding: "50px 0px",
    fontSize: 50,
    fontWeight: 700,
  },
  message: {
    paddingBottom: "50px",
    fontSize: 25,
    color: theme.palette.text.secondary,
    width: 400,
  },
}));

export const useEmptyFilterResultStyle = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    minHeight: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("mobileVertical")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("tablet")]: {
      flexDirection: "row",
    },
  },
  head: {
    width: 500,
    fontSize: 24,
    color: theme.palette.text.primary,
    fontWeight: "bold",
    [theme.breakpoints.up("mobileVertical")]: {
      fontSize: 16,
      width: "100%",
      textAlign: "center",
    },
    [theme.breakpoints.up("tablet")]: {
      textAlign: "start",
      fontSize: 17,
    },
    [theme.breakpoints.up("tabletHorisontal")]: {
      width: 500,
      fontSize: 24,
    },
  },
  message: {
    width: 500,
    fontSize: 24,
    color: theme.palette.text.primary,
    fontWeight: 400,
    [theme.breakpoints.up("mobileVertical")]: {
      fontSize: 12,
      marginTop: 10,
      width: "100%",
      textAlign: "center",
    },
    [theme.breakpoints.up("tablet")]: {
      textAlign: "start",
      fontSize: 15,
    },
    [theme.breakpoints.up("tabletHorisontal")]: {
      width: 500,
      fontSize: 24,
    },
  },
}));
