import { FC, useState } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { enUS, ru } from "date-fns/locale";
import { useLanguage } from "context/Translation";
import { useTranslation } from "react-i18next";
import { DateFilterProps } from "../Filters.types";
import { useDatePickerStyles } from "./Filters.styles";
// import { useDatePickerStyles } from '../Filters.styles'

const DateFilter: FC<DateFilterProps> = ({
  value = "",
  onChange,
  lable = "date",
}) => {
  // const classes = useDatePickerStyles()
  // const [value, setValue] = useState<Date | null>(new Date())
  const [errorDate, setErrorDate] = useState({
    error: false,
    minDate: false,
    maxDate: false,
  });

  const handleDateChange = (
    date: MaterialUiPickersDate,
    value: string | null | undefined
  ) => {
    setErrorDate({ error: false, minDate: false, maxDate: false });

    const allValue = value && value[value.length - 1] !== "_";
    if (!allValue) {
      return;
    }

    if (!date) {
      setErrorDate({ ...errorDate, error: true });
      return;
    }

    // if (date < minDate) {
    //   setErrorDate({ ...errorDate, minDate: true })
    //   return
    // }

    // if (date > maxDate) {
    //   setErrorDate({ ...errorDate, maxDate: true })
    //   return
    // }

    onChange(date);
  };
  const { isRus } = useLanguage();
  const { t } = useTranslation();
  const classes = useDatePickerStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={isRus ? ru : enUS}>
      <KeyboardDatePicker
        fullWidth
        autoOk
        size="small"
        variant="inline"
        inputVariant="outlined"
        invalidDateMessage=""
        format="dd/MM/yyyy"
        value={value ? value : null}
        InputProps={{ placeholder: t(lable) }}
        InputAdornmentProps={{
          position: "end",
          className: classes.calendareButton,
        }}
        onChange={(date, value) => handleDateChange(date, value)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateFilter;
