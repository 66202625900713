import { makeStyles, Theme } from '@material-ui/core'

export const useAnalyticsCardStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    border: `1px solid ${theme.palette.grey[100]}`,
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 5,
    height: 'fit-content',
    width: '100%',
    maxWidth: 400,
    minWidth: 350,
    minHeight: 540,
    [theme.breakpoints.down(380)]: {
      maxWidth: 280,
      minWidth: 240,
      maxHeight: 400,
      minHeight: 380,
      padding: 5
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  },

  imgContainer: {
    height: 290,
    minWidth: 162,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all .5s ease-out',
    [theme.breakpoints.down(380)]: {
      maxWidth: 280,
      height: 200
    },
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      transition: 'all .5s ease-out'
    }
  },
  title: {
    fontSize: 17,
    lineHeight: '25px',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(876)]: {
      fontSize: 15
    }
  },
  date: {
    color: theme.palette.secondary.main
  },
  cropHeight: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.text.secondary,
    '& p': {
      display: '-webkit-box',
      WebkitLineClamp: 5,
      WebkitBoxOrient: 'vertical',
      overflowY: 'hidden',
      marginTop: 10,
      [theme.breakpoints.down(380)]: {
        WebkitLineClamp: 2
      }
    }
  },
  longDescription: {
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2
  },

  bodyContainer: {
    minHeight: 200,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    width: '100%',
    [theme.breakpoints.down(380)]: {
      maxWidth: 280,
      minHeight: 160
    }
  },

  infoContainer: {
    display: 'flex',
    justifyContent: 'inherit'
  },
  titleContainer: {
    lineHeight: '25px',
    fontWeight: 700,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  }
}))
