import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { routers } from "routers";
import Footer from "components/common/Footer";
import Loginform from "components/Login/Login";
interface Props {
  username: string;
  password: string;
}

const Login: React.FC<Props> = () => {
  return (
    <>
      <Fragment>
        <Switch>
          <Route exact path={routers.login}>
            <Loginform />
          </Route>
        </Switch>
        <Footer />
      </Fragment>
    </>
  );
};

export default Login;
