import { FC, Fragment } from 'react'
import { useTreeItemStyles } from './TreeItem.styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Box, Grid } from '@material-ui/core'
import Breadcrumb from 'components/common/Breadcrumb'
import SectionTitle from 'components/common/SectionTitle'
import TreeItem from './TreeItem'
import { routers } from 'routers'

const SiteMapPage: FC = () => {
  const classes = useTreeItemStyles()
  const { t } = useTranslation()
  return (
    <Fragment>
      <SectionTitle isShown={false} />
      <Breadcrumb />
      <Box className={classes.bgWhite}>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs>
            <TreeItem
              circleSize={15}
              text={t('home')}
              url={routers.home}
              isBold
            />
          </Grid>

          <Grid item xs>
            <TreeItem
              circleSize={12}
              text={t('about')}
              url={routers.about.home}
              isBold
            />
          </Grid>

          <Grid item xs>
            <TreeItem
              circleSize={12}
              text={t('terms-materials')}
              url={routers.termsMaterials}
              isBold
            />
          </Grid>
          <Grid item xs>
            <TreeItem
              circleSize={12}
              text={t('privacy-policy')}
              url={routers.privacyPolicy}
              isBold
            />
          </Grid>
          <Grid item xs>
            <TreeItem
              circleSize={12}
              text={t('faq')}
              url={routers.faq}
              isBold
            />
          </Grid>
          <Grid item xs>
            <TreeItem
              circleSize={15}
              text={t('sanctions')}
              url={routers.sanctions}
              isBold
            />
          </Grid>
          <Grid item xs>
            <TreeItem
              circleSize={15}
              text={t('feedback')}
              url={routers.feedback}
              isBold
            />
          </Grid>
          <Grid item xs>
            <TreeItem
              circleSize={15}
              text={t('analytics')}
              url={routers.analytics}
              isBold
            />
          </Grid>
          <Grid item xs>
            <TreeItem
              circleSize={15}
              text={t('news')}
              url={routers.news}
              isBold
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}

export default observer(SiteMapPage)
