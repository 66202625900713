import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
// import { routers } from "routers";
import { scrollTopPage } from 'utils'
import { useTagStyles } from './Tag.style'
import { TagProps } from './Tag.types'

const Tag: FC<TagProps> = ({ title, marginRight = false }) => {
  const location = useLocation()
  const classes = useTagStyles()
  const style = `${classes.tag} ${marginRight ? classes.margin : ''}`
  // const urls = location.pathname.split("/");
  // const tab = type ? type : urls[1] === "mediacenter" ? urls[2] : urls[1];

  return location.pathname.indexOf('tags') !== -1 ? (
    <div className={style}>#{title}</div>
  ) : (
    <Link to={'#'} onClick={scrollTopPage}>
      <div className={style}>
        <div className={classes.tagText}>#{title}</div>
      </div>
    </Link>
  )
}

export default Tag
