import { makeStyles, Theme } from "@material-ui/core";
import { defaultContainer } from "styles/defaultStyle";

export const useTagsPageStyles = makeStyles((theme: Theme) => ({
  container: defaultContainer(theme),
  tagText: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "54px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("mobileHorisontal")]: {
      fontSize: 35,
    },
    [theme.breakpoints.up("tabletVertical")]: {
      fontSize: 40,
    },
  },
}));
