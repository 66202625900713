import { makeStyles, Theme } from "@material-ui/core";
import { defaultContainer } from "styles/defaultStyle";

export const useTreeItemStyles = makeStyles((theme: Theme) => ({
  boldText: {
    fontWeight: 600,
    fontSize: "18px",
    color: theme.palette.text.primary,
  },
  notBoldText: {
    fontWeight: 40,
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  circleIcon: {
    marginRight: "10px",
    color: theme.palette.success.main,
  },
  bgWhite: defaultContainer(theme),
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));
