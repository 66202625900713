import { FC, useState } from 'react'
import { LanguageContext } from 'context/Translation'
import i18n from 'i18n'

const TranslationProvider: FC = ({ children }) => {
  const startLanguage = 'ru'
  // const startLanguage = window.navigator.language.includes("ru") ? 'ru' : 'en'
  const [language, setLanguage] = useState(startLanguage)

  const isRus = language.includes('ru') ? true : false
  console.log(isRus, 'isRus')

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage)
    i18n.changeLanguage(newLanguage)
  }
  console.log(language, 'languageeeeee')

  return (
    <LanguageContext.Provider
      value={{
        language,
        isRus,
        changeLanguage
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export default TranslationProvider
