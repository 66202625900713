import { FC, useState } from 'react'
import { useHeaderStyles } from './Header.styles'
import { Logo } from 'components/common/Logo'
import { ExpandMore, Menu } from '@material-ui/icons'
import {
  IconButton,
  Box,
  Collapse,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography
} from '@material-ui/core'
import { HeaderProps } from './Headet.types'
import { navigation } from 'enums/navigation'
import { HeaderLink } from '../Link'
import { LoginButton } from '../Buttons'
import Line from '../Line'
import { useLocation } from 'react-router-dom'
import useContainerStyle from '../Container/Container.styles'
import { showTitle } from 'utils'
import { useTranslation } from 'react-i18next'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

const MobileHeader: FC<HeaderProps> = () => {
  const classes = useHeaderStyles()
  const containerStyle = useContainerStyle()
  const [expanded, setExpanded] = useState<number | boolean>(0)
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const location = useLocation()

  const handleChange = (panel: number) => (_, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleChangeOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu)
  }

  const withText = useMediaQuery('(min-width:480px)')

  const { t } = useTranslation()

  const text = withText ? showTitle(t('portal-name'), classes.text) : null
  const userName = localStorage.getItem('user_name')

  return (
    <header className={`${classes.root} ${containerStyle.root}`}>
      <Grid container className={classes.searchContainer}>
        <Box display="flex" justifyContent={'end'} id="mobile-menu">
          <Logo withText={withText} />
          <IconButton
            size="small"
            className={classes.icon}
            onClick={handleChangeOpenMenu}
          >
            <Menu htmlColor="#000" />
          </IconButton>
        </Box>
      </Grid>
      <Box className={classes.mobileHeaderTitle}>{text}</Box>
      <Box className={classes.mobileMenuTootip}>
        <Collapse in={isOpenMenu} style={{ minWidth: 320 }}>
          <Box width={'100%'} style={{ background: 'white' }} padding={'5px'}>
            <Box
              style={{ display: 'flex', justifyContent: 'space-between' }}
              padding={'0 5px'}
            >
              <LoginButton isDark />

              {userName && (
                <Box className={classes.userBox}>
                  <IconButton>
                    <AccountCircleOutlinedIcon htmlColor="#2E9CCA" />
                  </IconButton>
                  <Typography>{userName}</Typography>
                </Box>
              )}
            </Box>
            <Line />
            {navigation.map(({ title, link, isLink, childrenLinks }, index) =>
              childrenLinks ? (
                <Box key={link}>
                  <Accordion
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                    style={{ boxShadow: 'none' }}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={<ExpandMore fontSize={'large'} />}
                      style={{ display: 'flex' }}
                    >
                      <HeaderLink
                        title={title}
                        link={link}
                        isLink={isLink}
                        isActive={location.pathname.includes(link)}
                        color={'#4d4d4d'}
                        onClick={() => setIsOpenMenu(false)}
                      />
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                      {childrenLinks.map(({ title, link }) => (
                        <Box key={link} padding={'10px'} display={'flex'}>
                          <HeaderLink
                            title={title}
                            link={link}
                            isActive={location.pathname.includes(link)}
                            color={'#4d4d4d'}
                            onClick={() => setIsOpenMenu(false)}
                          />
                        </Box>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ) : (
                <Box key={link} display={'flex'} padding={'10px'}>
                  <HeaderLink
                    title={title}
                    link={link}
                    isLink={isLink}
                    isActive={location.pathname.includes(link)}
                    color={'#4d4d4d'}
                    onClick={() => setIsOpenMenu(false)}
                  />
                </Box>
              )
            )}
          </Box>
        </Collapse>
      </Box>
    </header>
  )
}

export default MobileHeader
