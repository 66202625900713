import { FC } from 'react'
import { Box, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { useLanguage } from 'context/Translation'
import { enUS, ru } from 'date-fns/locale'
import Tag from 'components/common/Tag'
import { scrollTopPage } from 'utils'
import HtmlElement from 'components/common/HtmlElement'
import { useResearchCardStyles } from './ResearchCard.styles'
import { ResearchCardProps } from './ResearchCard.types'
import { routers } from 'routers'

const ResearchCard: FC<ResearchCardProps> = ({ data }) => {
  const classes = useResearchCardStyles()
  const { isRus } = useLanguage()

  const Tags = data.tags.map((item) => (
    <Grid item key={item.id} className={classes.tagItem}>
      <Tag id={item.id} title={item.name} type="analytics" />
    </Grid>
  ))

  const description = data.description
    .replace('<p>', '<div>')
    .replace('</p>', '</div>')

  return (
    <Link to={`${routers.analytics}/${data.id}`} onClick={scrollTopPage}>
      <Box className={classes.container}>
        <Box>
          <Box className={classes.header}>
            <div>
              <span>{data.type.name.toLocaleUpperCase()}</span>
            </div>
            <div>
              <span>
                {format(new Date(data.date), 'dd MMMM yyyy', {
                  locale: isRus ? ru : enUS
                })}
              </span>
            </div>
          </Box>
          <Box className={classes.titleContainer}>
            <Box className={classes.title}>{data.title}</Box>
          </Box>
          <Box className={classes.autors}>
            {data.authors.map(({ short_name }) => `${short_name} `)}
          </Box>
          <Box
            padding={'20px 20px 20px 0px'}
            display={'flex'}
            justifyContent="space-between"
          >
            <Box className={classes.imgBox}>
              <img src={data.cover} alt="" />
            </Box>
            <HtmlElement
              component="div"
              classes={classes.description}
              html={description}
            />
          </Box>
        </Box>
        <Box padding={'20px'} width={'100%'}>
          <Grid container spacing={2}>
            {Tags}
          </Grid>
        </Box>
      </Box>
    </Link>
  )
}

export default ResearchCard
