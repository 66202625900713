import { makeStyles, Theme } from '@material-ui/core'

export const useMembersCardStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    // border: `1px solid ${theme.palette.grey[100]}`,
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 5,
    height: 380,
    width: 300,
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
      // '& >div:first-child': {
      //   '& img': {
      //     transform: 'scale(1.1) rotate(5deg)'
      //   }
      // }
    }
  },
  imgContainer: {
    height: 200,
    width: 200,
    borderRadius: '50%',
    background: 'unset',
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '50%'
    }
  },
  link: {
    textDecoration: 'unset',
    '& :hover ': {
      textDecoration: 'unset'
    }
  },
  title: {
    fontSize: 14,
    lineHeight: '25px',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(876)]: {
      fontSize: 15
    }
  },
  cropHeight: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.text.secondary,
    '& p': {
      display: '-webkit-box',
      WebkitLineClamp: 5,
      WebkitBoxOrient: 'vertical',
      overflowY: 'hidden',
      marginTop: 5
    }
  },
  longDescription: {
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2
  },

  bodyContainer: {
    maxHeight: 200,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    width: '100%'
  },

  infoContainer: {
    display: 'flex',
    justifyContent: 'inherit'
  },
  titleContainer: {
    lineHeight: '25px',
    fontWeight: 700,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  }
}))
