import { Grid } from '@material-ui/core'
import { useAboutChapterStyle } from './AboutProjectHome.styles'
import Chapter from 'components/common/Сhapter'
// import aboutimage from 'assets/image/sanction4.jpg'
import { aboutService } from 'services/about/about.service'

import HtmlElement from 'components/common/HtmlElement'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
const AboutChapter = () => {
  const classes = useAboutChapterStyle()
  const width = window.outerWidth
  const { about, aboutImage } = aboutService
  useEffect(() => {
    aboutService.fetchAboutInfo()
    aboutService.fetchAboutImage()
  }, [])
  console.log(about?.text, 'textttttt')

  const parser = new DOMParser()
  const doc = parser.parseFromString(aboutImage.text, 'text/html')

  // Get the src attribute
  const src = doc.querySelector('img')?.getAttribute('src')

  return (
    <Chapter title="portal-name" isButton={false} padding="30px 0px">
      <Grid container spacing={4}>
        <Grid item xs>
          <HtmlElement
            component="div"
            classes={classes.textContainer}
            html={about?.text}
          />
        </Grid>
        {width >= 1024 ? (
          <Grid item xs className={classes.imgContainer}>
            <img
              src={src?.toString()}
              alt={''}
              width={'95%'}
              className={classes.img}
            />
          </Grid>
        ) : null}
      </Grid>
    </Chapter>
  )
}

export default observer(AboutChapter)
