import { makeStyles, Theme } from '@material-ui/core'

export const usePagingStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaginationItem-root': {
      color: theme.palette.secondary.main
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[50]
    }
  }
}))
