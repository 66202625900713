import { makeStyles, Theme } from '@material-ui/core'

export const useChapterStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.text.primary,
    [theme.breakpoints.up('mobileVertical')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: 24
    }
  },
  modalStyle: {
    position: 'absolute',
    width: '580px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.common.white,
    border: `2px solid ${theme.palette.common.black}`,
    padding: 0
  },
  root: {
    '& .MuiFormLabel-root': {
      zIndex: 1
    }
  }
}))
