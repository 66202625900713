import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { FiltersProps } from 'models/filters'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import DateFilter from './components/DateFilter'
import CountryFilter from './components/CountryFilter'
import { useFiltersStyle } from './Filters.styles'
// import EventTypeFilter from './components/EventTypeFilter'
import { ExpandMore } from '@material-ui/icons'
import FieldFilter from './components/FieldFilter'
import SanctionTypeFilter from './components/SanctionTypeFilter'
import RadioFilter from './components/RadioFilter'

const SanctionFilter: FC<FiltersProps> = ({
  checkedValues,
  onChange,
  onFind
}) => {
  const classes = useFiltersStyle()
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width:600px)')

  const handleTitleChange = ({ target: { value } }) =>
    onChange({ ...checkedValues, title: value })

  // const handleKeysChange = ({ target: { value } }) =>
  //   onChange({ ...checkedValues, text: value })

  const handleFromDateChange = (date) =>
    onChange({
      ...checkedValues,
      period: { ...checkedValues.period, start: date }
    })

  const handleToDateChange = (date) =>
    onChange({
      ...checkedValues,
      period: { ...checkedValues.period, end: date }
    })

  const handleFindClick = () => {
    onFind()
  }

  const MobileFilter = (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMore fontSize={'small'} htmlColor="#fff" />}
      >
        <Typography className={classes.header}>{t('filters')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Box className={classes.filters}>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12}>
              <TextField
                size="small"
                variant="outlined"
                value={checkedValues.title}
                fullWidth
                placeholder="Поиск по тексту"
                onChange={handleTitleChange}
              />
            </Grid>
            <Grid item xs>
              <CountryFilter
                checkedValues={checkedValues}
                onChange={onChange}
              />
            </Grid>
            <Grid
              className={classes.container}
              item
              container
              spacing={2}
              direction={'column'}
            >
              <Grid item xs>
                <SanctionTypeFilter
                  checkedValues={checkedValues}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs>
                <FieldFilter
                  checkedValues={checkedValues}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs>
                <DateFilter
                  value={checkedValues.period.start}
                  onChange={handleFromDateChange}
                  lable={'c'}
                />
              </Grid>
              <Grid item xs>
                <DateFilter
                  value={checkedValues.period.end}
                  onChange={handleToDateChange}
                  lable={'по'}
                />
              </Grid>
              <Grid item xs>
                <RadioFilter
                  onChange={onChange}
                  checkedValues={checkedValues}
                />
              </Grid>
              <Grid item xs>
                <Button
                  className={classes.findBtn}
                  color="default"
                  variant="contained"
                  onClick={handleFindClick}
                >
                  показать
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  )

  return isMobile ? (
    MobileFilter
  ) : (
    <Box className={classes.filters}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={3} className={classes.dateGrid}>
          <DateFilter
            value={checkedValues.period.start}
            onChange={handleFromDateChange}
            lable={'c'}
          />
        </Grid>
        <Grid item xs={3} className={classes.dateGrid}>
          <DateFilter
            value={checkedValues.period.end}
            onChange={handleToDateChange}
            lable={'по'}
          />
        </Grid>
        <Grid item xs={6}>
          <CountryFilter checkedValues={checkedValues} onChange={onChange} />
        </Grid>
        <Grid className={classes.container} item container spacing={2}>
          <Grid item xs={3}>
            <FieldFilter checkedValues={checkedValues} onChange={onChange} />
          </Grid>
          <Grid item xs={3}>
            <SanctionTypeFilter
              checkedValues={checkedValues}
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              size="small"
              variant="outlined"
              value={checkedValues.title}
              fullWidth
              placeholder="Поиск по тексту"
              onChange={handleTitleChange}
              className={classes.textFilter}
            />
          </Grid>
          <Grid item container spacing={2} direction="row">
            <Grid item xs={8}>
              <RadioFilter onChange={onChange} checkedValues={checkedValues} />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                className={classes.findBtn}
                color="default"
                variant="contained"
                onClick={handleFindClick}
              >
                показать
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SanctionFilter
