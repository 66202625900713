import { FC } from "react";
import { Box, Button, Dialog } from "@material-ui/core";
import { CheckOutlined } from "@material-ui/icons";
import { ModalProps } from "./Modal";
import { useModalStyles } from "./Modal.styles";
import { useTranslation } from "react-i18next";

const InvalidFormatModal: FC<ModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const classes = useModalStyles(false);
  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.container}>
        <Box className={classes.text}>{t("invalid-format")}</Box>
        <Box marginTop={"20px"}>
          <Button
            className={classes.button}
            onClick={onClose}
            variant="outlined"
          >
            <CheckOutlined />
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default InvalidFormatModal;
