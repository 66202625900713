import { FC, Fragment, useEffect } from "react";
import SectionTitle from "components/common/SectionTitle";
import Breadcrumb from "components/common/Breadcrumb";
import { Box } from "@material-ui/core";
import { getUrlAdress, isVideo, processingUrlYoutube } from "utils";
import { useLanguage } from "context/Translation";
import { observer } from "mobx-react-lite";
import HtmlElement from "components/common/HtmlElement";
import Spiner from "components/common/Spiner";
import { openDataService } from "services/openData/openData.services";
import { useOpenDataDetailStyles } from "./OpenData.styles";

const DetailPage: FC = () => {
  const urlPage = getUrlAdress(window.location.pathname);
  const id = urlPage[urlPage.length - 1].name;
  const { language } = useLanguage();
  const { detail, lang, loading } = openDataService;
  const { name, description, url } = detail;
  const classes = useOpenDataDetailStyles(isVideo(url));

  useEffect(() => {
    if (id === null) return;
    if (detail.id !== Number(id)) {
      openDataService.fetchDetail(language, Number(id));
      return;
    }
    if (lang !== language) {
      openDataService.fetchDetail(language, Number(id));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, language, id]);

  if (loading) {
    return (
      <Fragment>
        <SectionTitle isEnd background="planet" />
        <Box className={classes.container}>
          <Spiner />
        </Box>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <SectionTitle isEnd title={name} background="planet" />
      <Breadcrumb isEnd title={name} />
      <Box className={classes.container}>
        <Box className={classes.title}>{name}</Box>
        <HtmlElement
          component={"div"}
          classes={classes.text}
          html={description}
        />
        <iframe
          className={classes.iframeFile}
          src={processingUrlYoutube(url)}
          name="open-data-image"
        />
      </Box>
    </Fragment>
  );
};

export default observer(DetailPage);
