import { makeStyles, Theme } from "@material-ui/core";

export const usePersonsCardStyles = makeStyles<Theme>((theme) => ({
  root: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  namePerson: {
    fontSize: 14,
    lineHeight: "26px",
  },
  bold: {
    fontSize: 14,
    lineHeight: "26px",
    fontWeight: 700,
  },
  accountCircle: {
    color: theme.palette.grey[50],
    width: 130,
    height: 130,
    margin: 0,
    padding: 0,
  },
  cardItemLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "start",
    marginBottom: 20,
  },
  authorInfo: {
    padding: "0 24px",
  },
  authorName: {
    fontSize: 14,
    lineHeight: "26px",
    textTransform: "uppercase",
    fontWeight: 700,
    marginBottom: 17,
  },
  authorWork: {
    fontSize: 14,
    lineHeight: "25px",
    fontWeight: 700,
  },
  authorEmail: {
    fontSize: 14,
    lineHeight: "25px",
    color: theme.palette.primary.main,
  },
  teamAccountCircle: {
    color: theme.palette.grey[50],
    width: 140,
    height: 140,
    margin: 0,
    padding: 0,
  },
  partnerCard: {
    border: `1px solid ${theme.palette.grey[100]}`,
    background: theme.palette.common.white,
    borderRadius: 5,
    padding: "25px",
    width: 250,
    height: 250,
    "&:hover": {
      background: theme.palette.grey[50],
    },
  },
  imageConteiner: {
    width: 100,
    height: 100,
    borderRadius: "50%",
  },
  imageItem: {
    objectFit: "cover",
    width: "100%",
    height: 100,
    borderRadius: "50%",
  },
  emptyImgItem: {
    color: theme.palette.grey[50],
    width: 140,
    height: 140,
    margin: 0,
    padding: 0,
  },
  autorCard: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  workText: {
    fontSize: 14,
    lineHeight: "22px",
  },
}));
