import { makeStyles, Theme } from "@material-ui/core";
import background from "assets/image/background.png";

export const useModalStyles = makeStyles((theme: Theme) => ({
  container: (isError) => ({
    backgroundImage: `url(${background})`,
    backgroundSize: "100%",
    backgroundColor: theme.palette.common.white,
    padding: "25px 70px",
    justifyContent: isError ? "space-between" : "space-around",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 5,
    minHeight: 190,
    [theme.breakpoints.down(600)]: {
      padding: "25px",
    },
  }),
  text: {
    color: theme.palette.text.primary,
    fontSize: 18,
    lineHeight: "22px",
    [theme.breakpoints.down(600)]: {
      fontSize: 14,
    },
  },
  email: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    lineHeight: "22px",
    [theme.breakpoints.down(600)]: {
      fontSize: 14,
    },
  },
  button: {
    color: theme.palette.success.main,
    "&.MuiButton-outlined": {
      border: `1px solid ${theme.palette.success.main}`,
    },
  },
}));
