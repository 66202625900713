import { FC, useEffect, useRef, useState } from 'react'
import { HeaderLink } from 'components/common/Link'
// import LanguageButton from 'components/common/Buttons/LanguageButton'
import { navigation } from 'enums/navigation'
import { useHeaderStyles } from './Header.styles'
import { Logo } from 'components/common/Logo'
import { Home } from '@material-ui/icons'
import { Link, useLocation } from 'react-router-dom'
import {
  IconButton,
  Grid,
  Fade,
  Popper,
  Box,
  Button,
  Typography
} from '@material-ui/core'
import { HeaderProps } from './Headet.types'
import { AnchorElType } from 'models'
import { scrollTopPage, showTitle } from 'utils'
import { useMenuState } from 'context/MenuState'
import header from 'assets/image/header.png'
import { useTranslation } from 'react-i18next'
import useContainerStyle from '../Container/Container.styles'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
const DefaultHeader: FC<HeaderProps> = ({ withText = false }) => {
  const classes = useHeaderStyles()
  const containerStyle = useContainerStyle()
  const { isOpen, setOpenMenu } = useMenuState()
  const anchorEl = useRef<AnchorElType>(null)
  const [isDown, setIsDown] = useState(true)
  const [isLogin, setIsLogin] = useState(false)
  // const [anchorLink, setAnchorLink] = useState<null | HTMLElement>(null)
  const [index, setIndex] = useState(0)
  const location = useLocation()

  //logs

  useEffect(() => {
    anchorEl.current = null
    setOpenMenu(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  const token = localStorage.getItem('token')
  useEffect(() => {
    if (token) {
      setIsLogin(true)
    }
  }, [])
  const curr_user = localStorage.getItem('user_name')
  const handleClose = () => {
    localStorage.clear()
    // setAnchorEl(null)
  }

  const handleHoverMenuItem = (event, index) => {
    setIndex(index)
    if (!event || typeof event === undefined) {
      anchorEl.current = null
      setOpenMenu(false)
      return
    }
    anchorEl.current = event?.currentTarget
    setOpenMenu(true)
    setIsDown(false)
  }

  const handleClickOnLink = () => {
    anchorEl.current = null
    setOpenMenu(false)
  }

  const Navigation = navigation.map(
    ({ title, link, childrenLinks, isLink }, index) => (
      <Grid item key={link} style={{ padding: '0 12px' }}>
        <HeaderLink
          title={title}
          link={link}
          isLink={isLink}
          onHover={(event) =>
            handleHoverMenuItem(childrenLinks ? event : null, index)
          }
          isActive={location.pathname.includes(link)}
          onClick={handleClickOnLink}
        />
      </Grid>
    )
  )

  const activeMenu = navigation[index]?.childrenLinks

  const handleMouseOver = () => {
    if (!isDown) {
      setIsDown(true)
      setOpenMenu(false)
    }
  }

  const handleCloseMenu = () => {
    setOpenMenu(false)
  }

  const gridColumns = () => {
    if (!activeMenu || activeMenu?.length <= 4) {
      return '1fr'
    }
    return `repeat(${activeMenu?.length / 4}, 350px)`
  }
  const { t } = useTranslation()
  const text = withText ? showTitle(t('portal-name'), classes.text) : null
  const userName = localStorage.getItem('user_name')

  return (
    <header className={`${classes.headerContainer}${containerStyle.root}`}>
      <Grid
        item
        style={{ height: '150px', position: 'relative' }}
        onMouseOver={handleMouseOver}
      >
        <Box className={classes.topHeader}>
          <Box className={classes.headerImage}>
            <img src={header} alt="header" />
          </Box>
          <Box className={classes.headerTitle}>{text}</Box>
          <Logo />
        </Box>
      </Grid>

      <Grid item container direction="row" className={classes.menu}>
        <Grid item>
          {userName && (
            <Box className={classes.userBox}>
              <IconButton>
                <AccountCircleOutlinedIcon htmlColor="#2E9CCA" />
              </IconButton>
              <Typography>{userName}</Typography>
            </Box>
          )}
        </Grid>
        <Grid
          id="site-menu"
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          style={{ width: 'fit-content' }}
        >
          <Grid item>
            <Link to={'/'} onClick={scrollTopPage}>
              <IconButton size="small" className={classes.icon}>
                <Home htmlColor="#fff" onMouseOver={handleClickOnLink} />
              </IconButton>
            </Link>
          </Grid>
          {Navigation}
          <Box className={classes.buttonBox}>
            <Link to={'/login'} onClick={scrollTopPage}>
              {!curr_user && !isLogin ? (
                <Button className={classes.loginButton}>{t('Login')}</Button>
              ) : (
                <Button className={classes.loginButton} onClick={handleClose}>
                  {t('logout')}
                </Button>
              )}
            </Link>
          </Box>
          {activeMenu ? (
            <Popper
              anchorEl={anchorEl.current}
              open={true}
              className={classes.hoverMenu}
              placement={'bottom'}
            >
              <Fade in={isOpen} timeout={350}>
                <div
                  className={classes.menuGrid}
                  style={{ gridTemplateColumns: gridColumns() }}
                >
                  {activeMenu?.map(({ title, link }) => (
                    <HeaderLink
                      key={link}
                      title={title}
                      link={link}
                      color="#000"
                      onClick={handleCloseMenu}
                    />
                  ))}
                </div>
              </Fade>
            </Popper>
          ) : null}
        </Grid>
      </Grid>
    </header>
  )
}

export default DefaultHeader
