import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { tagGridItem } from 'styles/defaultStyle'

export const useSanctionsChapterStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      minWidth: 290,
      maxWidth: 750,
      width: '100%',
      height: '100%',
      border: `1px solid ${theme.palette.grey[100]}`,
      boxShadow: 'none',
      minHeight: 370,
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down(1025)]: {
        minWidth: 255,
        maxWidth: 340
      }
    },
    media: {
      height: 195
    },

    typeContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '10px 0px'
    },
    clearButtonPosition: {
      borderRadius: ' 4px 0px 0px 4px',
      fontSize: 12,
      color: theme.palette.common.white,
      backgroundColor: 'rgba(0, 0, 0, 0.38)',
      padding: '6px 16px'
    },
    cardContent: {
      paddingTop: 0,
      marginTop: '-15px',
      '&.MuiCardContent-root:last-child': {
        paddingBottom: 5
      }
    },
    title: {
      fontSize: 16,
      color: theme.palette.text.secondary,
      lineHeight: '19px',
      fontWeight: 700
    },
    subscription: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      lineHeight: '16px'
    },
    button: {
      color: theme.palette.success.main,
      fontSize: 14
    },
    svgBox: {
      alignItems: 'flex-end',
      display: 'flex',
      margin: '0px 0px 20px 20px',
      '& svg': {
        width: '104',
        height: '106',
        viewBox: '0 0 104 106',
        fill: 'none'
      }
    },
    calendarBox: {
      width: 36,
      display: 'flex',
      padding: '6px',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.1)'
    },
    calendar: {
      color: theme.palette.success.main
    },
    emptyMedia: {
      height: 195,
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.grey[50]
    },

    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    infoContainerGrid: {
      paddingTop: 10
    },
    date: {
      fontSize: 12,
      color: theme.palette.secondary.main,
      fontWeight: 400,
      borderBottom: `1px solid ${theme.palette.success.main}`,
      paddingBottom: 3
    },
    detailsContainer: {
      width: '1px',
      margin: '-5px 16px',
      backgroundColor: '#C1C1C1'
    },

    time: {
      fontSize: 12,
      color: theme.palette.secondary.main
    },
    location: {
      fontSize: 12,
      color: theme.palette.secondary.main
    },
    registration: {
      fontSize: 14,
      color: theme.palette.text.secondary,
      '& p': {
        margin: 0,
        padding: 0
      }
    },
    paddingY: {
      padding: '10px 0px'
    }
  })
)

export const useSanctionsCardStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 5,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary,
    height: '200px',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    },

    [theme.breakpoints.down(780)]: {
      height: '250px'
    },
    [theme.breakpoints.down(380)]: {
      height: '280px'
    }
  },
  domains: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '90%'
  },
  cardMedia: {
    height: 180,
    width: 300
  },
  gridBox: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 3%'
  },

  domainText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    margin: '0 2%'
  },
  countryName: {
    color: theme.palette.secondary.main
  },
  domain: {
    color: theme.palette.secondary.main
  },
  domainBox: {
    color: theme.palette.secondary.main
  },
  domainName: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(690)]: {
      fontSize: 14
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 12
    }
  },
  name: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(690)]: {
      fontSize: 14
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 12
    }
  },
  flagIcon: {
    width: 30,
    height: 30,
    marginRight: 5,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  },
  domainContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'absolute',
    bottom: '10%',
    padding: '0 10px',
    width: '100%',
    margin: '10px 0'
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  tagText: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(680)]: {
      fontSize: 14
    },
    [theme.breakpoints.down(590)]: {
      fontSize: 12
    }
  },
  bttonGrid: {
    /* bottom: 0; */
    display: 'flex',
    justifyContent: 'end',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 30,
    '& a': {
      color: theme.palette.secondary.main,
      marginInlineEnd: 20
    }
  },
  tagBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  dateBox: {
    width: 230,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: 180
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%'
  },
  titleContainer: {
    fontSize: 18,
    lineHeight: '25px',
    fontWeight: 700,
    maxHeight: 45,
    [theme.breakpoints.down(768)]: {
      fontSize: 14
    }
  },
  title: {
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  cropHeight: {
    height: 200,
    marginTop: 10,
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.text.secondary
  },
  date: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.secondary.main,
    width: 140
  },
  icon: {
    marginRight: 10,
    color: theme.palette.secondary.main
  },
  informationContainer: {
    display: 'flex',
    marginBottom: 10,
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    [theme.breakpoints.down(650)]: {
      alignItems: 'start'
    }
  },
  line: {
    color: theme.palette.success.main,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '0 auto',
    [theme.breakpoints.down(768)]: {
      width: '85%',
      height: 1
    }
  },
  imgContainer: {
    padding: 0,
    height: '180px',
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '5px 0 0 5px'
    },
    [theme.breakpoints.down(768)]: {
      flexBasis: '50%',
      order: 2,
      maxWidth: '48%',
      '& img': {
        borderRadius: '0 0 0 5px'
      }
    }
  },
  lineContiner: {
    display: 'flex',
    alignItems: 'center'
  },
  tagsContainer: {
    position: 'absolute',
    bottom: '20px',
    [theme.breakpoints.down(768)]: {
      position: 'unset',
      marginTop: '20px'
    }
  },
  bodyContainer: {
    padding: '15px 12px',
    flexBasis: '53%',
    position: 'relative',
    [theme.breakpoints.down(768)]: {
      flexBasis: '100%',
      order: 0
    }
  },
  about: {
    flexBasis: '20%',
    marginLeft: '10px',
    fontSize: 14,
    lineHeight: '19px',
    [theme.breakpoints.down(768)]: {
      flexBasis: '50%',
      order: 3,
      marginLeft: 0
    },
    [theme.breakpoints.down(380)]: {
      padding: '5px'
    }
  },
  tagItem: tagGridItem
}))
