import { FC, Fragment, useEffect } from 'react'
import SectionTitle from 'components/common/SectionTitle'
import Breadcrumb from 'components/common/Breadcrumb'
import { Box, Grid } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import Paging from 'components/common/Paging'
import EmptyFilterResult from 'components/common/StubsAndBugs/EmptyFilterResult'
import { useAnalyticsStyles } from './Analtyics.styles'
import { scrollTopPage } from 'utils'
// import { useLanguage } from 'context/Translation'
import Spiner from 'components/common/Spiner'
import { AnalyticsCard } from 'components/common/Cards/Analytics'
import { analyticsService } from 'services/analytics/analytics.service'

const AnalyticsPage: FC = () => {
  const { paging, researchList, loading } = analyticsService
  // const { language } = useLanguage()

  const classes = useAnalyticsStyles()
  useEffect(() => {
    analyticsService.fetchResearchList()
  }, [])

  const handlePageChange = (page: number) => {
    analyticsService.fetchPagingResearch(page)
    scrollTopPage()
  }

  // const analytics = [
  //   {
  //     id: 1,
  //     title:
  //       'International Food Law: How Food Law Can Balance Health, Environment and Animal Welfare',
  //     text: 'The phenomenon of globalization together with the growth of the world population has implied a significant increase in the demand for food products. It entails new challenges under the profile of international and European law as to the necessity to harmonize rules and standards on animal welfare, environmental protection and food safety, especially in the optics of the crucial debate on the long-term sustainability of food production. The volume International Food Law edited by Cinzia Caporale, Ilja Richard Pavone and Maria Pia Ragionieri examines the global and European landscape of food governance. Empirically, this book investigates the main legal issues related to food regulation in a global context. The underlying idea behind this book is that food law must be global (both in its transnational and multi-level dimension) in order to meet the challenges related to the process of globalization. In current times, all aspects of the food chain (from processing, distribution to consumer purchase and consumer use) have a transboundary dimension. Food has become, therefore, a global good that requires common and agreed standards ensuring at the same time food safety and sustainable production. In detail, this book will evaluate whether the current legal landscape is able to guarantee high standards of protection to human health, environment and animal welfare, or whether further reforms are requested. As witnessed by the COVID-19 pandemic and its probable transmission within the Wuhan wet market, environmental law, health law and food law need probably to be more interconnected through a holistic approach built around the One Health Approach.',
  //     img: '/analytics/analytics1.jpg'
  //   },
  //   {
  //     id: 2,
  //     title:
  //       'Greening of international economic relations in the context of the evolution of the concept of sustainable development ',
  //     text: 'The paper substantiates the necessity of interrelation of economic development and environmental protection. The elimination of contradictions between economic development and environmental protection is possible on the basis of the ecologization of international economic relations, which means the replacement of the anthropocentric approach by the eco-centric approach to sustainable development. The evolution of the concept of sustainable development clearly demonstrates that today economic development is limited not by the level of scientific and technological progress, but by the state of the environment. The increasing influence of the environmental component of the concept of sustainable development on its economic component can be observed. The principle of sustainable development was formed as a principle of international law. The author substantiates the point of view, according to which the principle of sustainable development should be considered as an interdisciplinary principle of international environmental and international economic law.',
  //     img: '/analytics/analytics2.jpg'
  //   }
  // ]

  return (
    <Fragment>
      <SectionTitle isShown={false} />
      <Breadcrumb />

      {loading ? (
        <Spiner />
      ) : (
        <Box className={classes.container}>
          <Box>
            <Grid container spacing={2} className={classes.contentContainer}>
              {researchList && researchList.length ? (
                researchList.map((item, idx) => (
                  <Grid item lg={4} md={6} sm={12} key={idx}>
                    <AnalyticsCard analytics={item} />
                  </Grid>
                ))
              ) : (
                <EmptyFilterResult />
              )}
            </Grid>
            <Paging paging={paging} onChange={handlePageChange} />
          </Box>
        </Box>
      )}
    </Fragment>
  )
}

export default observer(AnalyticsPage)
