import { makeAutoObservable, runInAction } from 'mobx'
import axios from 'axios'
import getOrigin from 'utils/getOrigin'
import {
  NewDetailItem,
  NewListItem,
  PhotoGallery,
  PhotoGalleryList,
  VideoGalleryList
} from 'models'
import { defaultPaging } from 'enums'
import { defaulrNewDetail, defaultPhotoGallery } from './mediacenter.data'
import { FilterOptions } from 'models/filters'
import { format } from 'date-fns'
import { returnIds } from 'utils'

const url = 'api/news'
const urlDetail = 'api/news'
const urlPhotos = getOrigin() + 'api/igallery/list'
const urlPhotosDetail = getOrigin() + 'api/igallery'
const urlVideos = getOrigin() + 'api/vgallery/list'

class MediacenterService {
  public news: NewListItem[] = []
  public details: NewDetailItem = defaulrNewDetail
  public pagingNews = defaultPaging
  private newsFilter = {}
  ////////
  public photoGallerys: PhotoGalleryList[] = []
  public photoGallery: PhotoGallery = defaultPhotoGallery
  public pagingPhoto = defaultPaging
  private photoFilter = {}
  ////////
  public videoGallerys: VideoGalleryList[] = []
  public pagingVideo = defaultPaging
  private videoFilter = {}
  public lang = ''
  public loading = false

  constructor() {
    makeAutoObservable(this)
  }

  async fetchNews(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...defaultPaging
      }
      const result = await axios.get(url, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        this.news = data
        this.lang = language
        this.pagingNews = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  private getNewFilter = (filters: FilterOptions) => {
    const authorId = filters.authors ? returnIds(filters.authors) : null
    return {
      title: filters.title ?? null,
      text: filters.text ?? null,
      date_start: filters.period.start
        ? format(new Date(filters.period.start), 'yyyy-MM-dd')
        : null,
      date_end: filters.period.end
        ? format(new Date(filters.period.end), 'yyyy-MM-dd')
        : null,
      author_id: authorId
    }
  }

  async fetchNewsFilters(language: string, filters: FilterOptions) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...this.getNewFilter(filters),
        ...defaultPaging
      }
      const result = await axios.get(url, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        this.news = data
        this.lang = language
        this.newsFilter = this.getNewFilter(filters)
        this.pagingNews = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchPagingNews(newPage: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: this.lang,
        ...this.newsFilter,
        ...this.pagingNews,
        page: newPage
      }
      const result = await axios.get(url, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.news = data
        this.pagingNews = { ...this.pagingNews, page: newPage }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchNewDetail(language: string, id: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(`${urlDetail}/${id}`, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.details = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchPhotoGallerys(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...defaultPaging
      }
      const result = await axios.get(urlPhotos, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        this.photoGallerys = data
        this.lang = language
        this.pagingPhoto = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchPhotosPaging(newPage: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: this.lang,
        ...this.photoFilter,
        ...this.pagingPhoto,
        page: newPage
      }
      const result = await axios.get(urlPhotos, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.photoGallerys = data
        this.pagingPhoto = { ...this.pagingPhoto, page: newPage }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchPhotosDetail(language: string, id: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(`${urlPhotosDetail}/${id}`, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.lang = language
        this.photoGallery = data
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  private getPhotoFilter = (filters: FilterOptions) => {
    return {
      title: filters.title ?? null,
      text: filters.text ?? null,
      date_start: filters.period.start
        ? format(new Date(filters.period.start), 'yyyy-MM-dd')
        : null,
      date_end: filters.period.end
        ? format(new Date(filters.period.end), 'yyyy-MM-dd')
        : null
    }
  }

  async fetchPhotoFilters(language: string, filters: FilterOptions) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...this.getPhotoFilter(filters),
        ...defaultPaging
      }
      const result = await axios.get(urlPhotos, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        this.news = data
        this.lang = language
        this.photoFilter = this.getPhotoFilter(filters)
        this.pagingPhoto = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchVideoGallerys(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...defaultPaging
      }
      const result = await axios.get(urlVideos, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        this.videoGallerys = data
        this.lang = language
        this.pagingVideo = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchVideoPaging(newPage: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: this.lang,
        ...this.videoFilter,
        ...this.pagingVideo,
        page: newPage
      }
      const result = await axios.get(urlVideos, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.videoGallerys = data
        this.pagingVideo = { ...this.pagingVideo, page: newPage }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  private getVideoFilter = (filters: FilterOptions) => {
    return {
      title: filters.title ?? null,
      date_start: filters.period.start
        ? format(new Date(filters.period.start), 'yyyy-MM-dd')
        : null,
      date_end: filters.period.end
        ? format(new Date(filters.period.end), 'yyyy-MM-dd')
        : null
    }
  }

  async fetchVideoFilters(language: string, filters: FilterOptions) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...this.getVideoFilter(filters),
        ...defaultPaging
      }
      const result = await axios.get(urlVideos, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        this.videoGallerys = data
        this.lang = language
        this.videoFilter = this.getVideoFilter(filters)
        this.pagingPhoto = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  public cleanPage = () => {
    this.pagingNews = defaultPaging
    this.pagingPhoto = defaultPaging
    this.pagingVideo = defaultPaging
    this.news = []
    this.details = defaulrNewDetail
    this.photoGallerys = []
    this.photoGallery = defaultPhotoGallery
    this.videoGallerys = []
    this.newsFilter = {}
    this.photoFilter = {}
    this.videoFilter = {}
  }

  public cleanDetail = () => {
    this.details = defaulrNewDetail
    this.photoGallery = defaultPhotoGallery
  }
}

export const mediacenterService = new MediacenterService()
