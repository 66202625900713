import { FC } from 'react'
import { Box, Grid } from '@material-ui/core'
import { EventsCardProps } from '../Cards.types'
import { Link } from 'react-router-dom'
import { useEventsCardStyles } from './Events.styles'
import Tag from 'components/common/Tag'
import { format } from 'date-fns'
import { AccessTime, LocationOn, Event } from '@material-ui/icons'
import { scrollTopPage } from 'utils'
import HtmlElement from 'components/common/HtmlElement'
import { routers } from 'routers'

const EventsCard: FC<EventsCardProps> = ({ event }) => {
  const { id, image, tags, date, summary, title, time, location } = event
  const classes = useEventsCardStyles()
  const Line: FC = () => (
    <Grid item className={classes.lineContiner}>
      <Grid item className={classes.line} />
    </Grid>
  )

  const TagsContent = tags ? (
    <Box paddingTop={'20px'} width={'100%'}>
      <Grid container spacing={2}>
        {tags.map((tag) => (
          <Grid item key={tag.id} className={classes.tagItem}>
            <Tag key={tag.id} id={tag.id} title={tag.name} type="analytics" />
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : null

  const Location = location ? (
    <Box className={classes.informationContainer}>
      <LocationOn className={classes.icon} />
      {location}
    </Box>
  ) : null

  const Time = time ? (
    <Box className={classes.informationContainer}>
      <AccessTime className={classes.icon} />
      {time}
    </Box>
  ) : null

  return (
    <Link to={`${routers.events}/${id}`} onClick={scrollTopPage}>
      <Grid container className={classes.container}>
        <Grid item xs={3} className={classes.imgContainer}>
          <img src={image[0]} alt="Live from space album cover" />
        </Grid>
        <Grid item className={classes.bodyContainer}>
          <Box className={classes.titleContainer}>
            <Box className={classes.title}>{title}</Box>
          </Box>
          <HtmlElement component="div" classes={'crop-height'} html={summary} />
          {TagsContent}
        </Grid>
        <Line />
        <Grid item className={classes.about}>
          {Location}
          <Box className={classes.informationContainer}>
            <Event className={classes.icon} />
            {format(new Date(date.start), 'dd.MM.yyyy')} -{' '}
            {format(new Date(date.end), 'dd.MM.yyyy')}
          </Box>
          {Time}
        </Grid>
      </Grid>
    </Link>
  )
}

export default EventsCard
