import { FC, Fragment } from 'react'
// import Partners from "components/common/Partners";
//import Chapter from "components/common/Сhapter";
// import EventsChapter from "./EventsChapter";
// import MediaCenterChapter from "./MediaCenter";
import HomeCarousel from './HomeCarousel'
import { Box } from '@material-ui/core'

const HomePage: FC = () => (
  <Fragment>
    <HomeCarousel />
    {/* <MediaCenterChapter />
    <EventsChapter /> */}
    <Box style={{ marginBottom: '2rem' }} />
    {/*<Chapter title="our-partners" isButton={false} padding="30px 0px 50px 0px">*/}
    {/* <Partners /> */}
    {/*</Chapter>*/}
  </Fragment>
)

export default HomePage
