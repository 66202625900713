import { FC, Fragment, useEffect } from "react";
import Footer from "components/common/Footer";
import FAQPage from "components/FAQ";
import { useLanguage } from "context/Translation";
import { faqService } from "services/faq/faq.service";

const FAQ: FC = () => {
  const { language } = useLanguage();
  useEffect(() => {
    faqService.fetchFAQInfo(language);
  }, [language]);
  return (
    <Fragment>
      <FAQPage />
      <Footer />
    </Fragment>
  );
};

export default FAQ;
