import { FC } from 'react'
import { Box, Container, Grid, useMediaQuery } from '@material-ui/core'
import Line from 'components/common/Line'
import { ButtonLink } from 'components/common/Link'
import { ChapterProps } from './Chapter.types'
import { useTranslation } from 'react-i18next'
import { useChapterStyles } from './Chapter.style'
import { showTitle } from 'utils'

const Chapter: FC<ChapterProps> = ({
  children,
  title,
  isButton = false,
  allLink,
  isSmall = false,
  padding
}) => {
  const classes = useChapterStyles()
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width:600px)')
  return (
    <Box style={{ width: '100%' }}>
      <Container>
        <Grid item>{showTitle(t(title), classes.title)}</Grid>

        <Grid item xs>
          {<Line />}
        </Grid>
      </Container>
      <Box padding={padding ? padding : isSmall ? '30px 50px' : '50px 50px'}>
        {children}
      </Box>
      {isButton ? (
        <Box display={'flex'} justifyContent={isMobile ? 'center' : 'flex-end'}>
          <ButtonLink title={'all'} link={allLink ? allLink : ''} />
        </Box>
      ) : null}
    </Box>
  )
}

export default Chapter
