import { FC, Fragment, ReactElement } from "react";
import SectionTitle from "components/common/SectionTitle";
import Breadcrumb from "components/common/Breadcrumb";
import TabsPanel from "components/common/TabsPanel";
import { useMediacenterStyles } from "./Mediacenter.styles";
import { Box } from "@material-ui/core";
import PhotosPage from "./Photos";
import VideosPage from "./Videos";
import NewsPage from "./News";
import { observer } from "mobx-react-lite";
import { mediacenterTabs } from "enums/tabs";
import { getUrlAdress } from "utils";
import { useLocation } from "react-router-dom";
import { FilterCombinedProps, FilterOptions } from "models/filters";
import { MediaFilters } from "components/common/Filters";
import { mediacenterService } from "services/mediacenter/mediacenter.service";
import { useLanguage } from "context/Translation";
import Spiner from "components/common/Spiner";

export const panels: ReactElement[] = [
  <NewsPage key={"NewsPage"} />,
  <PhotosPage key={"PhotosPage"} />,
  <VideosPage key={"VideosPage"} />,
];

const MediacenterPage: FC<FilterCombinedProps<FilterOptions>> = ({
  checkedValues,
  onChange,
}) => {
  const location = useLocation();
  const { language } = useLanguage();
  const { loading } = mediacenterService;
  const url = getUrlAdress(location.pathname);
  const tab = mediacenterTabs.findIndex(
    (tab) => tab.lable === url[url.length - 1].name
  );
  const activeTab = tab < 0 ? 0 : tab;
  const classes = useMediacenterStyles();

  const handleFind = () => {
    switch (activeTab) {
      case 0:
        mediacenterService.fetchNewsFilters(language, checkedValues);
        break;
      case 1:
        mediacenterService.fetchPhotoFilters(language, checkedValues);
        break;
      case 2:
        mediacenterService.fetchVideoFilters(language, checkedValues);
        break;
    }
  };

  if (loading) {
    return (
      <Fragment>
        <SectionTitle background="earth" />
        <Breadcrumb />
        <MediaFilters
          checkedValues={checkedValues}
          onChange={onChange}
          onFind={handleFind}
          isVideo={url[url.length - 1].name === "videos"}
          isNews={url[url.length - 1].name === "news"}
        />
        <Box className={classes.container}>
          <Spiner />
        </Box>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <SectionTitle background="earth" />
      <Breadcrumb />
      <MediaFilters
        checkedValues={checkedValues}
        onChange={onChange}
        onFind={handleFind}
        isVideo={url[url.length - 1].name === "videos"}
        isNews={url[url.length - 1].name === "news"}
      />
      <Box className={classes.container}>
        <TabsPanel
          tabs={mediacenterTabs}
          panels={panels}
          activeTab={activeTab}
        />
      </Box>
    </Fragment>
  );
};

export default observer(MediacenterPage);
