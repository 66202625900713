import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import getOrigin from "utils/getOrigin";
import { OpenDataList } from "models";
import { defaultPaging } from "enums";
import { emptyOpenData } from "./openData.data";

const url = getOrigin() + "api/opendata";

class OpenDataService {
  public dataList: OpenDataList[] = [];
  public detail: OpenDataList = emptyOpenData;
  public paging = defaultPaging;
  public lang = "";
  public loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchDatas(language: string) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: language,
        ...defaultPaging,
      };
      const result = await axios.get(url, { params });
      if (result.status !== 200) {
        return console.log("result", result);
      }
      runInAction(() => {
        const { data, total } = result.data.data;
        this.dataList = data;
        this.lang = language;
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize),
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  async fetchPagingData(newPage: number) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: this.lang,
        ...this.paging,
        page: newPage,
      };
      const result = await axios.get(url, { params });
      if (result.status !== 200) {
        return console.log("result", result);
      }
      runInAction(() => {
        const { data } = result.data.data;
        this.dataList = data;
        this.paging = { ...this.paging, page: newPage };
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  async fetchDetail(language: string, id: number) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: language,
      };
      const result = await axios.get(`${url}/${id}`, { params });
      if (result.status !== 200) {
        return console.log("result", result);
      }
      runInAction(() => {
        this.detail = result.data.data;
        this.lang = language;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }
}

export const openDataService = new OpenDataService();
