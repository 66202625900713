import { FC, useState } from 'react'
import { MenuContext } from '.'

const MenuProvider: FC = ({ children }) => { 
  const [isOpen, setIsOpen] = useState(false)

    const setOpenMenu = (newState: boolean) => {
      setIsOpen(newState)
    }
  return (
    <MenuContext.Provider
      value={{
        isOpen,
        setOpenMenu
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

export default MenuProvider
