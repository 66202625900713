import { FC, Fragment, useEffect } from 'react'
import HomePage from 'components/Home'
import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import Container from 'components/common/Container'
import { useLanguage } from 'context/Translation'
import { generalService } from 'services/general/general.service'

const Home: FC = () => {
  const { language } = useLanguage()
  const { lang } = generalService
  useEffect(() => {
    if (lang !== language) {
      generalService.fetchHomePage()
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])
  return (
    <Fragment>
      <Header withText />
      <Container>
        <HomePage />
      </Container>
      <Footer />
    </Fragment>
  )
}

export default Home
