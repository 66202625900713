import { createTheme } from "@material-ui/core";
import { common } from "@material-ui/core/colors";
declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobileVertical: true;
    mobileHorisontal: true;
    tablet: true;
    tabletVertical: true;
    tabletHorisontal: true;
    laptop: true;
    desktop: true;
  }
}
export const globalBaseStyles = createTheme({
  palette: {
    primary: {
      main: "#25274D",
      dark: "#213E76",
    },
    info: {
      main: "#29648A",
    },
    secondary: {
      main: "#2E9CCA",
    },
    success: {
      main: "#57AB27",
    },
    text: {
      primary: "#4D4D4D",
      secondary: "#696A6D",
    },
    grey: {
      50: "#F0F2F4",
      100: "#D1D3D5",
    },
  },

  breakpoints: {
    values: {
      mobileVertical: 320,
      mobileHorisontal: 480,
      tablet: 600,
      tabletVertical: 768,
      tabletHorisontal: 1024,
      laptop: 1280,
      desktop: 1440,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: common.white,
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: common.white,
      },
    },
  },
});
