import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { defaultContainer, defaultFilters } from 'styles/defaultStyle'

export const useAnalyticsStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      ...defaultContainer(theme),
      padding: '30px 5% 30px 5%',
      '& h3': {
        color: theme.palette.secondary.main,
        textTransform: 'uppercase'
      }
    },
    filters: defaultFilters(theme),
    iconTextdiv: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.main
      },
      '& p': {
        margin: '5px'
      }
    },
    contentContainer: {
      margin: 0,
      width: '100%',
      [theme.breakpoints.down(910)]: {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    listItems: {
      flexBasis: '100%',
      margin: '30px'
    },
    detailsTitel: {
      fontSize: 24,
      lineHeight: '33px',
      textTransform: 'uppercase',
      fontWeight: 700,
      color: theme.palette.text.primary
    },
    detailsInfo: {
      fontSize: 14,
      color: theme.palette.text.primary,
      lineHeight: '19px',
      textAlign: 'justify',
      margin: '30px 30px'
    },
    detailsImgContainer: {
      width: 350,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: 5,
        marginTop: 30,
        width: 350,
        height: 250
      }
    }
  })
)

export const useDetailNewsStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      ...defaultContainer(theme),
      padding: '30px 5% 30px 5%',
      '& h3': {
        color: theme.palette.secondary.main,
        textTransform: 'uppercase'
      }
    },
    filters: defaultFilters(theme),
    iconTextdiv: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.main
      },
      '& p': {
        margin: '5px'
      }
    },
    title: {
      fontSize: 24,
      lineHeight: '33px',
      textTransform: 'uppercase',
      fontWeight: 700,
      color: theme.palette.text.primary
    },
    text: {
      fontSize: 14,
      color: theme.palette.text.primary,
      lineHeight: '19px',
      textAlign: 'justify'
    },
    textMargin: {
      margin: '30px 30px'
    },
    imgBox: {
      [theme.breakpoints.down(960)]: {
        width: '100%'
      }
    },
    img: {
      background: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 5,
      marginTop: 30,
      width: 350,
      height: 250,
      [theme.breakpoints.down(960)]: {
        width: '100%',
        height: 450,
        objectFit: 'cover'
      },
      [theme.breakpoints.down(500)]: {
        height: 250
      }
    }
  })
)
