import { makeStyles, Theme } from "@material-ui/core";

export const useLegalBaseCardStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    boxSizing: "border-box",
    borderRadius: 5,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.grey[50],
    width: "100%",
    color: theme.palette.text.primary,
    padding: "10px 20px",
  },
  title: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "16px 20px",
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "25px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  autors: {
    display: "flex",
    padding: "5px 20px",
    width: "100%",
    backgroundColor: theme.palette.grey[50],
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "25px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  summary: {
    fontFamily: "NotoSans",
    fontSize: 14,
    lineHeight: "19px",
    fontWeight: 500,
    textAlign: "justify",
    color: theme.palette.text.secondary,
  },
  containerHeader: {
    backgroundColor: theme.palette.grey[50],
    padding: "10px 20px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    [theme.breakpoints.down(768)]: { gridTemplateColumns: "1fr 1fr" },
    [theme.breakpoints.down(450)]: { gridTemplateColumns: "1fr" },
// [theme.breakpoints.up(768)]: { justifyContent: "space-between" },
  },
  headerItem: {
    fontWeight: 700,
    display: "flex",
    justifySelf: "start",
    alignItems: "center",
    "& img": {
      width: 30,
    },
    "& div": {
      paddingLeft: 15,
    },
  },
  lang: {
    color: theme.palette.success.main,
  },
  description: {
    color: theme.palette.primary.main,
  },
  headerItemEnd: {
    fontWeight: 700,
    display: "flex",
    justifySelf: "end",
    alignItems: "center",
    [theme.breakpoints.down(768)]: { justifySelf: "start" },
  },
  dateRange: {
    color: theme.palette.secondary.main,
  },
  cardContent: {
    width: "100%",
  },
  titleBox: {
    justifyContent: "flex-start",
    display: "flex",
    width: "100%",
    marginBottom: 10,
  },
  skeltonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  skelton: {
    width: "100%",
    height: 30,
  },
  loadingHeaderItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  skelton_1: {
    marginLeft: 15,
    width: "100%",
    height: 30,
  },
  skelton_2: {
    marginLeft: 15,
    width: "100%",
    height: 30,
  },
  loadingHeaderItemEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  skelton_3: {
    marginLeft: 15,
    width: 75,
    height: 30,
  },
  loadingCardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
  },
  skelton_4: {
    marginBottom: 10,
    width: "50%",
    height: 50,
  },
  skelton_5: {
    width: "100%",
  },
}));

export const useLinkedDocumentCardStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 300,
    height: "100%",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    boxSizing: "border-box",
    borderRadius: 5,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  containerHeader: {
    backgroundColor: theme.palette.grey[50],
    padding: "10px 20px 0 20px",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "4px 4px 0px 0px",
  },
  headerItem: {
    fontWeight: 700,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 15,
    "& img": {
      width: 30,
    },
    "& div": {
      color: theme.palette.text.primary,
      fontSize: 14,
      paddingLeft: 15,
      textTransform: "uppercase",
    },
  },
  lang: {
    color: theme.palette.success.main,
  },
  description: {
    color: theme.palette.primary.main,
  },
  dateRange: {
    color: theme.palette.secondary.main,
  },
  title: {
    fontSize: 14,
    lineHeight: "19px",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
    padding: 22,
  },
}));
