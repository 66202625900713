import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { defaultContainer, defaultFilters } from "styles/defaultStyle";

export const useMediacenterStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      ...defaultContainer(theme),
      padding: "10px 5% 30px 5%",
    },
    filters: defaultFilters(theme),
  })
);
