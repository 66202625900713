import { EventItem } from "models";

export const defaultEventDetail: EventItem = {
  id: 0,
  title: "",
  date: {
    start: "",
    end: "",
  },
  time: null,
  location: null,
  image: [],
  summary: "",
  tags: [],
  type: {
    id: 0,
    name: "",
  },
  registration: null,
  text: "",
  program: null,
  speakers: null,
  organizers: null,
  partners: null,
};
