import { NewDetailItem, PhotoGallery } from "models";

export const defaulrNewDetail: NewDetailItem = {
  id: 0,
  title: "",
  date: "",
  image: [],
  tags: [],
  summary: "",
  text: "",
  sources: [],
  author: {
    id: 0,
    name: "",
    description: "",
    short_name: "",
    photo: "",
    email: "",
    position: "",
    work: "",
  },
};

export const defaultPhotoGallery: PhotoGallery = {
  id: 0,
  title: "",
  date: "",
  image: [],
};
