import { FC, Fragment, useEffect } from 'react'
import SectionTitle from 'components/common/SectionTitle'
import Breadcrumb from 'components/common/Breadcrumb'
import { useNewsStyles } from './News.styles'
import { Box, Grid } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import Paging from 'components/common/Paging'
import { scrollTopPage } from 'utils'
import EmptyFilterResult from 'components/common/StubsAndBugs/EmptyFilterResult'
import { newsService } from 'services/news/news.service'
import Spiner from 'components/common/Spiner'
import { useLanguage } from 'context/Translation'
import { NewsCard } from 'components/common/Cards/News'

const NewsPage: FC = () => {
  const { news, paging, loading } = newsService
  const { language } = useLanguage()

  const classes = useNewsStyles()
  useEffect(() => {
    newsService.fetchNews(language)
  }, [language])

  const handlePageChange = (page: number) => {
    newsService.fetchPagingNews(page)
    scrollTopPage()
  }

  return (
    <Fragment>
      <SectionTitle isShown={false} />
      <Breadcrumb />

      {loading ? (
        <Spiner />
      ) : (
        <Box className={classes.container}>
          <Box>
            <Grid container spacing={2} className={classes.contentContainer}>
              {news && news.length ? (
                news.map((item, idx) => (
                  <Grid item xs={12} key={idx}>
                    <NewsCard news={item} />
                  </Grid>
                ))
              ) : (
                <EmptyFilterResult />
              )}
            </Grid>
            <Paging paging={paging} onChange={handlePageChange} />
          </Box>
        </Box>
      )}
    </Fragment>
  )
}

export default observer(NewsPage)
