import { makeAutoObservable, runInAction } from 'mobx'
import axios from 'axios'
import { ResearchListItem, ResearchItem } from 'models'
import { defaultPaging } from 'enums'
import { format } from 'date-fns'
import { defaultDetail } from './analytics.data'
import { returnIds } from 'utils'

const urlResearch = '/api/analytics'
class AnalyticsService {
  public researchList: ResearchListItem[] = []
  public detail: ResearchItem = defaultDetail
  public paging = defaultPaging
  public commentsNumber = 0
  private filter = {}
  public lang = ''
  public loading = false
  public isOpenMessageModal = false

  constructor() {
    makeAutoObservable(this)
  }

  public resetData = () => {
    this.researchList = []
  }

  public closeMessageModal = () => {
    this.isOpenMessageModal = false
  }

  async fetchResearchList() {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        // lang: language,
        ...defaultPaging
      }
      const result = await axios.get(urlResearch, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        this.researchList = data
        // this.lang = language
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  private parseFilters = (filters) => {
    const blockIds = filters.block ? returnIds(filters.block) : null
    const typeIds = filters.type ? returnIds(filters.type) : null
    return {
      block: blockIds,
      type: typeIds,
      author: filters.author.length ? filters.author : null,
      title: filters.title.length ? filters.title : null,
      text: filters.text.length ? filters.text : null,
      date: filters.date ? format(filters.date, 'yyyy-MM-dd') : null
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async fetchSearchList(filters: object) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        // lang: language,
        ...defaultPaging,
        ...this.parseFilters(filters)
      }
      const result = await axios.get(urlResearch, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        this.researchList = data
        // this.lang = language
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
        this.filter = this.parseFilters(filters)
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchResearchDetail(language: string, id: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(`${urlResearch}/${id}`, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.detail = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchPagingResearch(newPage: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: this.lang,
        ...this.filter,
        ...this.paging,
        page: newPage
      }
      const result = await axios.get(urlResearch, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.researchList = data
        this.paging = { ...this.paging, page: newPage }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  public cleanPage = () => {
    this.researchList = []
    this.detail = defaultDetail
    this.paging = defaultPaging
  }
  public cleanDetail = () => {
    this.detail = defaultDetail
  }
}

export const analyticsService = new AnalyticsService()
