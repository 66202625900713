import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useNewsChapterStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: (isSmall) => ({
      minWidth: isSmall ? 0 : 330,
      maxWidth: isSmall ? 340 : 750,
      border: `1px solid ${theme.palette.grey[100]}`,
      boxShadow: 'none',
      background: theme.palette.common.white
    }),
    media: (isSmall) => ({
      height: isSmall ? 130 : 350,
      display: 'flex',
      justifyContent: 'start'
    }),
    clearButtonPosition: {
      borderRadius: ' 4px 4px 0px 0px',
      margin: '0px 0px',
      padding: '6px 16px',
      fontSize: 18,
      color: theme.palette.secondary.main,
      backgroundColor: 'rgba(255, 255, 255, 0.8)'
    },
    cardContent: (isSmall) => ({
      '&.MuiCardContent-root:last-child': {
        paddingBottom: 5
      },
      height: isSmall ? 136 : 200,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column'
    }),
    title: {
      fontSize: 14,
      color: theme.palette.text.secondary,
      lineHeight: '19px',
      fontWeight: 700,
      [theme.breakpoints.down(1025)]: {
        overflow: 'hidden',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical'
      }
    },
    subscription: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      lineHeight: '16px',
      marginTop: 10,
      height: 110,
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 7,
      '-webkit-box-orient': 'vertical',
      [theme.breakpoints.down(1025)]: {
        height: 48,
        '-webkit-line-clamp': 3
      }
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.success.main,
      fontSize: 14
    }
  })
)

export const useNewsCardStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    height: 177,
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    },
    [theme.breakpoints.down(768)]: {
      height: 205
    },
    [theme.breakpoints.down(480)]: {
      display: 'none'
    }
  },
  imgContainer: {
    height: 175,
    minWidth: 162,
    width: 300,
    position: 'relative',
    [theme.breakpoints.down(768)]: {
      height: 203,
      minWidth: 200
    },
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: 175,
      borderRadius: '5px 0 0 5px',
      [theme.breakpoints.down(768)]: {
        height: 203
      },
      [theme.breakpoints.down(500)]: {
        objectFit: 'cover'
      }
    }
  },
  title: {
    fontSize: 17,
    lineHeight: '25px',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(876)]: {
      fontSize: 15
    }
  },
  cropHeight: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.text.secondary
  },
  longDescription: {
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2
  },
  date: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.secondary.main
  },
  loadingInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: 10
  },
  bodyContainer: {
    height: 177,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down(768)]: {
      height: 205
    },
    width: '100%'
  },

  infoContainer: {
    display: 'flex',
    justifyContent: 'inherit'
  },
  titleContainer: {
    lineHeight: '25px',
    fontWeight: 700,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  about: {
    flexBasis: '15%',
    padding: '20px',
    fontSize: 14,
    lineHeight: '19px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(768)]: {
      display: 'none'
    }
  },
  dateOnImage: {
    borderRadius: ' 4px 4px 0px 0px',
    margin: 0,
    padding: '6px 16px',
    fontSize: 18,
    color: theme.palette.secondary.main,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    position: 'absolute',
    bottom: 0,
    display: 'none',
    [theme.breakpoints.down(768)]: {
      display: 'block'
    }
  }
}))

export const useMobileNewsCardStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    boxShadow: 'none',
    flexWrap: 'wrap',
    [theme.breakpoints.up(480)]: {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  },
  title: {
    lineHeight: '25px',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    fontSize: 15
  },
  cropHeight: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  date: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.secondary.main
  },
  loadingInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: 10
  },
  imgContainer: {
    maxHeight: '224px',
    padding: 0,
    display: 'flex',
    justifyContent: 'start',
    position: 'relative',
    flexBasis: '100%',
    order: 1,
    '& img': {
      // width: '100%',
      // borderRadius: '0 0 5px 5px',
      // height: 150,
      objectFit: 'cover',
      width: '100%',
      height: 175,
      borderRadius: '5px 0 0 5px',
      [theme.breakpoints.down(768)]: {
        height: 150
      },
      [theme.breakpoints.down(500)]: {
        objectFit: 'cover'
      },
      '& img': {
        objectFit: 'cover',
        width: '100%',
        height: 175,
        borderRadius: '5px 0 0 5px',
        [theme.breakpoints.down(768)]: {
          height: 203
        },
        [theme.breakpoints.down(500)]: {
          objectFit: 'cover'
        }
      }
    }
  },
  bodyContainer: {
    padding: '15px 12px',
    flexBasis: '80%',
    position: 'relative',
    [theme.breakpoints.down(768)]: {
      flexBasis: '75%'
    },
    [theme.breakpoints.down(480)]: {
      flexBasis: '100%',
      order: 0
    }
  },
  titleContainer: {
    fontSize: 18,
    lineHeight: '25px',
    fontWeight: 700
  },
  about: {
    flexBasis: '15%',
    padding: '20px 0',
    fontSize: 14,
    lineHeight: '19px',
    textAlign: 'center',
    [theme.breakpoints.down(768)]: {
      display: 'none'
    }
  },
  titleDesContainer: {
    flexBasis: '100%',
    fontSize: '14px'
  },
  dateOnImage: {
    borderRadius: ' 4px 4px 0px 0px',
    margin: 0,
    padding: '6px 16px',
    fontSize: 18,
    color: theme.palette.secondary.main,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    position: 'absolute',
    bottom: 0,
    display: 'block'
  }
}))
