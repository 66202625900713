import { TabItem } from "components/common/TabsPanel/TabsPanel.types";

export const allObjectTabs: TabItem[] = [
  {
    lable: "news",
  },
  {
    lable: "analytics",
  },

  {
    lable: "events",
  },
  {
    lable: "legal-base",
  },
];

export const mediacenterTabs: TabItem[] = [
  {
    lable: "news",
  },
  {
    lable: "photos",
  },
  {
    lable: "videos",
  },
];
