import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import getOrigin from "utils/getOrigin";
import { defaultPaging } from "enums";
import {
  AutorItem,
  DocumentListItem,
  EventListItem,
  NewListItem,
  ResearchListItem,
} from "models";
import { TabItem } from "components/common/TabsPanel/TabsPanel.types";
import { returnPanel } from "utils";

const urlSearch = getOrigin() + "api/search";
const urlAnalytics = getOrigin() + "api/research/list";
const urlNews = getOrigin() + "api/news/list";
const urlDocuments = getOrigin() + "api/document/list";
const urlEvents = getOrigin() + "api/event/list";
const urlAuthors = getOrigin() + "api/authors";
class SearchService {
  public paging = defaultPaging;
  public newList: NewListItem[] = [];
  public analyticList: ResearchListItem[] = [];
  public eventList: EventListItem[] = [];
  public documentList: DocumentListItem[] = [];
  public rusAuthors: AutorItem[] = [];
  public engAuthors: AutorItem[] = [];
  public tabs: TabItem[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public panels: any[] = [];
  public lang = "";
  private key = "";
  private totals = {
    news: 0,
    analytics: 0,
    events: 0,
    "legal-base": 0,
    authors: 0,
  };
  public loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async fetchSearchInfo(language: string, text: string) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: language,
        text,
        ...this.paging,
      };
      const result = await axios.get(urlSearch, { params });
      if (result.status !== 200) {
        return console.log("result tag", result);
      }
      runInAction(() => {
        const { news, researches, events, documents, authors } =
          result.data.data;
        this.generateTabs(result.data.data);
        this.newList = news.data;
        this.analyticList = researches.data;
        this.eventList = events.data;
        this.documentList = documents.data;
        this.rusAuthors = authors.local.data;
        this.engAuthors = authors.foreign.data;
        this.key = text;
        this.lang = language;
        this.totals = {
          news: news.total,
          analytics: researches.total,
          events: events.total,
          "legal-base": documents.total,
          authors:
            authors.local.tottal > authors.foreign.total
              ? authors.local.tottal
              : authors.foreign.total,
        };
        this.paging = {
          ...this.paging,
          count: Math.ceil(
            this.returnTotal(result.data.data, this.tabs[0]) /
              defaultPaging.psize
          ),
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  public setTab = (newActiveTab: number) => {
    this.paging = {
      ...defaultPaging,
      count: Math.ceil(
        this.totals[this.tabs[newActiveTab].lable] / defaultPaging.psize
      ),
    };
  };

  private returnTotal = (data, tab) => {
    switch (tab) {
      case "news":
        return data.news.total;
      case "analytics":
        return data.researches.total;
      case "events":
        return data.events.total;
      case "legal-base":
        return data.documents.total;
      case "authors":
        return data.authors.local.total > data.authors.foreign.total
          ? data.authors.local.total
          : data.authors.foreign.total;
      default:
        return 0;
    }
  };

  async fetchPagingAnalytics(language: string, newPage: number) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: language,
        text: this.key,
        ...this.paging,
        page: newPage,
      };
      const result = await axios.get(urlAnalytics, { params });
      if (result.status !== 200) {
        return console.log("result tag", result);
      }
      runInAction(() => {
        this.analyticList = result.data.data;
        this.lang = language;
        this.paging = { ...this.paging, page: newPage };
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  async fetchPagingNews(language: string, newPage: number) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: language,
        text: this.key,
        ...this.paging,
        page: newPage,
      };
      const result = await axios.get(urlNews, { params });
      if (result.status !== 200) {
        return console.log("result tag", result);
      }
      runInAction(() => {
        this.newList = result.data.data;
        this.lang = language;
        this.paging = { ...this.paging, page: newPage };
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  async fetchPagingLegalBase(language: string, newPage: number) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: language,
        text: this.key,
        ...this.paging,
        page: newPage,
      };
      const result = await axios.get(urlDocuments, { params });
      if (result.status !== 200) {
        return console.log("result tag", result);
      }
      runInAction(() => {
        this.documentList = result.data.data;
        this.lang = language;
        this.paging = { ...this.paging, page: newPage };
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  async fetchPagingEvents(language: string, newPage: number) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: language,
        text: this.key,
        ...this.paging,
        page: newPage,
      };
      const result = await axios.get(urlEvents, { params });
      if (result.status !== 200) {
        return console.log("result tag", result);
      }
      runInAction(() => {
        this.eventList = result.data.data;
        this.lang = language;
        this.paging = { ...this.paging, page: newPage };
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  async fetchAuthorsPaging(language: string, newPage: number) {
    try {
      runInAction(() => (this.loading = true));
      const rusParams = {
        lang: language,
        foreign: 0,
        name: this.key,
        ...this.paging,
        page: newPage,
      };
      const engParams = {
        lang: language,
        foreign: 1,
        name: this.key,
        ...this.paging,
        page: newPage,
      };
      const rusResult = await axios.get(urlAuthors, { params: rusParams });
      const engResult = await axios.get(urlAuthors, { params: engParams });
      if (rusResult.status !== 200 || engResult.status !== 200) {
        console.log("rusResult", rusResult);
        console.log("engResult", engResult);
        return;
      }
      runInAction(() => {
        this.rusAuthors = rusResult.data.data.data;
        this.engAuthors = engResult.data.data.data;
        this.paging = { ...this.paging, page: newPage };
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  private returnTabName = (dataName: string): string => {
    switch (dataName) {
      case "news":
        return "news";
      case "researches":
        return "analytics";
      case "events":
        return "events";
      case "documents":
        return "legal-base";
      default:
        return "";
    }
  };

  private generateTabs = (data) => {
    const newTabs: TabItem[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newPanels: any[] = [];
    for (const item in data) {
      if (
        item === "authors" &&
        (data[item].local.data.length || data[item].foreign.data.length)
      ) {
        newTabs.push({ lable: "authors" });
        const panel = returnPanel("authors");
        newPanels.push(panel);
        continue;
      }
      if (data[item].data?.length) {
        newTabs.push({ lable: this.returnTabName(item.toString()) });
        const panel = returnPanel(item.toString());
        newPanels.push(panel);
      }
    }
    this.tabs = [...newTabs];
    this.panels = [...newPanels];
  };
}

export const searchService = new SearchService();
