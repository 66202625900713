import { FC, Fragment, useEffect, useState } from "react";
import { getParameterFromUrl } from "utils";
import { useLanguage } from "context/Translation";
import { observer } from "mobx-react-lite";
import { Box } from "@material-ui/core";
import { searchService } from "services/search/search.service";
import Breadcrumb from "components/common/Breadcrumb";
import { useSearchPageStyles } from "./Search.styles";
import { useTranslation } from "react-i18next";
import TabsPanel from "components/common/TabsPanel";
import Spiner from "components/common/Spiner";

const SearchPage: FC = () => {
  const classes = useSearchPageStyles();
  const { loading, panels, tabs } = searchService;
  const { language } = useLanguage();
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    searchService.fetchSearchInfo(language, getParameterFromUrl("key") ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const handleEditTab = (newTab: number) => {
    setActiveTab(newTab);
    searchService.setTab(newTab);
  };

  if (loading) {
    return (
      <Fragment>
        <Breadcrumb />
        <Box className={classes.container}>
          <Spiner />
        </Box>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Breadcrumb />
      <Box className={classes.container}>
        <div className={classes.title}>
          {t("search-results-for")} : {getParameterFromUrl("key")}
        </div>
        <TabsPanel
          tabs={tabs}
          panels={panels}
          activeTab={activeTab}
          setActiveTab={handleEditTab}
        />
      </Box>
    </Fragment>
  );
};

export default observer(SearchPage);
