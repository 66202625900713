import { makeStyles } from "@material-ui/core";

const useTabPanelStyles = makeStyles((theme) => ({
  indicator: {
    height: 14,
    width: 11,
    display: "flex",
    justifyContent: "center",
    color: theme.palette.secondary.main,
    backgroundColor: "rgba(255,255,255,0)",
  },
  spanIndicator: {
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.success.main}`,
  },
  fixed: {
    width: 0,
  },
  flexContainer: {
    justifyContent: "center",
  },
  tab: {
    borderBottom: `1px solid ${theme.palette.success.main}`,
    color: theme.palette.success.main,
    fontSize: 12,
    maxWidth: 72,
    padding: "6px 12px",
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.up("mobileVertical")]: {
      fontSize: 9,
      padding: "6px 6px",
    },
    [theme.breakpoints.up("mobileHorisontal")]: {
      fontSize: 12,
      maxWidth: "100%",
      padding: "6px 6px",
    },
    [theme.breakpoints.up("tablet")]: {
      fontSize: 14,
      maxWidth: "100%",
      padding: "6px 12px",
    },
  },
  panelMargin: {
    marginTop: 25,
  },
}));

export default useTabPanelStyles;
