import { FC, Fragment } from "react";
import Footer from "components/common/Footer";
import SiteMapPage from "components/SiteMap";

const SiteMap: FC = () => (
  <Fragment>
    <SiteMapPage />
    <Footer />
  </Fragment>
);

export default SiteMap;
