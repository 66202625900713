import { FC, Fragment, useEffect } from 'react'
import SectionTitle from 'components/common/SectionTitle'
import Breadcrumb from 'components/common/Breadcrumb'
import { useDetailNewsStyles } from './Analtyics.styles'
import { Box, Grid } from '@material-ui/core'
import { Event } from '@material-ui/icons'
import { getUrlAdress } from 'utils'
import { useLanguage } from 'context/Translation'
import { observer } from 'mobx-react-lite'
import { format } from 'date-fns'
import HtmlElement from 'components/common/HtmlElement'
import Line from 'components/common/Line'
import Tag from 'components/common/Tag'
import Spiner from 'components/common/Spiner'
import { analyticsService } from 'services/analytics/analytics.service'

const AnalyticsDetail: FC = () => {
  const classes = useDetailNewsStyles()
  const url = getUrlAdress(window.location.pathname)
  const id = url[url.length - 1].name
  const { language } = useLanguage()
  const { detail, loading } = analyticsService

  useEffect(() => {
    if (id === null) return
    if (detail.id !== Number(id)) {
      analyticsService.fetchResearchDetail(language, Number(id))
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, id])

  useEffect(() => {
    return function cleanupPage() {
      analyticsService.cleanDetail()
    }
  }, [])

  if (loading) {
    return (
      <Fragment>
        <SectionTitle isShown background={'sanction'} title={detail.title} />
        <Box className={classes.container}>
          <Spiner />
        </Box>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <SectionTitle background="sanction" title={detail.title} />
      <Breadcrumb title={detail.title} />
      <Box className={classes.container}>
        <Box className={classes.title}>{detail.title}</Box>
        <Grid container spacing={4} direction="column">
          <Grid item xs container spacing={2}>
            <Grid item xs>
              <Box className={`${classes.text} ${classes.textMargin}`}>
                <div className={classes.iconTextdiv}>
                  <Event />
                  <p>
                    {detail.date
                      ? format(new Date(detail.date), 'dd.MM.yyyy')
                      : null}{' '}
                  </p>
                </div>
              </Box>

              <HtmlElement
                component="div"
                classes={`${classes.text} ${classes.textMargin}`}
                html={detail.text}
              />
            </Grid>
            <Grid item>
              <Box
                width={350}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                className={classes.imgBox}
              >
                <img
                  className={classes.img}
                  src={detail.image[0].full}
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>

          {detail.tags ? (
            <Grid item xs>
              <Line />
              <Box pt={'30px'} style={{ display: 'flex' }}>
                {detail.tags.map((tag) => (
                  <Tag key={tag.id} id={tag.id} title={tag.name} marginRight />
                ))}
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Fragment>
  )
}

export default observer(AnalyticsDetail)
