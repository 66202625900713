import { Box } from "@material-ui/core";
import { FC } from "react";
import error from "assets/image/404.png";
import { useEmptyFilterResultStyle } from "./StubsAndBugs.style";
import { useTranslation } from "react-i18next";

const EmptyFilterResult: FC = () => {
  const classes = useEmptyFilterResultStyle();
  const { t } = useTranslation();
  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.head}>{t("nothing-request")}</Box>
        <Box className={classes.message}>{t("change-parameters")}</Box>
      </Box>
      <Box>
        <img src={error} width={287} alt="" />
      </Box>
    </Box>
  );
};

export default EmptyFilterResult;
