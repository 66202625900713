import { ResearchItem } from 'models'

export const defaultDetail: ResearchItem = {
  id: 0,
  title: '',
  summary: '',
  tags: [],
  date: '',
  text: '',
  image: [
    {
      full: '',
      preview: ''
    }
  ]
}
