import { makeStyles, Theme } from '@material-ui/core'

export const useLoginStyles = makeStyles<Theme>((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '55rem'
  },
  sectionHead: {
    width: '100%',
    height: 90,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleHead: {
    fontSize: 20,
    color: theme.palette.secondary.main
  },
  forgotPass: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(400)]: {
      flexDirection: 'column'
    }
  },
  already: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 0'
  },
  forgotText: {
    fontSize: 20,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    [theme.breakpoints.down(550)]: {
      fontSize: 14
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 18,
      marginTop: 10
    }
  },
  alert: {
    color: 'red'
  },
  form: {
    border: `1px solid ${theme.palette.secondary.main}`,
    maxWidth: '90%',
    height: 620,
    borderRadius: `20px 20px 5px 5px `,
    padding: '20px',
    position: 'relative'
  },
  registerForm: {
    border: `1px solid ${theme.palette.secondary.main}`,
    width: 840,
    height: 780,
    borderRadius: `20px 20px 5px 5px `,
    padding: '20px',
    position: 'relative'
  },
  formik: {
    height: 'fit-content'
  },
  container: {
    // padding: "25px",
    // height: "100%",
  },
  errorMessage: {
    color: 'red'
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: 20,
    marginBottom: 15
  },
  field: {
    width: '100%',
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& input': {
      width: '100%',
      height: 50,
      borderRadius: 5,
      border: `1px solid ${theme.palette.grey[300]}`,
      fontSize: 20,
      padding: 10
    }
  },
  signUpField: {
    width: '100%',
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& input': {
      width: '100%',
      height: 50,
      borderRadius: 5,
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: 10
    }
  },
  registerTitle: {
    width: '100%',
    textAlign: 'center',
    margin: '10px 0',
    fontSize: 32,
    color: '#000'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    background: theme.palette.primary.main,
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 70
  },
  button: {
    color: theme.palette.secondary.main,
    fontSize: 28,
    fontWeight: 600,
    width: '100%',
    transition: 'color 500ms',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main
    },
    textTransform: 'capitalize',
    [theme.breakpoints.down(400)]: {
      fontSize: 24
    }
  }
}))
