import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { routers } from 'routers'
import Footer from 'components/common/Footer'
import RegisterForm from 'components/Register/Register'
interface Props {
  username: string
  password: string
  email: string
}

const Register: React.FC<Props> = () => {
  return (
    <>
      <Fragment>
        <Switch>
          <Route exact path={routers.register}>
            <RegisterForm />
          </Route>
        </Switch>
        <Footer />
      </Fragment>
    </>
  )
}

export default Register
