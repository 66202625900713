import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useColumnStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      borderBottom: `1px solid ${theme.palette.success.main}`,
      color: theme.palette.success.main,
      marginTop: "20px",
    },
    columnTitle: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderTop: "5px",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "19px",
      padding: 10,
      borderRadius: "5px 5px 0px 0px",
      textTransform: "uppercase",
    },
    column: {
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 5,
    },
    columnCell: {
      padding: "20px 20px 0px 20px",
      "&:hover": {
        backgroundColor: theme.palette.grey[50],
      },
    },
  })
);
