import { FC, Fragment, useState } from 'react'
import SectionTitle from 'components/common/SectionTitle'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

// import EventsCard from "components/common/Cards/Events/EventsCard";
import { observer } from 'mobx-react-lite'
import './styles.scss'
import { Login } from 'services/login/auth.service'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { useLoginStyles } from './Login.styles'
import { useHistory } from 'react-router-dom'
import { scrollTopPage } from 'utils'
const Loginform: FC = () => {
  const classes = useLoginStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const history = useHistory()
  const initialValues: {
    username: string
    password: string
  } = {
    username: '',
    password: ''
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('User Name is required!'),
    password: Yup.string().required('password is required!')
  })
  const handleLogin = (formValue: { username: string; password: string }) => {
    const { username, password } = formValue
    scrollTopPage()
    Login(username, password).then(
      (response) => {
        const user = response.data.user
        const req_status = response.status
        localStorage.setItem('authorizeStatus', req_status)
        if (req_status === 3) {
          setErrorMessage('wrong credentials , please try again')
          history.push('/login')
        }
        if (response.data) {
          const expiredToken = response.data.expire
          localStorage.setItem('expired', expiredToken)
          localStorage.setItem('user_name', user.name)
          if (user.permissions.includes('view sanction entity')) {
            localStorage.setItem('have_permission', 'true')
            if (req_status === 0) {
              localStorage.setItem('token', response.data.token)
              history.push('/sanctions')
            }
          } else {
            localStorage.setItem('have_permission', 'false')
            history.push('/home')
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()

        setLoading(false)
        setMessage(resMessage)
      }
    )
  }

  return (
    <Fragment>
      <SectionTitle background="sanction" isShown={false} />
      <Box className={classes.card}>
        <Box className={classes.form}>
          <Box className={classes.sectionHead}>
            <Typography className={classes.titleHead}>
              SanctionsRuBase+
            </Typography>
          </Box>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form className={classes.formik}>
              {errorMessage && (
                <Grid item xs={12}>
                  <Box className={classes.errorBox}>
                    <Typography className={classes.errorMessage}>
                      {errorMessage}
                    </Typography>
                  </Box>
                </Grid>
              )}

              <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.field}>
                  <label className={classes.label} htmlFor="username">
                    Имя пользователя
                  </label>
                  <Field name="username" type="text" className="form-control" />
                  <ErrorMessage
                    name="username"
                    component="Box"
                    className={classes.alert}
                  />
                </Grid>

                <Grid item xs={12} className={classes.field}>
                  <label className={classes.label} htmlFor="password">
                    Пароль
                  </label>
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="password"
                    component="Box"
                    className={classes.alert}
                  />
                </Grid>

                {message && (
                  <Box className="form-group">
                    <Box className={classes.alert} role="alert">
                      {message}
                    </Box>
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} className={classes.forgotPass}>
                <Typography
                  className={classes.forgotText}
                  onClick={() => {
                    history.push('/register')
                  }}
                >
                  Зарегистрировать новый аккаунт
                </Typography>

                <Typography
                  className={classes.forgotText}
                  onClick={() => {
                    history.push('/reset-password')
                  }}
                >
                  Забыли пароль?
                </Typography>
              </Grid>

              <Box className={classes.buttonBox}>
                <Button
                  type="submit"
                  className={classes.button}
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                  {'Войти'}
                </Button>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Box>
    </Fragment>
  )
}

export default observer(Loginform)
