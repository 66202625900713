import { FC, Fragment, useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useTabPanelStyles from "./TabsPanel.styles";
import TabPanel from "./Panel";
import { TabsPanelProps } from "./TabsPanel.types";
import { getParameterFromUrl, getUrlAdress } from "utils";
import { useHistory, useLocation } from "react-router-dom";
import EmptyFilterResult from "../StubsAndBugs/EmptyFilterResult";
import { routers } from "routers";

const TabsPanel: FC<TabsPanelProps> = ({
  tabs,
  panels,
  activeTab,
  setActiveTab,
  isBigMargin,
}) => {
  const classes = useTabPanelStyles();
  const [value, setValue] = useState(activeTab ?? 0);
  useEffect(() => {
    setValue(activeTab ?? 0);
  }, [activeTab]);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const handleChange = (_, newValue: number) => {
    setValue(newValue);
    setActiveTab && setActiveTab(newValue);
    const isNotLinkTab =
      !getUrlAdress(location.pathname).length ||
      location.pathname.indexOf("about") !== -1 ||
      location.pathname.indexOf("search-by-key") !== -1;

    if (isNotLinkTab) {
      return;
    }

    const isLink = getUrlAdress(location.pathname).some(
      (item) => item.link !== ""
    );
    const isTagsPage = getUrlAdress(location.pathname)[0].link.includes(
      routers.tags
    );

    if (isTagsPage) {
      const tagId = Number(getParameterFromUrl("tagId"));
      history.push(
        `${routers.tags}?tagId=${tagId}&tab=${tabs[newValue].lable}`
      );
      return;
    }

    if (isLink) {
      const url = getUrlAdress(location.pathname)[0].link;
      history.push(`${url}/${tabs[newValue].lable}`);
    }
  };

  if (!panels.length || !tabs.length) {
    return <EmptyFilterResult />;
  }

  return (
    <Fragment>
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        classes={{
          indicator: classes.indicator,
          fixed: classes.fixed,
          flexContainer: classes.flexContainer,
        }}
        TabIndicatorProps={{
          children: <span className={classes.spanIndicator} />,
        }}
      >
        {tabs.map(({ lable, disabled }, index) => (
          <Tab
            key={index}
            label={t(lable)}
            value={index}
            className={classes.tab}
            selected={value === index}
            disabled={disabled}
          />
        ))}
      </Tabs>
      {panels.map((panel, index) => (
        <TabPanel
          key={index}
          value={value}
          index={index}
          isBigMargin={isBigMargin}
        >
          <div>{panel}</div>
        </TabPanel>
      ))}
    </Fragment>
  );
};

export default TabsPanel;
