import { makeStyles, Theme } from "@material-ui/core";

export const useBreadcrumbStyles = makeStyles((theme: Theme) => ({
  navigate: {
    color: theme.palette.success.main,
  },
  container: {
    height: 50,
    backgroundColor: theme.palette.common.white,
    padding: "0px 5%",
    display: "flex",
    alignItems: "center",
    "& .MuiBreadcrumbs-ol": {
      flexWrap: "inherit",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  link: {
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
