import { Box } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const ScreenFormat: FC = () => {
  const { t } = useTranslation();

  return (
    <Box style={{ width: 200, margin: "50vw auto", textAlign: "center" }}>
      {t("displayNotSupported")}
    </Box>
  );
};

export default ScreenFormat;
