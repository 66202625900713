import { FC } from 'react'
import AutocompleteFilter from 'components/common/AutocompleteFilter'
import { observer } from 'mobx-react-lite'
import { FilterCombinedProps, FilterOptions } from 'models/filters'
import { filtersService } from 'services/filters/filters.service'

const FieldFilter: FC<FilterCombinedProps<FilterOptions>> = ({
  onChange,
  checkedValues
}) => {
  const { domainType, loading } = filtersService

  const handleChange = (value) => {
    console.log(value, 'value')

    onChange({ ...checkedValues, domain: value })
  }

  const handleOpen = () => {
    filtersService.fetchDomainTypes()
  }

  return (
    <AutocompleteFilter
      options={domainType}
      loading={loading}
      label="Сфера"
      checkedValues={checkedValues.domain}
      optionLabelField="name"
      optionSelectedFieldKey="id"
      onOpen={handleOpen}
      onChange={handleChange}
      hasAll
    />
  )
}

export default observer(FieldFilter)
