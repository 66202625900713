import { useMediaQuery } from "@material-ui/core";
import { useState } from "react";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
// import { generalService } from "services/general/general.service";

export const FooterHelper = (): typeof FooterHelper => {
  const [email, setEmail] = useState("");
  const isMobile = useMediaQuery("(max-width:768px)");
  const handleChangeEmail = ({ target: { value } }) => {
    setEmail(value);
  };

  // const { executeRecaptcha } = useGoogleReCaptcha();

  const [openOkMessage, setOpenOkMessage] = useState(false);
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const [openInvalidFormat, setOpenInvalidFormat] = useState(false);

  // const handleOpenOkMessage = () => setOpenOkMessage(true);
  // const handleOpenErrorMessage = () => setOpenErrorMessage(true);
  // const handleOpenInvalidFormat = () => setOpenInvalidFormat(true);

  const handleCloseOkMessage = () => setOpenOkMessage(false);
  const handleCloseErrorMessage = () => setOpenErrorMessage(false);
  const handleCloseInvalidFormat = () => setOpenInvalidFormat(false);

  // const handleSubmitClick = () => {
  //   if (email.length) {
  //     try {
  //       executeRecaptcha &&
  //         executeRecaptcha("submit").then((result) => {
  //           generalService.submit(
  //             email,
  //             result,
  //             handleOpenOkMessage,
  //             handleOpenErrorMessage,
  //             handleOpenInvalidFormat
  //           );
  //         });
  //     } catch (err) {
  //       throw new Error("Token error");
  //     }
  //   }
  // };

  return {
    email,
    isMobile,
    handleChangeEmail,
    // handleSubmitClick,
    openOkMessage,
    openErrorMessage,
    openInvalidFormat,
    handleCloseOkMessage,
    handleCloseErrorMessage,
    handleCloseInvalidFormat,
  };
};
