import { FC } from "react";
import AutocompleteFilter from "components/common/AutocompleteFilter";
import { FilterCombinedProps, FilterOptions } from "models/filters";
import { filtersService } from "services/filters/filters.service";
import { observer } from "mobx-react-lite";
import { useLanguage } from "context/Translation";

const DocumentFilter: FC<FilterCombinedProps<FilterOptions>> = ({
  onChange,
  checkedValues,
}) => {
  const { documentType, loading } = filtersService;
  const { language } = useLanguage();
  const handleChange = (value) => {
    onChange({ ...checkedValues, documentType: value });
  };

  const handleOpen = () => {
    filtersService.fetchDocumentTypes(language);
  };

  return (
    <AutocompleteFilter
      options={documentType}
      loading={loading}
      label="act"
      checkedValues={checkedValues.documentType}
      optionLabelField="name"
      optionSelectedFieldKey="id"
      onOpen={handleOpen}
      onChange={handleChange}
      hasAll
    />
  );
};

export default observer(DocumentFilter);
