import { FC, Fragment } from "react";
import Footer from "components/common/Footer";
import TermsMaterialsPage from "components/TermsMaterials";

const TermsMaterials: FC = () => (
  <Fragment>
    <TermsMaterialsPage />
    <Footer />
  </Fragment>
);

export default TermsMaterials;
