import { FC } from "react";
import { SingleAQProps } from "./FAQ.types";
import { useFAQStyles } from "./FAQ.styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import HtmlElement from "components/common/HtmlElement";

const SingleAQ: FC<SingleAQProps> = ({
  ariaControls,
  id,
  question,
  answer,
}) => {
  const classes = useFAQStyles();

  return (
    <Grid item xs={12}>
      <Accordion className={classes.acordionBorder}>
        <AccordionSummary
          expandIcon={<ExpandMore fontSize={"large"} />}
          aria-controls={ariaControls}
          id={id}
        >
          <Typography className={classes.heading}>{question}</Typography>
        </AccordionSummary>
        <hr className="line" />
        <AccordionDetails>
          <HtmlElement component="div" classes={classes.answer} html={answer} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default SingleAQ;
