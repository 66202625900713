import { FC } from "react";
import { Box, Grid } from "@material-ui/core";
import { NewsCard } from "components/common/Cards/News";
import { observer } from "mobx-react-lite";
import { scrollTopPage } from "utils";
import Paging from "components/common/Paging";
import { searchService } from "services/search/search.service";
import EmptyFilterResult from "components/common/StubsAndBugs/EmptyFilterResult";
import Spiner from "components/common/Spiner";
import { useLanguage } from "context/Translation";

const NewsTab: FC = () => {
  const { newList, paging, loading } = searchService;
  const { language } = useLanguage();
  const handlePageChange = (page: number) => {
    searchService.fetchPagingNews(language, page);
    scrollTopPage();
  };

  if (loading) {
    return <Spiner />;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {newList.length ? (
          newList.map((item) => (
            <Grid item xs={12} key={item.id}>
              <NewsCard news={item} />
            </Grid>
          ))
        ) : (
          <EmptyFilterResult />
        )}
      </Grid>
      <Paging paging={paging} onChange={handlePageChange} />
    </Box>
  );
};

export default observer(NewsTab);
