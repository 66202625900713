import { FC } from "react";
import { Typography } from "@material-ui/core";
import { useTextStyles } from "./Text.styles";

const SectionHeading: FC = ({ children }) => {
  const classes = useTextStyles();
  return <Typography className={classes.sectionHeading}>{children}</Typography>;
};

export default SectionHeading;
