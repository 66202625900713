import { makeStyles, Theme } from '@material-ui/core'
import planet from 'assets/image/planet.jpg'
import earth from 'assets/image/earth.jpg'
import book from 'assets/image/titles/book.jpg'
import analityc from 'assets/image/analityc.jpg'
import water from 'assets/image/water.jpg'
import sanction from 'assets/image/sectionImage.png'

export const useSectionTitleStyles = makeStyles((theme: Theme) => ({
  section: {
    background: 'none',
    height: 70,
    width: '100%',
    [theme.breakpoints.up('mobileVertical')]: {
      height: 70
    },
    [theme.breakpoints.up('mobileHorisontal')]: {
      height: 80
    },
    [theme.breakpoints.up('tablet')]: {
      height: 90
    },

    [theme.breakpoints.up('laptop')]: {
      height: 300
    }
  },
  title: {
    padding: '12px 5%',
    fontSize: 14,
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    fontWeight: 700,
    display: 'flex',
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.4) 1.31%, rgba(255, 255, 255, 0) 93.64%)',
    [theme.breakpoints.up('mobileVertical')]: {
      fontSize: 14,
      padding: '12px 5%'
    },
    [theme.breakpoints.up('mobileHorisontal')]: {
      fontSize: 18,
      padding: '12px 5%'
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: 24,
      padding: '12px 5%'
    },
    [theme.breakpoints.up('tabletVertical')]: {
      fontSize: 24,
      padding: '12px 5%'
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: 40,
      padding: '25px 200px 25px 5%'
    }
  },
  planet: {
    backgroundImage: `url(${planet})`
  },
  sanction: {
    backgroundImage: `url(${sanction})`
  },
  earth: {
    backgroundImage: `url(${earth})`
  },
  book: {
    backgroundImage: `url(${book})`
  },
  analityc: {
    backgroundImage: `url(${analityc})`
  },
  water: {
    backgroundImage: `url(${water})`
  },
  headerFon: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: '100% 100%',
    marginTop: '3%',
    minHeight: 360,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(1024)]: {
      backgroundSize: 'auto 346px'
    }
  },
  childrenBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}))
