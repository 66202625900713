import { useMediaQuery } from '@material-ui/core'
// import { EventChapterCard } from "components/common/Cards";
import Chapter from 'components/common/Сhapter'
import { observer } from 'mobx-react-lite'
import { FC, Fragment } from 'react'
import { useArticlesCaruseleStyle } from './ArticlesCarusel.styles'
import ArticlesChapterCard from 'components/common/Cards/Articles/ArticlesChapterCard'
import Slider from 'react-slick'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import './carusele.scss'
import { generalService } from 'services/general/general.service'

const NewsChapter: FC = () => {
  const isScroll = useMediaQuery('(max-width:1023px)')
  const classes = useArticlesCaruseleStyle()

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <KeyboardArrowRight className={classes.icon} />
      </div>
    )
  }

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <KeyboardArrowLeft className={classes.icon} />
      </div>
    )
  }
  const minMobile = useMediaQuery('(min-width:320px)')
  const maxMobile = useMediaQuery('(max-width:880px)')
  const isMobile = minMobile && maxMobile

  const settings = {
    infinite: false,
    lazyLoad: true,
    slidesToShow: isMobile ? 1 : 2,
    centerMode: false,
    initialSlide: 0,
    dots: true,
    arrows: false,
    nextArrow: <NextArrow onClick={() => null} />,
    prevArrow: <PrevArrow onClick={() => null} />,
    slidesToScroll: isMobile ? 2 : 3,
    autoplaySpeed: 5000,
    autoplay: false
  }
  const { homeNews } = generalService
  console.log(homeNews, 'newssssssssss')

  return (
    <Chapter title="news" padding={isScroll ? '50px 0%' : '50px'}>
      <Fragment>
        <Slider {...settings}>
          {homeNews.map((item, idx) => (
            <div key={idx}>
              <ArticlesChapterCard news={item} />
            </div>
          ))}
        </Slider>
      </Fragment>
    </Chapter>
  )
}

export default observer(NewsChapter)
