import { FC, Fragment } from "react";
import Breadcrumb from "components/common/Breadcrumb";
import SectionTitle from "components/common/SectionTitle";
import PrivacyPolizyText from "./PrivacyPolicyText";
import { usePrivacyPolicyStyles } from "./PrivacyPolicy.styles";
import { Box } from "@material-ui/core";

const PrivacyPolicyPage: FC = () => {
  const classes = usePrivacyPolicyStyles();

  return (
    <Fragment>
      <SectionTitle background="water" />
      <Breadcrumb />
      <Box className={classes.bgWhite}>
        <PrivacyPolizyText />
      </Box>
    </Fragment>
  );
};

export default PrivacyPolicyPage;
