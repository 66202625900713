import {FC, Fragment} from "react";
import { Box, Card, CardContent, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useLegalBaseCardStyles } from "./Cards.styles";
import { SectionHeading } from "../Text";
import { format } from "date-fns";
import { LegalBaseCardProps } from "./Cards.types";
import { DateRange, Description, Language } from "@material-ui/icons";
import HtmlElement from "../HtmlElement";
import { scrollTopPage } from "utils";
import Tag from "../Tag";
import Line from "../Line";
import { routers } from "routers";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const LegalBaseCard: FC<LegalBaseCardProps> = ({ document }) => {
  const classes = useLegalBaseCardStyles();
  const { id, block, region, date, summary, type, title, tags } = document;

  const TagsContent = tags && tags.length ? (
      <Fragment>
          <Box marginTop={2}>
            <Line />
          </Box>
          <Grid
              item
              container
              spacing={2}
              xs={12}
              style={{ paddingTop: 10, width: "100%" }}
          >
            {tags.map((tag) => (
                <Grid item key={tag.id}>
                  <Tag key={tag.id} id={tag.id} title={tag.name} type="analytics" />
                </Grid>
            ))}
          </Grid>
      </Fragment>
  ) : null;

  return (
    <Link to={`${routers.legalBase}/${id}`} onClick={scrollTopPage}>
      <Card className={classes.container}>
        <Grid className={classes.containerHeader} container spacing={1}>
          <Grid className={classes.headerItem} item>
            <img src={block.img} alt="" />
            <div>{block.name.toLocaleUpperCase()}</div>
          </Grid>
          <Grid className={classes.headerItem} item>
            <Language className={classes.lang} />
            <div>{region.name.toLocaleUpperCase()}</div>
          </Grid>
          <Grid className={classes.headerItem} item>
            <Description className={classes.description} />
            <div>{type.name.toLocaleUpperCase()}</div>
          </Grid>
          <Grid className={classes.headerItemEnd} item>
            <DateRange className={classes.dateRange} />
            <div style={{paddingLeft: 15}}>{format(new Date(date), "dd.MM.yyyy")}</div>
          </Grid>
        </Grid>
        <CardContent className={classes.cardContent}>
          <Box className={classes.titleBox}>
            <SectionHeading>{title}</SectionHeading>
          </Box>
          <Box>
            <HtmlElement
              component="div"
              classes={`${classes.summary} max-lines-5`}
              html={summary}
            />
          </Box>
          {TagsContent}
        </CardContent>
      </Card>
    </Link>
  );
};

export default LegalBaseCard;
