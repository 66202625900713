import { FC, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useAboutStyles } from '../AboutProject.styles'
import MembersCard from 'components/common/Cards/Members/MembersCard'
import Chapter from 'components/common/Сhapter'
import { membersService } from 'services/members/members.service'
import Spiner from 'components/common/Spiner'

const Members: FC = () => {
  const { members, loading } = membersService

  const classes = useAboutStyles()
  useEffect(() => {
    membersService.fetchMembers()
  }, [])
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const partners = [
  //   {
  //     id: 1,
  //     name: 'Лихачева Анастасия Борисовна ',
  //     position:
  //       'руководитель проекта,декан Факультета мировой экономики и мировой политики',
  //     img: 'members/member1.jpg',
  //     url: 'https://www.hse.ru/org/persons/14276119'
  //   },
  //   {
  //     id: 2,
  //     name: 'Шеин Сергей Александрович',
  //     position:
  //       'научный сотрудник Центра комплексных европейских и международных исследований ',
  //     img: 'members/member2.jpeg',
  //     url: 'https://www.hse.ru/org/persons/103793343'
  //   },
  //   {
  //     id: 3,
  //     name: 'Бирюкова Ольга Владимировна',
  //     position:
  //       'старший научный сотрудник Центра комплексных европейских и международных исследований ',
  //     img: 'members/member3.png',
  //     url: 'https://www.hse.ru/org/persons/3669627'
  //   },
  //   {
  //     id: 4,
  //     name: 'Новиков Дмитрий Павлович ',
  //     position:
  //       ' заместитель руководителя Департамента международных отношений ',
  //     img: 'members/member4.jpeg',
  //     url: 'https://www.hse.ru/org/persons/101538368'
  //   },
  //   {
  //     id: 5,
  //     name: 'Бочарова Александра Павловна',
  //     position: 'преподаватель Департамента международных отношений',
  //     img: 'members/member5.jpg',
  //     url: 'https://www.hse.ru/org/persons/160957425'
  //   },
  //   {
  //     id: 6,
  //     name: 'Смирнова Вероника Александровна',
  //     position:
  //       'младший научный сотрудник Центра комплексных европейских и международных исследований',
  //     img: 'members/member6.jpeg',
  //     url: 'https://www.hse.ru/org/persons/305122406'
  //   },
  //   {
  //     id: 7,
  //     name: 'Бондаренко Анна Валентиновна',
  //     position:
  //       'стажер-исследователь Центра комплексных европейских и международных исследований ',
  //     img: '/members/member7.jpeg',
  //     url: 'https://www.hse.ru/org/persons/305061850'
  //   },
  //   {
  //     id: 8,
  //     name: 'Рыжкин Егор Николаевич',
  //     position:
  //       'стажер-исследователь Центра комплексных европейских и международных исследований ',
  //     img: 'members/member8.jpeg',
  //     url: 'https://www.hse.ru/org/persons/305117356'
  //   },
  //   {
  //     id: 9,
  //     name: 'Янькова Александра Дмитриевна',
  //     position:
  //       'стажер-исследователь Центра комплексных европейских и международных исследований ',
  //     img: 'members/member9.jpeg',
  //     url: 'https://www.hse.ru/org/persons/401667628'
  //   }
  // ]

  return (
    <Chapter title="команда" isButton={false} padding="30px 0px">
      {loading ? (
        <Spiner />
      ) : (
        <Grid container spacing={4} className={classes.panelContainer}>
          {members.map((member) => (
            <Grid item key={member.id} sm={4} lg={3}>
              <MembersCard members={member} />
            </Grid>
          ))}
        </Grid>
      )}
    </Chapter>
  )
}

export default observer(Members)
