import { observer } from "mobx-react-lite";
import { FC, Fragment } from "react";
import { usePhotoStyle } from "./Photos.styles";
import { Grid } from "@material-ui/core";
import { mediacenterService } from "services/mediacenter/mediacenter.service";
import Paging from "components/common/Paging";
import { scrollTopPage } from "utils";
import PhotoCard from "./PhotoCard";
import Spiner from "components/common/Spiner";
import EmptyFilterResult from "components/common/StubsAndBugs/EmptyFilterResult";

const PhotosPage: FC = () => {
  const classes = usePhotoStyle();
  const { loading, photoGallerys, pagingPhoto } = mediacenterService;
  const handlePageChange = (page: number) => {
    mediacenterService.fetchPhotosPaging(page);
    scrollTopPage();
  };

  if (loading) {
    return <Spiner />;
  }

  return (
    <Fragment>
      <Grid container spacing={4} className={classes.cardsContainer}>
        {photoGallerys.length ? (
          photoGallerys.map((item) => (
            <Grid key={item.id} className={classes.photoCard} item>
              <PhotoCard
                id={item.id}
                title={item.title}
                date={item.date}
                urlPicture={item.image[0]}
              />
            </Grid>
          ))
        ) : (
          <EmptyFilterResult />
        )}
      </Grid>
      <Paging paging={pagingPhoto} onChange={handlePageChange} />
    </Fragment>
  );
};

export default observer(PhotosPage);
