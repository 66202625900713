import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import getOrigin from "utils/getOrigin";
import { DocumentListItem, DocumentDetailItem } from "models";
import { defaultPaging } from "enums";
import { FilterOptions } from "models/filters";
import { format } from "date-fns";
import { defaultDetails } from "./legalBase.data";
import { returnIds } from "utils";

const url = getOrigin() + "api/document/list";
const urlDetail = getOrigin() + "api/document";

class LegalBaseService {
  public documents: DocumentListItem[] = [];
  public details: DocumentDetailItem = defaultDetails;
  public paging = defaultPaging;
  private filters = {};
  public lang = "";
  public loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchDocuments(language: string) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: language,
        ...defaultPaging,
      };
      const result = await axios.get(url, { params });
      if (result.status !== 200) {
        return console.log("result", result);
      }
      runInAction(() => {
        const { data, total } = result.data;
        this.documents = data;
        this.lang = language;
        this.details = defaultDetails;
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize),
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  async fetchDocumentsFilter(filters: FilterOptions) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: this.lang,
        ...this.parseFilters(filters),
        ...defaultPaging,
      };
      const result = await axios.get(url, { params });
      if (result.status !== 200) {
        return console.log("result", result);
      }
      runInAction(() => {
        const { data, total } = result.data;
        this.documents = data;
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize),
        };
        this.filters = this.parseFilters(filters);
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  async fetchPagingDocuments(newPage: number) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: this.lang,
        ...this.filters,
        ...this.paging,
        page: newPage,
      };
      const result = await axios.get(url, { params });
      if (result.status !== 200) {
        return console.log("result", result);
      }
      runInAction(() => {
        const { data } = result.data;
        this.documents = data;
        this.paging = { ...this.paging, page: newPage };
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  async fetchDocumentDetail(language: string, id: number) {
    try {
      runInAction(() => (this.loading = true));
      const params = {
        lang: language,
      };
      const result = await axios.get(`${urlDetail}/${id}`, { params });
      if (result.status !== 200) {
        return console.log("result", result);
      }
      runInAction(() => {
        const { data } = result.data;
        console.log("detail", data);
        this.details = data;
        this.lang = language;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  private parseFilters = (filters) => {
    const blockIds = filters.block ? returnIds(filters.block) : null;
    const regionIds = filters.country ? returnIds(filters.country) : null;
    const typeIds = filters.documentType
      ? returnIds(filters.documentType)
      : null;
    return {
      title: filters.title.length ? filters.title : null,
      text: filters.text.length ? filters.text : null,
      block: blockIds,
      region: regionIds,
      type: typeIds,
      date_start: filters.period.start
        ? format(filters.period.start, "yyyy-MM-dd")
        : null,
      date_end: filters.period.end
        ? format(filters.period.end, "yyyy-MM-dd")
        : null,
    };
  };

  public cleanPage = () => {
    this.paging = defaultPaging;
    this.documents = [];
    this.details = defaultDetails;
    this.filters = {};
  };
}

export const legalBaseService = new LegalBaseService();
