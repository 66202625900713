import { FC, useEffect } from "react";
import { useLanguage } from "context/Translation";
import { observer } from "mobx-react-lite";
import { Box, Grid } from "@material-ui/core";
import SectionTitle from "components/common/SectionTitle";
import Breadcrumb from "components/common/Breadcrumb";
import { openDataService } from "services/openData/openData.services";
import HtmlElement from "components/common/HtmlElement";
import { useOpenDataStyles } from "./OpenData.styles";
import { scrollTopPage } from "utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routers } from "routers";

const OpenDataPage: FC = () => {
  const classes = useOpenDataStyles();
  const { dataList } = openDataService;
  const { language } = useLanguage();
  const { t } = useTranslation();
  useEffect(() => {
    openDataService.fetchDatas(language);
  }, [language]);

  return (
    <Box>
      <SectionTitle background="planet" />
      <Breadcrumb />
      <Box className={classes.container}>
        <Box>
          <Grid container spacing={2}>
            {dataList.map((item) => (
              <Grid item key={item.id} xs={12}>
                <Box className={classes.titleContainer}>
                  <Box className={classes.title}>{item.name}</Box>
                  <Box
                    display={"flex"}
                    padding={"10px 15px"}
                    alignItems={"center"}
                  >
                    <HtmlElement
                      component={"div"}
                      classes={classes.description}
                      html={item.description}
                    />
                    <Link
                      to={`${routers.openData}/${item.id}`}
                      onClick={scrollTopPage}
                      className={classes.linkButton}
                    >
                      {t("more")}
                    </Link>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(OpenDataPage);
