import { Box, Button, useMediaQuery } from '@material-ui/core'
import { FC, useState } from 'react'
import { useCardStyle } from './SanctionHome.styles'
import { CardProps } from './Sanction.types'
import HtmlElement from 'components/common/HtmlElement'

const Card: FC<CardProps> = ({ info }) => {
  const { title, text } = info

  const classes = useCardStyle()
  const [clicked, setClicked] = useState(false)

  const responsiveCard = useMediaQuery('(max-width:650px)')
  return (
    <Box
      className={
        responsiveCard
          ? clicked
            ? classes.sactionCard
            : classes.respoCard
          : classes.sactionCard
      }
    >
      <Box className={classes.textBox}>
        <Box className={classes.text}>{title}</Box>
        <HtmlElement
          component="div"
          classes={classes.description}
          html={text}
        />
      </Box>
      {responsiveCard && (
        <Button className={classes.button} onClick={() => setClicked(!clicked)}>
          {!clicked ? 'Read more' : 'Show Less'}
        </Button>
      )}
    </Box>
  )
}

export default Card
