import { makeStyles, Theme } from "@material-ui/core";
import { common, grey } from "@material-ui/core/colors";
import { defaultFilters } from "styles/defaultStyle";

export const useDatePickerStyles = makeStyles((theme: Theme) => ({
  editInput: {
    fontSize: 14,
  },
  dataPicker: {
    "& .Mui-focused": {
      color: common.black,
    },
    "& .Mui-error": {
      color: common.black,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "none!important",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 10px) scale(1)",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 10px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(15px, -6px) scale(0.75)",
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 5,
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0,
    },
  },
  calendareButton: {
    "& .MuiIconButton-root": {
      padding: 3,
      color: theme.palette.secondary.main,
      fontSize: "1rem",
      marginRight: -6,
      marginLeft: -3,
    },
  },
}));

export const useFiltersStyle = makeStyles((theme) => ({
  filters: defaultFilters(theme),
  radio: {
    marginInlineEnd: "30px",
  },
}));

export const useBirthdateFilterStyle = makeStyles({
  paper: {
    padding: "16px 16px 5px 16px",
    marginTop: 20,
    minWidth: 300,
    boxShadow:
      "0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
  },
  datePicker: {
    "& .MuiInputLabel-outlined": {
      transform: "translate(15px, 12px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(15px, -6px) scale(0.75)",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiIconButton-root": {
      padding: 3,
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0,
    },
  },
  datesContainer: {
    marginBottom: 5,
  },
  positionEnd: {
    marginRight: 5,
  },
  calendarIcon: {
    fontSize: 19,
  },
  dash: {
    height: 2,
    background: grey[400],
    width: 8,
  },
  inputStyle: {
    paddingRight: 0,
  },
});
