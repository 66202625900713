import { makeAutoObservable, runInAction } from 'mobx'
import axios from 'axios'
import { NewsItem, NewsListItem } from 'models'
import { defaultPaging } from 'enums'
import { FilterOptions } from 'models/filters'
import { format } from 'date-fns'
import { defaultNewsDetail } from './news.data'
import { returnIds } from 'utils'
const urlNews = '/api/news'

class NewsService {
  public news: NewsListItem[] = []
  public detail: NewsItem = defaultNewsDetail
  public paging = defaultPaging
  private filter = {}
  public lang = ''
  public loading = false

  constructor() {
    makeAutoObservable(this)
  }

  async fetchNews(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...defaultPaging
      }
      const result = await axios.get(urlNews, { params })

      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        this.news = data
        this.lang = language
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchNewsFilter(language: string, filters: FilterOptions) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...this.parseFilters(filters),
        ...defaultPaging
      }
      const result = await axios.get(urlNews, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        this.news = data
        this.lang = language
        this.filter = this.parseFilters(filters)
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  private parseFilters = (filters) => {
    const typeIds = filters.type ? returnIds(filters.type) : null
    return {
      type: typeIds,
      title: filters.title.length ? filters.title : null,
      text: filters.text.length ? filters.text : null,
      date_start: filters.period.start
        ? format(filters.period.start, 'yyyy-MM-dd')
        : null,
      date_end: filters.period.end
        ? format(filters.period.end, 'yyyy-MM-dd')
        : null
    }
  }

  async fetchPagingNews(newPage: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: this.lang,
        ...this.filter,
        ...this.paging,
        page: newPage
      }
      const result = await axios.get(urlNews, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.news = data
        this.paging = { ...this.paging, page: newPage }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchDetail(language: string, id: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(`${urlNews}/${id}`, { params })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        this.detail = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  public cleanPage = () => {
    this.news = []
    this.detail = defaultNewsDetail
    this.paging = defaultPaging
    this.filter = {}
  }

  public cleanDetail = () => {
    this.detail = defaultNewsDetail
  }
}

export const newsService = new NewsService()
