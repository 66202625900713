import { FC, useEffect } from "react";
import { useTagsPageStyles } from "./Tags.styles";
import { getParameterFromUrl } from "utils";
import { tagsService } from "services/tags/tags.service";
import { useLanguage } from "context/Translation";
import { observer } from "mobx-react-lite";
import { Box } from "@material-ui/core";
import NewsTab from "./NewsTab";
import AnalyticsTab from "./AnalyticsTab";
import EventsTab from "./EventsTab";
import LegalBaseTab from "./LegalBaseTab";
import TabsPanel from "components/common/TabsPanel";
import { allObjectTabs } from "enums/tabs";

const TagsPage: FC = () => {
  const classes = useTagsPageStyles();
  const tagId = Number(getParameterFromUrl("tagId"));
  const tab = String(getParameterFromUrl("tab"));
  const { lang, idTag, tagName } = tagsService;
  const { language } = useLanguage();

  useEffect(() => {
    if (tagId !== idTag) {
      tagsService.fetchTagInfo(language, tagId, tab);
      return;
    }
    if (lang !== language) {
      tagsService.fetchTagInfo(language, tagId, tab);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, idTag]);

  useEffect(() => {
    tagsService.setElementTotal(tab);
  }, [tab]);

  const panels = [
    // eslint-disable-next-line react/jsx-key
    <NewsTab />,
    // eslint-disable-next-line react/jsx-key
    <AnalyticsTab />,
    // eslint-disable-next-line react/jsx-key
    <EventsTab />,
    // eslint-disable-next-line react/jsx-key
    <LegalBaseTab />,
  ];
  const activeTab = allObjectTabs.findIndex(({ lable }) => lable === tab);

  return (
    <Box className={classes.container}>
      <div className={classes.tagText}>#{tagName}</div>
      <TabsPanel tabs={allObjectTabs} panels={panels} activeTab={activeTab} />
    </Box>
  );
};

export default observer(TagsPage);
