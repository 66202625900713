import { makeStyles, Theme } from "@material-ui/core";
import { defaultContainer, tagGridItem } from "styles/defaultStyle";

export const useNewsStyles = makeStyles((theme: Theme) => ({
  container: {
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  detailContainer: defaultContainer(theme),
  carusel: {
    display: "flex",
    height: 200,
    justifyContent: "center",
    alignItems: "center",
  },
  buttons: {
    color: theme.palette.success.main,
    border: "none",
    backgroundColor: "rgba(255,255,255,0)",
    fontSize: 70,
  },
  stillLoadingBox: {
    width: "100%",
    height: "100%",
  },
  detailTitle: {
    fontSize: 24,
    lineHeight: "33px",
    textTransform: "uppercase",
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  detailText: {
    fontSize: 14,
    color: theme.palette.text.primary,
    lineHeight: "19px",
    textAlign: "justify",
    margin: "30px 0px",
  },
  detailSources: {
    fontSize: 14,
    color: theme.palette.text.primary,
    textAlign: "justify",
  },
  detailImgs: {
    padding: "30px 0px",
  },
  slider: {
    flex: 1,
    justifyContent: "center",
  },
  imgsToSlid: {
    display: "flex",
    justifyContent: "space-beetwen",
  },
  detailImg: {
    marginRight: 20,
    borderRadius: 5,
    height: 195,
    width: 280,
  },
  imgsWithoutSliderContainer: {
    display: "flex",
    justifyContent: "center",
  },
  detailAuthorContainer: {
    width: 350,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    padding: 10,
  },
  detailAuthorPicture: {
    borderRadius: "50%",
    overflow: "hidden",
    width: 225,
    height: 225,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: 255,
      height: "auto",
    },
  },
  detailAuthorName: {
    marginTop: "10px",
    width: "100%",
    fontWeight: 700,
  },
  detailAuthorDescription: {
    width: "100%",
    marginBottom: "10px",
  },
  underAuthorDiscriptionBox: {
    height: 1,
    backgroundColor: theme.palette.grey[100],
    width: "100%",
  },
  socialMediaLinksContainer: {
    width: "100%",
    display: "flex",
    "& button": {
      color: theme.palette.primary.main,
    },
  },
}));

export const useNewsDetailStyles = makeStyles((theme) => ({
  detailContainer: defaultContainer(theme),
  carusel: {
    display: "flex",
    width: 320,
    height: 200,
    justifyContent: "center",
    alignItems: "center",
  },
  buttons: {
    color: theme.palette.success.main,
    border: "none",
    backgroundColor: "rgba(255,255,255,0)",
    fontSize: 70,
  },
  title: {
    fontSize: 24,
    lineHeight: "33px",
    textTransform: "uppercase",
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    lineHeight: "19px",
    textAlign: "justify",
    margin: "30px 0px",
    [theme.breakpoints.down(605)]: {
      marginBottom: 0,
    },
  },
  sources: {
    fontSize: 14,
    color: theme.palette.text.primary,
    textAlign: "justify",
  },
  image: {
    marginRight: 20,
    borderRadius: 5,
    height: 195,
    width: 280,
  },
  slider: {
    flex: 1,
    justifyContent: "center",
  },
  tagItem: tagGridItem,
  imgNotAutor: {
    borderRadius: 5,
    height: 250,
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down(768)]: {
      width: "calc(100% + 20px)",
      height: 200,
    },
  },
  imgContainerNotAutor: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    marginTop: 30,
    height: 250,
    width: 350,
    [theme.breakpoints.down(768)]: {
      height: 200,
      width: 200,
    },
    [theme.breakpoints.down(605)]: {
      marginTop: 0,
      width: "100%",
    },
  },
}));
