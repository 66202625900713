import { Theme } from '@material-ui/core'
import background from 'assets/image/background.png'

export const defaultContainer = (theme: Theme): typeof defaultContainer => {
  return {
    backgroundImage: `url(${background})`,
    backgroundSize: '100%',
    backgroundColor: theme.palette.common.white,

    padding: '30px 5%',
    minHeight: '60vh'
  }
}

export const defaultFilters = (theme: Theme): typeof defaultFilters => {
  return {
    padding: '20px 5% 30px',
    // backgroundColor: theme.palette.primary.main,
    background: 'rgba(255, 255, 255, 0.94)',
    display: 'flex',
    width: '80%',
    alignItems: 'center',
    [theme.breakpoints.up('tablet')]: {
      padding: '30px 5%'
    }
  }
}

export const tagGridItem = {
  display: 'flex',
  alignItems: 'center',
  marginInlineEnd: '5%'
}
