import { FC, Fragment, useState } from 'react'
import SectionTitle from 'components/common/SectionTitle'
import Breadcrumb from 'components/common/Breadcrumb'
import AboutChapter from './AboutChapter'
import { useAboutStyles } from '../AboutProject.styles'
import FeedbackChapter from './FeedbackChapter'
import { Box } from '@material-ui/core'
import Members from './Members'
const AboutProjectPage: FC = () => {
  const classes = useAboutStyles()
  const [, setWidth] = useState(window.outerWidth)

  const detectWindowSize = () => setWidth(window.outerWidth)
  window.onresize = detectWindowSize

  return (
    <Fragment>
      <SectionTitle isFone={false} isShown={false} />
      <Breadcrumb />
      <Box className={classes.container}>
        <AboutChapter />
        <FeedbackChapter />
        <Members />
      </Box>
    </Fragment>
  )
}

export default AboutProjectPage
