import { Link } from "react-router-dom";
import { AccountCircle } from "@material-ui/icons";
import { usePersonsCardStyles } from "./PeopleCard.style";
import { FC } from "react";
import { AuthorProps } from "./PeopleCard.types";
import { Box } from "@material-ui/core";
import Line from "components/common/Line";
import { scrollTopPage } from "utils";
import { routers } from "routers";

const AutorItemCard: FC<AuthorProps> = ({ author, isLine }) => {
  const classes = usePersonsCardStyles();
  const imgContent = author.photo ? (
    <div className={classes.imageConteiner}>
      <img src={author.photo} alt="" className={classes.imageItem} />
    </div>
  ) : (
    <AccountCircle className={classes.accountCircle} />
  );

  return (
    <Box>
      <Link
        to={`${routers.about.authors}/${author.id}`}
        className={classes.cardItemLink}
        onClick={scrollTopPage}
      >
        <div>{imgContent}</div>
        <div className={classes.authorInfo}>
          <div className={classes.authorName}>{author.name}</div>
          <div className={classes.authorWork}>{author.work}</div>
        </div>
      </Link>
      {isLine ? <Line /> : null}
    </Box>
  );
};

export default AutorItemCard;
