import { makeStyles, Theme } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { defaultFilters } from 'styles/defaultStyle'

export const useFiltersStyle = makeStyles((theme: Theme) => ({
  filters: defaultFilters(theme),
  radioForm: {
    justifyContent: 'space-between'
  },
  container: {
    display: 'flex'
  },
  textFilter: {
    '& .MuiInputBase-root': {
      backgroundColor: 'unset',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderBottom: `1px solid ${(theme.palette, grey[400])}`,
        borderRadius: 0
      }
    }
  },
  dateGrid: {
    '& .MuiTextField-root': {
      '& .MuiInputBase-root': {
        backgroundColor: 'unset!important'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderBottom: `1px solid ${(theme.palette, grey[400])}`,
        borderRadius: 0
      }
    }
  },
  findBtn: {
    width: '100%',
    height: 40,
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    transition: 'color 500ms',
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.primary.main
    }
  },
  accordion: {
    background: theme.palette.primary.main,
    margin: 0
  },
  summary: {
    paddingLeft: '5%',
    paddingRight: '5%'
  },
  header: {
    color: theme.palette.common.white
  },
  details: {
    padding: 20,
    display: 'flex',
    justifyContent: 'center'
  }
}))

export const useBirthdateFilterStyle = makeStyles({
  paper: {
    padding: '16px 16px 5px 16px',
    marginTop: 20,
    minWidth: 300,
    boxShadow:
      '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
  },
  datePicker: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(15px, 12px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 12px'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    },
    '& .MuiIconButton-root': {
      padding: 3
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0
    }
  },
  datesContainer: {
    marginBottom: 5
  },
  positionEnd: {
    marginRight: 5
  },
  calendarIcon: {
    fontSize: 19
  },
  dash: {
    height: 2,
    background: grey[400],
    width: 8
  },
  inputStyle: {
    paddingRight: 0
  }
})
