import { NewsDetailsItem, NewsItem } from 'models'

export const defaultNewsDetail: NewsItem = {
  id: 0,
  title: '',
  date: '',
  image: [
    {
      full: '',
      preview: ''
    }
  ],
  summary: '',
  tags: [],
  text: ''
}
export const defaultNewsItemDetail: NewsDetailsItem = {
  id: 0,
  title: '',
  date: {
    start: '',
    end: ''
  },
  image: {
    full: '',
    preview: ''
  },
  summary: '',
  tags: []
}
