import { FC, Fragment, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import Footer from 'components/common/Footer'
import AnalyticsDetails from 'components/Analytics/Details'
import AnalyticsPage from 'components/Analytics/index'
// import { newsService } from 'services/news/news.service'
import { routers } from 'routers'
import { analyticsService } from 'services/analytics/analytics.service'

const Analytics: FC = () => {
  useEffect(() => {
    return function cleanupPage() {
      analyticsService.cleanPage()
    }
  }, [])
  useEffect(() => {
    return function cleanupPage() {
      analyticsService.cleanPage()
    }
  }, [])

  return (
    <Fragment>
      <Switch>
        <Route exact path={routers.analytics}>
          <AnalyticsPage />
        </Route>
        <Route
          exact
          path={`${routers.analytics}/:id`}
          component={AnalyticsDetails}
        />
      </Switch>
      <Footer />
    </Fragment>
  )
}

export default Analytics
