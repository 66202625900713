import { ListItem, ListItemText } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { scrollTopPage } from "utils";
import { useFooterLinkStyles } from "./Link.styles";
import { LinkProps } from "./Link.types";

const FooterLink: FC<LinkProps> = ({ link, title, isTranslation = true }) => {
  const { t } = useTranslation();
  const titles = isTranslation ? t(title) : title;
  const classes = useFooterLinkStyles();
  return (
    <ListItem className={classes.container}>
      <Link to={link} onClick={scrollTopPage} className={classes.link}>
        <ListItemText primary={titles} />
      </Link>
    </ListItem>
  );
};

export default FooterLink;
