import { FC } from 'react'
import { Box } from '@material-ui/core'
import { useSectionTitleStyles } from './SectionTitle.style'
// import { useTranslation } from "react-i18next";
import { SectionTitleProps } from './SectionTitle.types'

import Header from '../Header'

const SectionTitle: FC<SectionTitleProps> = ({
  // title,
  // isEnd = false,
  background,
  children,
  isShown = true
}) => {
  const classes = useSectionTitleStyles()

  return (
    <>
      <Header withText />
      {isShown ? (
        <Box
          className={`${background?.length ? classes[background] : ''} ${
            classes.headerFon
          }`}
        >
          {children && <Box className={classes.childrenBox}>{children}</Box>}{' '}
        </Box>
      ) : null}
    </>
  )
}

export default SectionTitle
