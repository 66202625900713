import { makeStyles, Theme } from "@material-ui/core";

export const useLogoStyles = makeStyles((theme: Theme) => ({
  imgBox: {
    width: 50,
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    // [theme.breakpoints.up("mobileVertical")]: {
    //   width: 50,
    //   flexDirection: "row",
    // },
    // [theme.breakpoints.up("mobileHorisontal")]: {
    //   width: "100%",
    //   flexDirection: "row",
    // },
    // [theme.breakpoints.up("tabletHorisontal")]: {
    //   width: 200,
    //   flexDirection: "column",
    // },
  },
  text: {
    fontSize: 14,
    fontWeight: 700,
    [theme.breakpoints.up("mobileHorisontal")]: {
      fontSize: 12,
    },
  },
  container: {
    width: 200,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    marginBottom: 10,
    width: 50,
    [theme.breakpoints.up("mobileVertical")]: {
      width: 50,
      marginBottom: 0,
    },
    [theme.breakpoints.up("mobileHorisontal")]: {
      width: 70,
    },
    [theme.breakpoints.up("tablet")]: {
      width: 80,
    },
    [theme.breakpoints.up("tabletVertical")]: {
      width: 90,
    },
    [theme.breakpoints.up("laptop")]: {
      width: 100,
    },
  },
  textContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.up("mobileVertical")]: {
      width: 200,
      margin: "0px auto",
    },
    [theme.breakpoints.up("laptop")]: {
      width: 200,
      margin: "0px auto",
    },
  },
}));
