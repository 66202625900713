import { FC } from "react";
import { Box } from "@material-ui/core";
import useContainerStyle from "./Container.styles";

const Container: FC = ({ children }) => {
  const classes = useContainerStyle();
  return <Box className={classes.root}>{children}</Box>;
};

export default Container;
