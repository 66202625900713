import { Typography } from "@material-ui/core";
import { FC } from "react";
import { useTermsMaterialsStyles } from "./TermsMaterials.styles";

const TermsMaterialsText: FC = () => {
  const classes = useTermsMaterialsStyles();
  return (
    <Typography>
      <b>Условия использования материалов портала</b>
      <br />
      <br />
      Материалы, размещенные в разделах портала, могут использоваться в целях
      ознакомления, если в отношении таких материалов на странице их размещения
      не сделано специальное указание на иные условия использования. Допускается
      размещение активных ссылок на такие материалы в других информационных
      источниках без непосредственного копирования их содержания.
      <br />
      <br />
      Материалы портала, за исключением материалов, правообладателем которых не
      является университет, могут быть использованы для наполнения свободной
      энциклопедии Википедия (wikipedia.org) любым способом, в том числе путем
      подготовки точного их перевода, за исключением иной их переработки, с
      обязательным указанием авторов таких материалов, если сведения о них
      доступны на корпоративном портале. <br />
      <br />
      При любом использовании материалов активная ссылка на источник,
      размещенный на портале www.hse.ru, обязательна. <br />
      <br />
      Использование материалов за пределами вышеуказанных способов, в том числе
      их копирование, перепечатка, переработка и какое-либо коммерческое
      использование, запрещены. <br />
      <br />
      Логотипы НИУ ВШЭ, отображаемые на портале, зарегистрированы в качестве
      товарных знаков. Перечень товарных знаков НИУ ВШЭ доступен по адресу{" "}
      <a
        target="_blank"
        href={"https://www.hse.ru/org/hse/info/znak"}
        className={classes.link}
        rel="noreferrer"
      >
        www.hse.ru/org/hse/info/znak
      </a>
      . <br />
      <br />В оформлении редакционных материалов использованы изображения из
      коллекции{" "}
      <a
        target="_blank"
        href={"https://www.istockphoto.com/ru"}
        className={classes.link}
        rel="noreferrer"
      >
        iStock
      </a>{" "}
      (Коллекция/iStock). <br />
    </Typography>
  );
};

export default TermsMaterialsText;
