import { FC } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import Home from 'views/Home'
import Analytics from 'views/Analytics'
import AboutProject from 'views/AboutProject'
// import Events from 'views/Events'
import Mediacenter from 'views/Mediacenter'
import LegalBase from 'views/LegalBase'
import FAQ from 'views/FAQ'
import SiteMap from 'views/SiteMap'
import PrivacyPolicy from 'views/PrivacyPolicy'
import TermsMaterials from 'views/TermsMaterials'
import Tags from 'views/Tags'
import { observer } from 'mobx-react-lite'
import SearchView from 'views/Search'
import OpenDataView from 'views/OpenData'
import MenuProvider from 'context/MenuState/MenuProvider'
import { routers } from 'routers'
import { useMediaQuery } from '@material-ui/core'
import ScreenFormat from 'components/common/StubsAndBugs/ScreenFormat'
import Articles from 'views/Articles'
import Sanctions from 'views/Sanction'
import Login from 'views/Login'
import News from 'views/News'
import Feedback from 'views/FeedBack/index'
import Register from 'views/Register'

const App: FC = () => {
  const isNonScreen = useMediaQuery('(max-width:319px)')

  if (isNonScreen) {
    return <ScreenFormat />
  }

  return (
    <BrowserRouter>
      <MenuProvider>
        <Switch>
          <Route exact path={routers.home} component={Home} />
          <Route path="/home" component={Home} />
          <Route path={routers.mediacenter.home} component={Mediacenter} />
          {/* <Route path={routers.events} component={Events} /> */}
          <Route path={routers.news} component={News} />
          <Route path={routers.sanctions} component={Sanctions} />
          <Route path={routers.feedback} component={Feedback} />
          <Route path={routers.analytics} component={Analytics} />
          <Route path={routers.about.home} component={AboutProject} />
          <Route path={routers.legalBase} component={LegalBase} />
          <Route path={routers.termsMaterials} component={TermsMaterials} />
          <Route path={routers.privacyPolicy} component={PrivacyPolicy} />
          <Route path={routers.siteMap} component={SiteMap} />
          <Route path={routers.faq} component={FAQ} />
          <Route path={routers.tags} component={Tags} />
          <Route path={routers.searchByKey} component={SearchView} />
          <Route path={routers.openData} component={OpenDataView} />
          <Route path={routers.sanctions} component={Articles} />
          <Route path={routers.login} component={Login} />
          <Route path={routers.register} component={Register} />
          <Route path="*" exact component={Home} />
        </Switch>
      </MenuProvider>
    </BrowserRouter>
  )
}

export default observer(App)
