import { FC } from 'react'
import logoHeader from 'assets/image/logoHeader.png'
import { LogoProps } from './Logo.types'
import { useLogoStyles } from './Logo.styles'
import { scrollTopPage } from 'utils'
import { Box } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const Logo: FC<LogoProps> = () => {
  const classes = useLogoStyles()
  return (
    <Box className={classes.imgBox}>
      <Link to={'/'} onClick={scrollTopPage}>
        <img src={logoHeader} alt="Logo" className={classes.img} />
      </Link>
    </Box>
  )
}
