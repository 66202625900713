import { FC } from "react";
import { VideoCardProps } from "./Videos.types";
import { useVideosStyle } from "./Videos.styles";
import { Card, CardMedia, Typography } from "@material-ui/core";
import { processingUrlYoutube } from "utils";
import { format } from "date-fns";
import HtmlElement from "components/common/HtmlElement";

const VideoCard: FC<VideoCardProps> = ({ title, date, urlVideo }) => {
  const classes = useVideosStyle();

  return (
    <Card className={classes.cardItem}>
      {urlVideo.includes("iframe") ? (
        <HtmlElement
          component="div"
          classes={classes.videoIframe}
          html={urlVideo}
        />
      ) : (
        <CardMedia
          component="iframe"
          className={classes.video}
          title={title}
          height="228px"
          src={processingUrlYoutube(urlVideo)}
          allowFullScreen
        />
      )}
      <Typography className={classes.title} gutterBottom component="div">
        {title}
      </Typography>
      <Typography className={classes.date}>
        {format(new Date(date), "dd.MM.yyyy")}
      </Typography>
    </Card>
  );
};

export default VideoCard;
