import { grey } from "@material-ui/core/colors";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { scrollTopPage } from "utils";
import { LinkProps } from "./Link.types";
import { useLanguage } from "context/Translation";
const HeaderLink: FC<LinkProps> = ({
  link,
  title,
  isTranslation = true,
  color = grey[600],
  onHover,
  isActive = false,
  onClick,
}) => {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const titles = isTranslation ? t(title) : title;
  const classes = isActive ? "active-link" : "link-hover-center";

  const handleClick = () => {
    onClick && onClick();
    scrollTopPage();
  };
  return (
    <div
      className={`${classes} ${
        language === "en" ? "" : "link-hover-center-ru"
      }`}
    >
      <Link
        to={link}
        onClick={handleClick}
        style={{ color }}
        onMouseOver={onHover}
      >
        {titles}
      </Link>
    </div>
  );
};

export default HeaderLink;
