import { makeAutoObservable, runInAction } from 'mobx'
import axios from 'axios'
import { FaqItem } from 'models'

const urlFAQ = '/api/faq'

class FAQService {
  public faqList: FaqItem[] = []
  public lang = ''
  public loading = false

  constructor() {
    makeAutoObservable(this)
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async fetchFAQInfo(language: string) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const result = await axios.get(urlFAQ, { params })
      if (result.status !== 200) {
        return console.log('result tag', result)
      }

      runInAction(() => {
        const { data } = result.data
        this.faqList = [...data]
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
}

export const faqService = new FAQService()
