import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { globalBaseStyles } from 'styles'
import TranslationProvider from 'context/Translation/TranslationProvider'
import Spiner from 'components/common/Spiner'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

ReactDOM.render(
  <React.StrictMode>
    {/* <GoogleReCaptchaProvider reCaptchaKey="6LfxsqIoAAAAAPMoSnWKqKGcptTpqfLNxtSSxIZd"> */}
    <GoogleReCaptchaProvider
      useRecaptchaNet={true}
      reCaptchaKey="6LdA9mopAAAAALEFNRW_jspJesON1GflA6Uqd5r3"
    >
      <ThemeProvider theme={globalBaseStyles}>
        <CssBaseline />
        <TranslationProvider>
          <Suspense fallback={<Spiner isFone />}>
            <App />
          </Suspense>
        </TranslationProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
