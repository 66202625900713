import { FC } from "react";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useLinkedDocumentCardStyles } from "./Cards.styles";
import { format } from "date-fns";
import { DateRange, Description, Language } from "@material-ui/icons";
import { scrollTopPage } from "utils";
import { LinkedDocumentsProps } from "./Cards.types";
import { routers } from "routers";

const LinkedDocumentsCard: FC<LinkedDocumentsProps> = ({ document }) => {
  const classes = useLinkedDocumentCardStyles();
  const { id, block, region, date, type, title } = document;
  return (
    <Link to={`${routers.legalBase}/${id}`} onClick={scrollTopPage}>
      <Box className={classes.container}>
        <Box className={classes.containerHeader}>
          <Box className={classes.headerItem}>
            <img src={block.img} alt="" />
            <div>{block.name}</div>
          </Box>
          <Box className={classes.headerItem}>
            <Language className={classes.lang} />
            <div>{region.name}</div>
          </Box>
          <Box className={classes.headerItem}>
            <Description className={classes.description} />
            <div>{type.name}</div>
          </Box>
          <Box className={classes.headerItem}>
            <DateRange className={classes.dateRange} />
            <div>{format(new Date(date), "dd.MM.yyyy")}</div>
          </Box>
        </Box>
        <Box className={classes.title}>{title}</Box>
      </Box>
    </Link>
  );
};

export default LinkedDocumentsCard;
