import { DocumentDetailItem } from "models";

export const defaultDetails: DocumentDetailItem = {
  id: 0,
  title: "",
  date: "",
  summary: "",
  type: {
    id: 0,
    name: "",
  },
  region: {
    id: 0,
    name: "",
  },
  block: {
    id: 0,
    name: "",
    img: "",
  },
  linked_documents: {
    data: [],
    total: 0,
  },
  tags: [],
};
