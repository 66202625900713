import { makeStyles, Theme } from '@material-ui/core'

export const useArticlesChapterStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: theme.palette.background.default,
    width: 640,
    height: 350,
    border: `1px solid ${theme.palette.grey[400]}`,
    overflow: 'visible',
    position: 'relative',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up(1130)]: {
      width: 550
    },
    [theme.breakpoints.down(1250)]: {
      width: 500
    },
    [theme.breakpoints.down(1130)]: {
      width: 450
    },
    [theme.breakpoints.down(970)]: {
      width: 420
    },
    [theme.breakpoints.down(880)]: {
      width: 580
    },
    [theme.breakpoints.down(650)]: {
      width: 450
    },
    [theme.breakpoints.down(490)]: {
      width: 400
    },
    [theme.breakpoints.down(450)]: {
      width: 350
    },
    [theme.breakpoints.down(375)]: {
      width: 310
    }
  },
  actionBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end'
  },
  icon: {
    color: theme.palette.secondary.main
  },
  dateBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    padding: 10
  },

  desText: {
    fontSize: 14,
    lineHeight: '30px',
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical'
  },
  date: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.secondary.main
  },
  backBox: {
    background: theme.palette.secondary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: '3%',
    top: '6%',
    zIndex: -1
  },
  titleBox: {
    width: '100%',
    height: 50,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(970)]: {
      fontSize: 16,
      padding: '20px 0'
    },
    [theme.breakpoints.down(450)]: {
      marginBottom: 10
    },
    '& p': {
      fontSize: 18,
      color: theme.palette.info.main,
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflowY: 'hidden',
      [theme.breakpoints.down(450)]: {
        fontSize: 16
      }
    }
  },
  textBox: {
    width: '100%',
    height: 200,
    '& p': {
      lineHeight: '30px',
      fontSize: 16,
      color: theme.palette.text.primary,
      [theme.breakpoints.down(450)]: {
        lineHeight: '30px',
        fontSize: 14
      },
      [theme.breakpoints.down(375)]: {
        lineHeight: '25px',
        fontSize: 14
      }
    },
    [theme.breakpoints.down(1130)]: {
      width: '100%'
    }
  }
}))

export const useArticlesCardStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    height: 177,
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    },
    [theme.breakpoints.down(768)]: {
      height: 205
    },
    [theme.breakpoints.down(480)]: {
      display: 'none'
    }
  },
  imgContainer: {
    height: 175,
    minWidth: 162,
    width: 162,
    position: 'relative',
    [theme.breakpoints.down(768)]: {
      height: 203,
      minWidth: 146,
      width: 146
    },
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: 175,
      borderRadius: '5px 0 0 5px',
      [theme.breakpoints.down(768)]: {
        height: 203
      }
    }
  },
  title: {
    fontSize: 17,
    lineHeight: '25px',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(876)]: {
      fontSize: 15
    }
  },
  cropHeight: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.text.secondary
  },
  longDescription: {
    '-webkit-line-clamp': 6
  },
  date: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.secondary.main
  },
  loadingInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: 10
  },
  bodyContainer: {
    height: 177,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down(768)]: {
      height: 205
    },
    width: '100%'
  },

  infoContainer: {
    display: 'flex',
    justifyContent: 'inherit'
  },
  titleContainer: {
    lineHeight: '25px',
    fontWeight: 700,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  about: {
    flexBasis: '15%',
    padding: '20px 0',
    fontSize: 14,
    lineHeight: '19px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(768)]: {
      display: 'none'
    }
  },
  dateOnImage: {
    borderRadius: ' 4px 4px 0px 0px',
    margin: 0,
    padding: '6px 16px',
    fontSize: 18,
    color: theme.palette.secondary.main,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    position: 'absolute',
    bottom: 0,
    display: 'none',
    [theme.breakpoints.down(768)]: {
      display: 'block'
    }
  }
}))

export const useMobileArticlesCardStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    boxShadow: 'none',
    flexWrap: 'wrap',
    [theme.breakpoints.up(480)]: {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  },
  title: {
    lineHeight: '25px',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    fontSize: 15
  },
  cropHeight: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.text.secondary
  },
  date: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.secondary.main
  },
  loadingInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: 10
  },
  imgContainer: {
    maxHeight: '224px',
    padding: 0,
    display: 'flex',
    justifyContent: 'start',
    position: 'relative',
    flexBasis: '100%',
    order: 1,
    '& img': {
      width: '100%',
      borderRadius: '0 0 5px 5px',
      height: 150
    }
  },
  bodyContainer: {
    padding: '15px 12px',
    flexBasis: '80%',
    position: 'relative',
    [theme.breakpoints.down(768)]: {
      flexBasis: '75%'
    },
    [theme.breakpoints.down(480)]: {
      flexBasis: '100%',
      order: 0
    }
  },
  titleContainer: {
    fontSize: 18,
    lineHeight: '25px',
    fontWeight: 700
  },
  about: {
    flexBasis: '15%',
    padding: '20px 0',
    fontSize: 14,
    lineHeight: '19px',
    textAlign: 'center',
    [theme.breakpoints.down(768)]: {
      display: 'none'
    }
  },
  titleDesContainer: {
    flexBasis: '100%',
    fontSize: '14px'
  },
  dateOnImage: {
    borderRadius: ' 4px 4px 0px 0px',
    margin: 0,
    padding: '6px 16px',
    fontSize: 18,
    color: theme.palette.secondary.main,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    position: 'absolute',
    bottom: 0,
    display: 'block'
  }
}))
