import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { FiltersProps } from "models/filters";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import CountryFilter from "./components/CountryFilter";
import DateFilter from "./components/DateFilter";
import DocumentFilter from "./components/DocumentFilter";
import { useFiltersStyle } from "./Filters.styles";
import ResearchFilter from "./components/ResearchFilter";
import { ExpandMore } from "@material-ui/icons";

const LegalBaseFilters: FC<FiltersProps> = ({
  checkedValues,
  onChange,
  onFind,
}) => {
  const classes = useFiltersStyle();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const maxMedia = useMediaQuery("(max-width:1440px)");
  const minMedia = useMediaQuery("(min-width:600px)");
  const isTablet = maxMedia && minMedia;

  const handleTitleChange = ({ target: { value } }) =>
    onChange({ ...checkedValues, title: value });

  const handleKeysChange = ({ target: { value } }) =>
    onChange({ ...checkedValues, text: value });

  const handleFromDateChange = (date) =>
    onChange({
      ...checkedValues,
      period: { ...checkedValues.period, start: date },
    });

  const handleToDateChange = (date) =>
    onChange({
      ...checkedValues,
      period: { ...checkedValues.period, end: date },
    });

  const handleFindClick = () => {
    onFind();
  };

  if (isMobile) {
    return (
      <Accordion className={classes.accordion}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMore fontSize={"small"} htmlColor="#fff" />}
        >
          <Typography className={classes.header}>{t("filters")}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Box className={classes.filters}>
            <Grid container spacing={2} direction={"row"}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  variant="outlined"
                  value={checkedValues.title}
                  fullWidth
                  placeholder={t("search-by-name")}
                  onChange={handleTitleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={checkedValues.text}
                  placeholder={t("keywords")}
                  onChange={handleKeysChange}
                />
              </Grid>
              <Grid
                className={classes.container}
                item
                container
                spacing={2}
                direction={"column"}
              >
                <Grid item xs>
                  <ResearchFilter
                    checkedValues={checkedValues}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs>
                  <CountryFilter
                    checkedValues={checkedValues}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs>
                  <DocumentFilter
                    checkedValues={checkedValues}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs>
                  <DateFilter
                    value={checkedValues.period.start}
                    onChange={handleFromDateChange}
                    lable={"from"}
                  />
                </Grid>
                <Grid item xs>
                  <DateFilter
                    value={checkedValues.period.end}
                    onChange={handleToDateChange}
                    lable={"to"}
                  />
                </Grid>
                <Grid item xs>
                  <Button
                    className={classes.findBtn}
                    color="default"
                    variant="contained"
                    onClick={handleFindClick}
                  >
                    {t("find")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

  if (isTablet) {
    return (
      <Box className={classes.filters}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="outlined"
              value={checkedValues.title}
              fullWidth
              placeholder={t("search-by-name")}
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              value={checkedValues.text}
              placeholder={t("keywords")}
              onChange={handleKeysChange}
            />
          </Grid>
          <Grid className={classes.container} item container spacing={2}>
            <Grid item xs={4}>
              <ResearchFilter
                checkedValues={checkedValues}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={4}>
              <CountryFilter
                checkedValues={checkedValues}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={4}>
              <DocumentFilter
                checkedValues={checkedValues}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={4}>
              <DateFilter
                value={checkedValues.period.start}
                onChange={handleFromDateChange}
                lable={"from"}
              />
            </Grid>
            <Grid item xs={4}>
              <DateFilter
                value={checkedValues.period.end}
                onChange={handleToDateChange}
                lable={"to"}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                className={classes.findBtn}
                color="default"
                variant="contained"
                onClick={handleFindClick}
              >
                {t("find")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box className={classes.filters}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            value={checkedValues.title}
            fullWidth
            placeholder={t("search-by-name")}
            onChange={handleTitleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            value={checkedValues.text}
            placeholder={t("keywords")}
            onChange={handleKeysChange}
          />
        </Grid>
        <Grid className={classes.container} item container spacing={2}>
          <Grid item xs>
            <ResearchFilter checkedValues={checkedValues} onChange={onChange} />
          </Grid>
          <Grid item xs>
            <CountryFilter checkedValues={checkedValues} onChange={onChange} />
          </Grid>
          <Grid item xs>
            <DocumentFilter checkedValues={checkedValues} onChange={onChange} />
          </Grid>
          <Grid item xs>
            <DateFilter
              value={checkedValues.period.start}
              onChange={handleFromDateChange}
              lable={"from"}
            />
          </Grid>
          <Grid item xs>
            <DateFilter
              value={checkedValues.period.end}
              onChange={handleToDateChange}
              lable={"to"}
            />
          </Grid>
          <Grid item xs>
            <Button
              className={classes.findBtn}
              color="default"
              variant="contained"
              onClick={handleFindClick}
            >
              {t("find")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LegalBaseFilters;
