import { makeStyles, Theme } from '@material-ui/core'

export const useCaruseleStyle = makeStyles((theme: Theme) => ({
  newsCarusel: {
    paddingBottom: '25px',
    minHeight: 200,

    [theme.breakpoints.down(1024)]: {
      paddingBottom: 0
    }
  },
  blokCarusel: {
    padding: '0 0 25px 0',
    [theme.breakpoints.down(1024)]: {
      padding: '20px 0px 30px 0px'
    },
    [theme.breakpoints.down(768)]: {
      padding: '15px 0px'
    }
  },
  icon: {
    fontSize: 70,
    [theme.breakpoints.up('mobileVertical')]: {
      fontSize: 30
    }
  }
}))

export const useEventsStyle = makeStyles({
  container: {
    display: 'flex',
    padding: '0px 5px',
    overflowX: 'auto',
    overflowY: 'hidden'
  }
})
