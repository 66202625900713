import { makeStyles, Theme } from "@material-ui/core";

export const useVideosStyle = makeStyles((theme: Theme) => ({
  cardItem: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  date: {
    color: theme.palette.secondary.main,
    padding: "0px 23px",
    fontSize: "large",
    fontWeight: 400,
  },
  cardsContainer: {
    "& .MuiPaper-root": {
      borderRadius: 5,
      border: `1px solid ${theme.palette.grey[100]}`,
      boxShadow: "none",
    },
  },
  video: {
    borderRadius: 5,
    border: 0,
    width: "calc(100% - 20px)",
    margin: 10,
  },
  videoIframe: {
    borderRadius: 5,
    border: 0,
    margin: 10,
    "& iframe": {
      borderRadius: 5,
      width: "100%",
      height: 228,
    },
  },
  title: {
    padding: "0px 23px",
    fontSize: "large",
    color: theme.palette.text.primary,
    fontWeight: 700,
    textTransform: "uppercase",
    flex: 1,
  },
  videoCard: {
    flexShrink: 1,
    [theme.breakpoints.down(768)]: { flexBasis: "100%" },
    [theme.breakpoints.between(769, 1279)]: { flexBasis: "50%" },
    [theme.breakpoints.up(1280)]: { flexBasis: "33.333333%" },
    [theme.breakpoints.up(2000)]: { flexBasis: "25%" },
  },
}));
