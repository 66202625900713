import { FC } from "react";
import { FiberManualRecord } from "@material-ui/icons";
import { TreeItemProps } from "./TreeItem.types";
import { Link } from "react-router-dom";
import { useTreeItemStyles } from "./TreeItem.styles";
import { scrollTopPage } from "utils";

const TreeItem: FC<TreeItemProps> = ({
  circleSize,
  text,
  url,
  isBold = false,
}) => {
  const classes = useTreeItemStyles();
  const textStyle = isBold ? classes.boldText : classes.notBoldText;
  const padding = (15 - circleSize) * 10;
  return (
    // These styles should not be taken out when refactoring code
    <Link
      className={textStyle}
      to={url}
      style={{ paddingLeft: padding }}
      onClick={scrollTopPage}
    >
      <FiberManualRecord
        className={classes.circleIcon}
        style={{ fontSize: `${circleSize}px` }}
      />
      {text}
    </Link>
  );
};
export default TreeItem;
