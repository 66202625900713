import { makeStyles, Theme } from "@material-ui/core";
import { defaultContainer } from "styles/defaultStyle";

export const useFAQStyles = makeStyles((theme: Theme) => ({
  root: {
    flexDirection: "column",
    display: "flex",
    width: "100%",
    "& .MuiAccordionSummary-expandIcon": {
      color: theme.palette.success.main,
    },
    "& .line": {
      margin: "0 15px",
      border: "none",
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.main,
      height: "1px",
    },
  },
  heading: {
    color: theme.palette.text.primary,
    lineHeight: "25px",
    fontSize: 18,
    fontWeight: 500,
  },
  answer: {
    color: theme.palette.text.primary,
    lineHeight: "25px",
    fontSize: 14,
    fontWeight: 400,
  },
  bgWhite: defaultContainer(theme),
  acordionBorder: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    boxShadow: "none",
  },
}));
