import { FC, Fragment, useState } from 'react'
import SectionTitle from 'components/common/SectionTitle'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

// import EventsCard from "components/common/Cards/Events/EventsCard";
import { observer } from 'mobx-react-lite'
import './styles.scss'
import { Register } from 'services/login/auth.service'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { useLoginStyles } from './Login.styles'
import { useHistory } from 'react-router-dom'
import { scrollTopPage } from 'utils'
const RegisterForm: FC = () => {
  const classes = useLoginStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const history = useHistory()
  const initialValues: {
    username: string
    password: string
    email: string
  } = {
    username: '',
    email: '',
    password: ''
  }
  //phoneRegex

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required!')
      .email('please enter a valid Email'),
    username: Yup.string().required('User Name is required!'),
    password: Yup.string().required('password is required!').nullable(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], "passwords don't match")
      .required('password is required!')
  })

  const handleSignUp = (formValue: {
    username: string
    email: string
    password: string
  }) => {
    const { username, password, email } = formValue
    scrollTopPage()
    Register(username, email, password).then(
      (response) => {
        const req_status = response.status

        if (req_status == 0) {
          setError(false)
          history.push('/login')
        } else {
          setError(true)
        }
      },
      (error) => {
        console.log(error, 'errorrrrrrr')

        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()

        setLoading(false)
        setMessage(resMessage)
      }
    )
  }

  return (
    <Fragment>
      <SectionTitle background="sanction" isShown={false} />

      <Box className={classes.card}>
        <Box className={classes.registerForm}>
          {error && (
            <p className={classes.alert} style={{ position: 'absolute' }}>
              Что-то пошло не так. Пожалуйста, повторите попытку позже
            </p>
          )}
          <Box className={classes.sectionHead}>
            <Typography className={classes.titleHead}>
              SanctionsRuBase+
            </Typography>
          </Box>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSignUp}
          >
            <Form className={classes.formik}>
              <Grid container className={classes.container}>
                <Typography className={classes.registerTitle}>
                  Регистрация
                </Typography>
                <Grid item xs={12} className={classes.signUpField}>
                  <label className={classes.label} htmlFor="email">
                    E-mail адрес
                  </label>
                  <Field name="email" type="text" className="form-control" />
                  <ErrorMessage
                    name="email"
                    component="Box"
                    className={classes.alert}
                  />
                </Grid>
                <Grid item xs={12} className={classes.signUpField}>
                  <label className={classes.label} htmlFor="username">
                    Имя пользователя
                  </label>
                  <Field name="username" type="text" className="form-control" />
                  <ErrorMessage
                    name="username"
                    component="Box"
                    className={classes.alert}
                  />
                </Grid>

                <Grid item xs={12} className={classes.signUpField}>
                  <label className={classes.label} htmlFor="password">
                    Пароль
                  </label>
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="password"
                    component="Box"
                    className={classes.alert}
                  />
                </Grid>
                <Grid item xs={12} className={classes.signUpField}>
                  <label className={classes.label} htmlFor="confirmPassword">
                    Re-Type Password
                  </label>
                  <Field
                    name="confirmPassword"
                    type="password"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="Box"
                    className={classes.alert}
                  />
                </Grid>

                {message && (
                  <Box className="form-group">
                    <Box className={classes.alert} role="alert">
                      {message}
                    </Box>
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} className={classes.already}>
                <Typography
                  className={classes.forgotText}
                  onClick={() => {
                    history.push('/login')
                  }}
                >
                  уже есть аккаунт? Войти
                </Typography>
              </Grid>

              <Box className={classes.buttonBox}>
                <Button
                  type="submit"
                  className={classes.button}
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                  {'Зарегистрироваться'}
                </Button>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Box>
    </Fragment>
  )
}

export default observer(RegisterForm)
