import { makeStyles, Theme } from '@material-ui/core'

export const useArticlesCaruseleStyle = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '20px 1fr',
    gridTemplateRows: '1fr',
    alignItems: 'center',
    '& .CarouselItem': {
      gridColumn: 2,
      gridRow: 1,
      padding: '0px 50px',
      margin: '0px auto',
      [theme.breakpoints.down(1024)]: {
        margin: '0px'
      }
    }
  },
  title: {
    fontSize: 24,
    lineHeight: '33px',
    textTransform: 'uppercase',
    fontWeight: 700,
    color: theme.palette.text.primary
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    lineHeight: '19px',
    textAlign: 'justify'
  },
  textMargin: {
    margin: '30px 30px'
  },
  imageItem: {
    objectFit: 'cover',
    width: '100%',
    height: imageContainer.height,
    borderRadius: imageContainer.borderRadius
  },
  iconTextdiv: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main
    },
    '& p': {
      margin: '5px'
    }
  },
  img: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    marginTop: 30,
    width: 350,
    height: 250
  },
  icon: {
    fontSize: 70,
    [theme.breakpoints.up('mobileVertical')]: {
      fontSize: 30
    }
  },
  carusel: {
    display: 'grid',
    gridTemplateColumns: '20px 1fr',
    gridTemplateRows: '1fr',
    alignItems: 'center',
    '& .CarouselItem': {
      gridColumn: 2,
      gridRow: 1,
      padding: '0px 50px',
      margin: '0px auto',
      [theme.breakpoints.down(1024)]: {
        margin: '0px'
      }
    }
  },
  indicators: {
    gridColumn: 1,
    gridRow: 1
  },
  indicatorColor: {
    color: 'rgba(255, 255, 255, 0.6)'
  },
  activeIndicator: {
    color: `${theme.palette.success.main} !important`
  }
}))

const imageContainer = {
  gridColumn: 1,
  gridRow: 1,
  width: 210,
  height: 130,
  borderRadius: 5
}

export const useCardStyle = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '210px auto',
    gridTemplateRows: '200px',
    alignItems: 'center',
    [theme.breakpoints.down(1024)]: {
      gridTemplateColumns: 'auto'
    }
  },
  backdrop: {
    ...imageContainer,
    position: 'relative',
    top: -10,
    left: -10,
    background: 'rgba(255, 255, 255, 0.2)',
    border: `1px solid ${theme.palette.common.white}`,
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.2)'
  },
  image: {
    ...imageContainer,
    position: 'relative',
    zIndex: 1,
    filter: 'drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.2))'
  },

  desText: {
    color: theme.palette.common.white,
    fontSize: 24,
    lineHeight: '33px'
  },
  textContainer: {
    gridColumn: 2,
    gridRow: 1,
    background:
      'linear-gradient(90.1deg, rgba(255, 255, 255, 0.2) 0.1%, rgba(255, 255, 255, 0) 99.93%)',
    filter: 'drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.2))',
    padding: '5px 0px 5px 10px',
    minHeight: 50,
    maxWidth: 550,
    alignItems: 'center',
    [theme.breakpoints.down(768)]: {
      maxWidth: 0
    },
    [theme.breakpoints.down(1024)]: {
      borderRadius: '5px 0px 0px 5px',
      maxWidth: 648,
      gridColumn: 1
    }
  }
}))
