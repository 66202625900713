import { Box, Grid } from "@material-ui/core";
import EventsCard from "components/common/Cards/Events/EventsCard";
import Spiner from "components/common/Spiner";
import EmptyFilterResult from "components/common/StubsAndBugs/EmptyFilterResult";
import Paging from "components/common/Paging";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { searchService } from "services/search/search.service";
import { scrollTopPage } from "utils";
import { useLanguage } from "context/Translation";

const EventsTab: FC = () => {
  const { eventList, loading, paging } = searchService;
  const { language } = useLanguage();

  const handlePageChange = (newPage: number) => {
    searchService.fetchPagingEvents(language, newPage);
    scrollTopPage();
  };

  if (loading) {
    return <Spiner />;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {eventList.length ? (
          eventList.map((item) => (
            <Grid item xs={12} key={item.id}>
              <EventsCard event={item} />
            </Grid>
          ))
        ) : (
          <EmptyFilterResult />
        )}
      </Grid>
      <Paging paging={paging} onChange={handlePageChange} />
    </Box>
  );
};

export default observer(EventsTab);
