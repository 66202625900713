import { FC, Fragment } from 'react'
import SectionTitle from 'components/common/SectionTitle'
import { observer } from 'mobx-react-lite'
import './styles.scss'
import { Box, Grid } from '@material-ui/core'
import { useLoginStyles } from './FeedBack.styles'
import FeedbackChapter from 'components/AboutProject/Home/FeedbackChapter'
import Breadcrumb from 'components/common/Breadcrumb'
const FeedBackForm: FC = () => {
  const classes = useLoginStyles()

  return (
    <Fragment>
      <SectionTitle isShown={false} />
      <Breadcrumb />
      <Box className={classes.container}>
        <Box style={{ width: '100%' }}>
          <Grid container spacing={2} style={{ padding: '0 20px' }}>
            <FeedbackChapter />
          </Grid>
        </Box>
      </Box>
    </Fragment>
  )
}

export default observer(FeedBackForm)
