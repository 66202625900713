import { FC, Fragment, useEffect, useReducer } from 'react'
import { Route, Switch } from 'react-router-dom'
import Footer from 'components/common/Footer'
import MediacenterPage from 'components/Mediacenter'
import NewDetail from 'components/Mediacenter/News/Detail'
import PhotoDetail from 'components/Mediacenter/Photos/Detail'
import { mediacenterService } from 'services/mediacenter/mediacenter.service'
import { useLanguage } from 'context/Translation'
import { defaultFilterOptions } from 'enums'
import { FilterOptions } from 'models/filters'
import { routers } from 'routers'

const reducer = (
  options: FilterOptions,
  newOptions: Partial<FilterOptions>
) => ({
  ...options,
  ...newOptions
})

const Mediacenter: FC = () => {
  const [filterOptions, setFilterOptions] = useReducer(
    reducer,
    defaultFilterOptions
  )

  const { language } = useLanguage()
  useEffect(() => {
    mediacenterService.fetchNews(language)
    mediacenterService.fetchPhotoGallerys(language)
    mediacenterService.fetchVideoGallerys(language)
  }, [language])

  useEffect(() => {
    return function cleanupPage() {
      mediacenterService.cleanPage()
    }
  }, [])

  return (
    <Fragment>
      <Switch>
        <Route exact path={routers.mediacenter.news}>
          <MediacenterPage
            checkedValues={filterOptions}
            onChange={setFilterOptions}
          />
        </Route>
        <Route
          exact
          path={`${routers.mediacenter.news}/:id`}
          component={NewDetail}
        />
        <Route exact path={routers.mediacenter.photos}>
          <MediacenterPage
            checkedValues={filterOptions}
            onChange={setFilterOptions}
          />
        </Route>
        <Route
          exact
          path={`${routers.mediacenter.photos}/:id`}
          component={PhotoDetail}
        />
        <Route exact path={routers.mediacenter.videos}>
          <MediacenterPage
            checkedValues={filterOptions}
            onChange={setFilterOptions}
          />
        </Route>
        <Route path="*">
          <MediacenterPage
            checkedValues={filterOptions}
            onChange={setFilterOptions}
          />
        </Route>
      </Switch>
      <Footer />
    </Fragment>
  )
}

export default Mediacenter
